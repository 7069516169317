import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { mapGetters, mapActions } from "vuex";

const mixin = {
    data: () => ({
        // API_URL: process.env.VUE_APP_MOFSERVER,
        uid: null,
    }),
    computed: {
        ...mapGetters({
            GET_currentUserAuth: "GET_currentUserAuth",
            GET_userAuthenticated: "GET_userAuthenticated",
        }),
    },
    methods: {
        ...mapActions({
            ACT_currentUserAuth: "ACT_currentUserAuth",
            ACT_currentUserProfile: "ACT_currentUserProfile",
            ACT_userAuthenticated: "ACT_userAuthenticated",
        }),
        // FIREBASE REGISTER
        async FIREBASE_register(fullName, email, password) {
            const auth = getAuth();
            // let t = this;
            await this.ACT_currentUserAuth(null);
            return createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    // t.uid = user.uid;
                    await this.ACT_currentUserAuth(user);
                    await this.ACT_userAuthenticated(true);
                    // console.log("user", user);
                    // updateProfile(auth.currentUser, {
                    //     displayName: fullName,
                    // })
                    // .then(() => {
                    // console.log("user profile updated")
                    return { success: true };
                }).catch((error) => {
                    // console.error(error);
                    return { success: false, error: error };
                });
                // }
                // .catch((error) => {
                //     // console.error(error);
                //     return { success: false, error: error };
                // });
        },
        // FIREBASE LOGIN
        FIREBASE_login(userEmail, userPassword) {
            const auth = getAuth();
            return signInWithEmailAndPassword(auth, userEmail, userPassword)
                .then(async (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    await this.ACT_currentUserAuth(user);
                    let userProfile = await this.REDIS_read("user", user.uid);
                    // console.log('userProfile', JSON.stringify(userProfile, null, 2))
                    await this.ACT_currentUserProfile(userProfile.data);
                    await this.ACT_userAuthenticated(true);
                    // console.log("user", user);
                    // console.log("userProfile", userProfile);
                    return { success: true }
                })
                .catch((error) => {
                    console.error(error);
                    switch (error.code) {
                        case 'auth/invalid-email': return { success: false, error: "User Account not found" }
                        case 'auth/user-disabled': return { success: false, error: "User Account has been disabled" }
                        case 'auth/wrong-password': return { success: false, error: "Password is incorrect" }
                        case 'auth/user-not-found': return { success: false, error: "User Account not found" }
                        default: return { success: false, error: "User Unknown Error" }
                    }
                    // console.error(error);
                });
        },
        // FIREBASE LOGOUT
        FIREBASE_logout() {
            const auth = getAuth();
            auth.signOut().then(() => {
                this.ACT_currentUserAuth(null);
                this.ACT_currentUserProfile(null);
                this.ACT_userAuthenticated(false);
                // Sign-out successful.
                console.log("sign out successful");
                // this.$store.replaceState({})
                this.$router.push("/login");    
            }).catch((error) => {
                // An error happened.
                console.error(error);
            });
        }
        // FIREBASE UPDATE USER PROFILE
        // FIREBASE CHANGE PASSWORD
        // FIREBASE PASSWORD RESET
        // FIREBASE SEND EMAIL VERIFICATION
        // FIREBASE DELETE USER
    },
};

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};


<template>
    <div class="rounded-of d-flex align-center justify-start" :class="bodyclass" :style="`minHeight: ${minheight} !important`">
        <div class=" font-weight-light text-uppercase text-center pl-4">
            <v-icon class="text-h3" :class="iconclass"><slot name="icon"></slot></v-icon>
        </div>
        <div class="py-3 pl-2">
            <div class="text-body-1 font-weight-bold text-uppercase" :class="titleclass" >
                <slot name="title"></slot>
            </div>
            <div class="text-body-2 text-center font-weight-normal" :class="descriptionclass">
                <slot name="description"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titleclass: {
            type: String,
            default: "",
        },
        descriptionclass: {
            type: String,
            default: "",
        },
        iconclass: {
            type: String,
            default: "",
        },
        bodyclass: {
            type: String,
            default: "white",
        },
        minheight: {
            type: String,
            default: "100px",
        },
    }
};
</script>

<style scoped>
title:empty {
    display: none !important;
}
description:empty {
    display: none !important;
}
icon:empty {
    display: none !important;
}
</style>


               
<template>
	<div class="d-flex align-center mt-2">
		<div class="font-weight-bold grey--text text--darken-1 mt-2">Copyright <a :href="$config.website" target="_blank">{{ $config.companyName }}</a> {{ MIX_formatDateTimeNow('YYYY') }}</div>
		<v-spacer />
		<div><img src="@/assets/vindicologo.png" height="25" alt="Vindico" @click="MIX_goExternal('https://www.vindico.net')" />   </div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>

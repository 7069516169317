<template>
    <v-menu transition="scale-transition" origin="top right" content-class="elevation-0" nudge-bottom="4" offset-y left :close-on-content-click="false" rounded="b-lg">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="rounded-of" :class="buttonclass" depressed height="46" v-bind="attrs" v-on="on"><v-icon>{{ icon }}</v-icon></v-btn>
        </template>
        <div class="white pt-1 pb-2 px-5" style="border-radius: 8px !important; border: 2px solid lightgrey !important;">
            <slot></slot>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: "dropdown-menu-component",
    props: {
        icon: {
            type: String,
            default: "icons8-menu-vertical",
        },
        text: {
            type: String,
            default: null,
        },
		buttonclass: {
			type: String,
			default: "grey darken-1 white--text",
		}
    },

};
</script>

const config = {
    name: "On Farm",
    description: "Description of the App goes here",
    companyName: "Gwaredu BVD",
    country: "United Kingdom",
    email: "gwaredubvd@colegsirgar.ac.uk",
    address: "Gwaredu BVD, Gelli Aur College Farm, Gelli Aur, Carmarthen, SA32 8NJ",
	website: "https://www.ahww.cymru/"
};

module.exports = {
    config,
};

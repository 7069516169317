<template>
    <v-text-field
        :label="label"
        v-model="innerValue"
        :error-messages="validateMessage"
        filled
        rounded
        :type="showPassword ? 'text' : 'password'"
        class="rounded-of"
        background-color="grey lighten-4"
        hide-details="auto"
        :disabled="disabled"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'icons8-eye' : 'icons8-hide'">
    </v-text-field>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        validate: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data:() => ({
        innerValue: "",
        validateMessage: "",
        showPassword: false
    }),
    methods: {
        clear() {
            setTimeout(() => {
                this.innerValue = "";
            }, 1);
        }
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
        },        
        innerValue: function (value) {
            this.$emit("input", value);
            this.validateMessage = "";
        },
        validate: function (value) {
            this.validateMessage = value;
        },
    },
    mounted() {
        this.innerValue = this.value;
        this.validateMessage = this.error;
    },
};
</script>

<template>
	<div>
		<v-data-table :headers="tableheaders" :items="tabledata" :options.sync="tableOptions" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }" class="elevation-0 rounded-of" :server-items-length="itemstotal">
			<template v-slot:top="{ pagination, updateOptions }">
				<v-data-footer :pagination="pagination" :options="tableOptions" @update:options="updateOptions" :items-per-page-options="[5, 10, 15, 25, 50, 100]" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
			</template>
			<!-- Name -->
			<template #[`item.name`]="{ item }">
				<div class="font-weight-bold">{{ item.name }}</div>
			</template>
			<!-- Email -->
			<template #[`item.email`]="{ item }">
				<a class="text-decoration-none" :href="`mailto:${item.email}`">{{ item.email }}</a>
			</template>
			<!-- Website -->
			<template #[`item.website`]="{ item }">
				<a class="text-decoration-none" :href="`${item.website}`" target="_blank">{{ item.website }}</a>
			</template>
			<!-- Status -->
			<template #[`item.status`]="{ item }">
				<v-icon title="Approved" class="success--text font-weight-bold" v-if="item.status === 'Approved'">icons8-100-</v-icon>
				<v-icon title="Pending" class="warning--text font-weight-bold" v-if="item.status === 'Pending'">icons8-100-</v-icon>
				<v-icon title="Suspended" class="info--text font-weight-bold" v-if="item.status === 'Suspended'">icons8-100-</v-icon>
				<v-icon title="Rejected" class="error--text font-weight-bold" v-if="item.status === 'Rejected'">icons8-100-</v-icon>
				<v-icon title="Achived" class="grey--text font-weight-bold" v-if="item.status === 'Archived'">icons8-100-</v-icon>
				<!-- Custom for Animal -->
				<v-icon title="Negative" class="success--text font-weight-bold" v-if="custom === 'animal' && item.status === 'Negative'">icons8-100-</v-icon>
				<v-icon title="Positive" class="error--text font-weight-bold" v-if="custom === 'animal' && item.status === 'Positive'">icons8-100-</v-icon>
				<v-icon title="Unknown" class="grey--text font-weight-bold" v-if="custom === 'animal' && item.status === 'Unknown'">icons8-100-</v-icon>
				<!-- Custom for Test Event -->
				<v-icon title="Draft" class="grey--text font-weight-bold" v-if="item.status === 'Draft'">icons8-100-</v-icon>
				<v-icon title="Complete" class="success--text font-weight-bold" v-if="item.status === 'Completed'">icons8-100-</v-icon>
			</template>
			<template #[`item.action`]="{ item }">
				<v-btn title="Restore" v-if="item.deleted" icon @click="restoreItem(item)"><v-icon>icons8-restart</v-icon></v-btn>
				<v-btn title="Edit" v-if="!item.deleted" icon @click="editItem(item)"><v-icon>icons8-edit</v-icon></v-btn>
				<v-btn title="Delete" v-if="!item.deleted" icon @click="deleteItem(item)"><v-icon>icons8-delete</v-icon></v-btn>
			</template>
			<!-- Custom -->
			<template #[`item.birthDate`]="{ item }" v-if="custom === 'animal'">
				<span>{{ MIX_formatDateTime(item.birthDate, "ISOString", "DD-MMM-YY") }}</span>
			</template>
			<template #[`item.certificateStatus`]="{ item }">
				<v-icon title="Positive" class="red--text font-weight-bold" v-if="item.certificateStatus === 'Positive'">icons8-livestock</v-icon>
				<v-icon title="Gold Certified" class="gold--text font-weight-bold" v-if="item.certificateStatus === 'Gold'">icons8-certificate</v-icon>
				<v-icon title="Silver Certified" class="silver--text font-weight-bold" v-if="item.certificateStatus === 'Silver'">icons8-certificate</v-icon>
				<v-icon title="Bronze Certified" class="bronze--text font-weight-bold" v-if="item.certificateStatus === 'Bronze'">icons8-certificate</v-icon>
				<!-- <v-icon class="grey--text text--lighten-2 font-weight-bold" v-else>icons8-certificate</v-icon>          -->
			</template>
			<template #[`item.cphNumber`]="{ item }">
				{{ item.cphNumber | VMask("##/###/####") }}
			</template>
			<template #[`item.sampleDate`]="{ item }" v-if="custom === 'testresult'">
				<span>{{ MIX_formatDateTime(item.sampleDate, "ISOString", "DD-MMM-YY") }}</span>
			</template>
			<template #[`item.processedDate`]="{ item }" v-if="custom === 'testresult'">
				<span>{{ MIX_formatDateTime(item.processedDate, "ISOString", "DD-MMM-YY") }}</span>
			</template>
			<template #[`item.pooled`]="{ item }" v-if="custom === 'testresult'">
				<span v-if="item.pooled">Yes</span>
				<span v-else>No</span>
			</template>
			<template #[`item.resultText`]="{ item }" v-if="custom === 'testresult'">
				<span title="Positive" v-if="item.resultText === 'Positive'"><v-icon class="error--text">icons8-100-</v-icon></span>
				<span title="Negative" v-if="item.resultText === 'Negative'"><v-icon class="success--text">icons8-100-</v-icon></span>
			</template>
			<template #[`item.eventDate`]="{ item }" v-if="custom === 'testevent'">
				<span>{{ MIX_formatDateTime(item.eventDate, "ISOString", "DD-MMM-YY") }}</span>
			</template>			
		</v-data-table>
		<CopyrightBanner />
	</div>
</template>
<script>
export default {
	// props
	props: {
		tableheaders: {
			type: Array,
			default: () => [],
		},
		tabledata: {
			type: Array,
			default: () => [],
		},
		datatableoptions: {
			type: Object,
			default: () => ({}),
		},
		itemstotal: {
			type: Number,
			default: 0,
		},
		custom: {
			type: String,
			default: "",
		},
		dense: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	// data
	data: () => ({
		tableOptions: {},
	}),
	// computed: {
	// 	calcTableHeight() {
	// 		let tableHeight = window.innerHeight - 420;
	// 		if (tableHeight < 300) {
	// 			tableHeight = 300;
	// 		}
	// 		return tableHeight;
	// 	},
	// },
	// methods
	methods: {
		restoreItem(item) {
			this.$emit("restore", item);
		},
		editItem(item) {
			this.$emit("edit", item);
		},
		deleteItem(item) {
			this.$emit("delete", item);
		},
	},
	created() {
		this.tableOptions = this.datatableoptions;
	},
	watch: {
		datatableoptions: {
			handler: function (val) {
				this.tableOptions = val;
			},
			deep: true,
		},
		tableOptions: {
			handler: function (val) {
				this.$emit("tableoptions", val);
			},
			deep: true,
		},
	},
};
</script>

const user = {
	firstName: "",
	lastName: "",
	jobRole: "",
	telephone: "",
	email: "",
	orgId: "",
	orgName: "",
	status: "",
	group: "",
	level: "",
	orgType: "",
	termsAgreed: false,
	optInAgreed: false,
	language: "EN",
	createdUserId: "",
	createdOrgId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifedOrgId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedOrgId: "",
	deletedDateTime: "",
	deleted: false,
};

const org = {
    name: "",
    contactFirstName: "",
    contactLastName: "",
    contactJobRole: "",
    telephone: "",
    email: "",
    website: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    town: "",
    county: "",
    postcode: "",
    latitude: "",
    longitude: "",
    type: "",
    cphNumber: "",
    status: "",
    certificateStatus: "",
	lastTestDate: "",
    ownerUserId: "",
    createdUserId: "",
    createdOrgId: "",
    createdDateTime: "",
    modifiedUserId: "",
    modifedOrgId: "",
    modifiedDateTime: "",
    deletedUserId: "",
    deletedOrgId: "",
    deletedDateTime: "",
    deleted: false,
};

const vetholding = {
    holdingOrgId: "",
    vetOrgId: "",
    createdUserId: "",
    createdOrgId: "",
    createdDateTime: "",
    modifiedUserId: "",
    modifedOrgId: "",
    modifiedDateTime: "",
    deletedUserId: "",
    deletedOrgId: "",
    deletedDateTime: "",
    deleted: false,
};

const testevent = {
	managementGroup: "",
	groupTest: false,
	sampleType: "",
	diseaseType: "",
	testType: "",
	pooled: false,
	holdingOrgId: "",
	cphNumber: "",
	eventDate: "",
	vetOrgId: "",
	farmerSignature: "",
	vetSignature: "",
	status: "",
	createdUserId: "",
	createdOrgId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifedOrgId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedOrgId: "",
	deletedDateTime: "",
	deleted: false,
}

const testdetail = {
    tubeId: "",
    identifier: "",
    groupTest: false,
    diseaseType: "",
    testType: "",
    eventId: "",
    eventDate: "",
    managementGroup: "",
    holdingOrgId: "",
    cphNumber: "",
    vetOrgId: "",
    status: "",
    createdUserId: "",
    createdOrgId: "",
    createdDateTime: "",
    modifiedUserId: "",
    modifedOrgId: "",
    modifiedDateTime: "",
    deletedUserId: "",
    deletedOrgId: "",
    deletedDateTime: "",
    deleted: false,
}

const animal = {
	animalId: "", // Required as unique per animal
	holdingOrgId: "",
	cphNumber: "",
	note: "",
	type: "",
	parentId: "",
	breedCode: "",
	sex: "",
	birthDate: "",
	status: "",
	createdUserId: "",
	createdOrgId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifedOrgId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedOrgId: "",
	deletedDateTime: "",
	deleted: false,
}

const testresult = {
	tubeId: "",
	sampleDate: null,
	processedDate: null,
	sampleType: "",
	diseaseType: "",
	testType: "",
	pooled: false,
	animalId: "",
	cphNumber: "",
	resultText: "",
	resultValue: "",
	labOrgId: "",
	interpretedResult: "",
	originalResult: "",
	resultChangedDate: null,
	resultChangeUserId: "",
	createdUserId: "",
	createdOrgId: "",
	createdDateTime: null,
	modifiedUserId: "",
	modifedOrgId: "",
	modifiedDateTime: null,
	deletedUserId: "",
	deletedOrgId: "",
	deletedDateTime: "",
	deleted: false,
}

module.exports = {
    user,
    org,
    vetholding,
    testevent,
    testdetail,
	testresult,
	animal
};

<template>
    <div>
        <v-banner app class="grey darken-1 full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
            <div class="d-flex align-center">
                <v-icon class="white--text pr-2" x-large>icons8-rod-of-asclepius</v-icon>
                <div class="text-h6 white--text font-weight-bold">{{ display }}</div>
                <v-spacer />
                <div v-if="JSON.stringify(holdings) !== '[]'">
                    <AppButton :text="true" v-if="display === 'My Vets'" @click.native="display = 'Add Vet'" depressed class="secondary white--text"><v-icon>icons8-add</v-icon><span class="pl-2">Vet </span></AppButton>
                    <AppButton :text="true" v-else @click.native="closeVet()" depressed class="grey darken-2 white--text"><v-icon class="">icons8-back-to</v-icon><span class="pl-2">Vets</span></AppButton>
                </div>                
            </div>
        </v-banner>
        <div style="margin-top: 80px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary':'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>        
        <v-progress-linear v-if="loading" :indeterminate="loading" :color="loading ? 'primary':'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        <transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
            <v-container v-if="display === 'My Vets'" class="">
                <v-row v-for="holding in holdings" :key="holding.entityId" dense>
                    <v-col cols="12" xs="12" sm="4" class="pa-4 text-h5 text--secondary">
                        {{ holding.name }}
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" class="">
                        <FarmCard v-for="(vetholding, index) in filteredVetsHoldings(holding.entityId)" :key="index" @click.native="viewVet(vetholding.vetOrgId); MIX_scrollToTop()" class="pa-4 mb-4">
                            <div class="d-flex align-center" dense>
                                <div class="d-flex align-start justify-start flex-column">
                                    <div class="text-h6 primary--text">{{ getVetDetails(vetholding.vetOrgId)?.name }}</div>
                                    <div class="text-body-2 text--secondary my-3" v-if="vetholding?.vetOrgId">{{ MIX_formatAddress(getVetDetails(vetholding.vetOrgId)) }}</div>
                                    <div class="d-flex align-center full-width mt-2">
                                        <!-- <div class="d-flex align-center"><v-icon class="primary--text">icons8-cow</v-icon><div class="ml-1 mr-3 font-weight-bold text--secondary">0</div></div> -->
                                        <!-- <v-chip small label class="white--text font-weight-bold mr-2" :class="MIX_certificateStatus(Vet.certificateStatus)" v-if="Vet.certificateStatus !== undefined">{{ Vet.certificateStatus }}</v-chip>
                                        <v-chip small label class="white--text font-weight-bold" :class="MIX_accountStatus(Vet.status)" v-if="Vet.status !== 'Approved'">{{ Vet.status }}</v-chip> -->
                                        <v-spacer />
                                    </div>
                                </div>
                                <v-spacer />
                                <div class="d-flex align-center justify-right">
                                    <v-btn @click="viewVet(vetholding.vetOrgId); MIX_scrollToTop()" icon><v-icon large class="secondary--text">icons8-next-page</v-icon></v-btn>
                                </div>
                            </div>
                        </FarmCard>
                    </v-col>
                </v-row>
				<v-row class="dense" v-if="JSON.stringify(holdings) === '[]'">
				<v-col>
					<FarmCard class="pa-5 grey lighten-3 text--secondary">
						<strong>Information</strong><br />
						<i>There are currently no holdings associated with your account, please use the Add Holding button under My Holdings to link a holding.</i>
					</FarmCard>
				</v-col>
			</v-row>				
            </v-container>
            <AddVet v-if="display === 'Add Vet'" v-on:close="closeVet()" />
            <ViewVet v-if="display === 'View Vet'" :vetdata="activeVet" v-on:close="closeVet()" />
        </transition>
        <!-- <pre>{{ vets }}</pre> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "HomeView",
    // comopnents
    components: {
        AddVet: () => import("@/views/farm/AddVet.vue"),
        ViewVet: () => import("@/views/farm/ViewVet.vue")
    },
    // data
    data: () => ({
        loading: false,
        search: "",
        display: "My Vets",
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
        activeVet: {},
        vets: [],
        totalVets: 0,
        vetholdings: [],
        totalVetHoldings: 0,
        holdings: [],
        totalHoldings: 0
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },
    methods: {
        async initialise() {
            this.loading = true;
            await this.getHoldings();
			if (this.holdings.length > 0) {
				await this.getVetHoldings();
				await this.getVets();
			}
            this.loading = false;
        },
        // Get Holdings belonging to the Farm
        async getHoldings() {
            return new Promise((resolve, reject) => {
                try {
                    let query = `@deleted:{false} @type:{Farm} @ownerUserId:{${this.GET_currentUserProfile.entityId}}`;
                    this.REDIS_searchFor('org', '', '', 'name', 'asc', query).then((holdingsResult) => {
                        this.holdings = holdingsResult.data.documents;
                        this.totalHoldings = holdingsResult.data.total;
                        resolve();
                    })
                } catch (error) {
                    console.error(error);
                    reject(error);
                }
            })
        },
        // Get Holdings linked to Vet and Farm
        async getVetHoldings() {
            return new Promise((resolve, reject) => {
                try {
                    let holdingIds = this.holdings.map(holding => holding.entityId);
                    let query = `@deleted:{false} @holdingOrgId:{${holdingIds.join('|')}}`;
                    this.REDIS_searchFor('vetholding', '', '', '', '', query).then((vetholdingsResult) => {
                        this.vetholdings = vetholdingsResult.data.documents;
                        this.totalVetholdings = vetholdingsResult.data.total;
                        resolve();
                    })
                } catch (error) {
                    console.error(error);
                    reject(error);
                }
            })
        },
        // Get Vets Details linked to Holdings
        async getVets() {
            return new Promise((resolve, reject) => {
                try {
                    let vetholdingIds = this.vetholdings.map(vetholding => vetholding.vetOrgId);
                    let vets = []
                    for (let i = 0; i < vetholdingIds.length; i++) {
                        this.REDIS_read('org', vetholdingIds[i]).then((vet) => {
                            vets.push(vet.data);
                        })
                    }
                    this.vets = vets;
                    this.totalVets = vets.length;
                    resolve();
                } catch (error) {
                    console.error(error);
                    reject(error);
                }
            })
        },
        addVet() {
            this.display = "Add Vet";
        },
        async viewVet(entityId) {
            try {
                this.loading = true;
                let orgResult = await this.REDIS_read('org', entityId);
                this.activeVet = orgResult.data;
                this.display = "View Vet";
                this.loading = false;
            }
            catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Loading Vet", color: "error", timeout: "4000" });
                console.error(error);
                this.loading = false;
            }
        },
        editVet() {
            this.display = "Edit Vet";
        },
        closeVet() {
            this.display = "My Vets";
            this.initialise();
            this.activeVet = null;
        },
        filteredVetsHoldings (entityId) {
            return this.vetholdings.filter(vetholding => {
                return vetholding.holdingOrgId === entityId
            })
        },
        getVetDetails(vetOrgId) {
            return this.vets.find(vet => vet.entityId === vetOrgId)
        }
    },
    created() {
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;
}
</style>

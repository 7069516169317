<template>
    <v-overlay opacity="0.95" class="pa-5 fixed" v-model="showTerms">
        <div class="d-flex align-start">
            <div class="text-h6">Terms and Conditions</div>
            <v-spacer />
            <v-btn icon @click="ACT_showTermsConditions(false)" class="float-right">
                <v-icon>icons8-multiply</v-icon>
            </v-btn>
        </div>
        <div class="scrolling">
            <div class="text-h6">TERMS OF SERVICE</div>
            <p>Welcome to <strong>{{ $config.name }}</strong> (the "App"). By using the App, you agree to be bound by these Terms of Service (the "Terms"). If you do not agree to these Terms, do not use the App.</p>
            <!-- Description of the App -->
            <div class="text-h7">Description of the App</div>
            <p>{{  $config.description }}</p>
            <!-- Use of the App -->
            <div class="text-h7">Use of the App</div>
            <p>You may use the App only in accordance with these Terms and all applicable laws and regulations. You may not use the App for any illegal or unauthorized purpose. You must not, in the use of the App, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
            <!-- Accounts -->
            <div class="text-h7">Accounts</div>
            <p>In order to use certain features of the App, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password, and you agree to accept responsibility for all activities that occur under your account. You are responsible for keeping your account information current and accurate, and for notifying us of any unauthorized use of your account or any other security breach.</p>
            <div class="text-h7">Intellectual Property</div>
            <p>The App and its original content, features, and functionality are and will remain the exclusive property of <strong>{{ $config.companyName}}</strong> and its licensors. The App is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of <strong>{{ $config.companyName }}</strong>. You may not reproduce, distribute, or create derivative works from any part of the App without our written consent.</p>
            <!-- Termination --.-->
            <div class="text-h7">Termination</div>
            <p>We may terminate your access to the App at any time, without notice and without liability, if you violate these Terms. Upon termination, your right to use the App will immediately cease. We may also terminate your account if it has been inactive for an extended period of time.</p>
            <!-- Disclaimer of Warranties -->
            <div class="text-h7">Disclaimer of Warranties</div>
            <p>The App is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the App or the information, content, materials, or products included on the App. We make no warranty that the App will be uninterrupted or error-free, and we will not be liable for any interruptions or errors.</p>
            <!-- Limitation of Liability -->
            <div class="text-h7">Limitation of Liability</div>
            <p>In no event shall <strong>{{ $config.companyName }}</strong> be liable for any damages whatsoever, including but not limited to any direct, indirect, special, incidental, consequential, or punitive damages, arising out of or in connection with the use or inability to use the App or any portion thereof. In no event shall <strong>{{ $config.companyName }}</strong> be liable for any damages resulting from loss of use, data, or profits, or from any unauthorized access to or alteration of your transmissions or data.</p>
            <!-- Changes to the Terms -->
            <div class="text-h7">Changes to the Terms</div>
            <p>We reserve the right to update or modify these Terms at any time and for any reason without notice to you. Your continued use of the App following any changes to these Terms will indicate your acceptance of such changes.</p>
            <!-- Governing Law -->
            <div class="text-h7">Governing Law</div>
            <p>These Terms shall be governed by and construed in accordance with the laws of <strong>{{ $config.country }}</strong>, without giving effect to any principles of conflicts of law.</p>
            <!-- Contact Us-->
            <div class="text-h7">Contact Us</div>
            <p>If you have any questions about these Terms, please contact us by email at <strong><a :href="'mailto:'+$config.email">{{ $config.email }}</a></strong> or by mail at <strong>{{  $config.companyName }}</strong>, <strong>{{ $config.address }}</strong>.</p>
            
            <!-- Privacy Policy -->
            <div class="text-h6">PRIVACY POLICY</div>
            <p>This Privacy Policy describes how <strong>{{ $config.companyName }}</strong> (“we,” “us,” or “our”) collects, uses, and shares information about you when you use our mobile application (the “App”). By using the App, you consent to our collection, use, and sharing of your information as described in this Privacy Policy.</p>
            <!-- Information We Collect -->
            <div class="text-h7">Information We Collect</div>
            <p>When you use the App, we may collect the following types of information:</p>
            <ol>
                <li>Personal Information: We may collect personal information such as your name, telephone number, and email address when you provide it to us through the App, for example, when you sign up for an account, contact us for support or participate in surveys or promotions.</li>
                <li>Usage Information: We may collect information about how you use the App, such as the features you access, the actions you take and the time you spend on the App.</li>
                <li>Location Information: We may collect information about your location if you have enabled location services on your device and have consented to the collection of this information.</li>
                <li>Device Information: We may collect information about the device you use to access the App, including the type of device, its operating system, and its unique device identifier.</li>
                <li>Log Data: We may also collect log data when you use the App, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before navigating to the App.</li>
            </ol>
            <!-- How We Use Information -->
            <div class="text-h7">How We Use Information</div>
            <p>We use the information we collect about you to:</p>
            <ol>
                <li>Provide, maintain, and improve the App: We use the information we collect to provide, maintain, and improve the App, and to personalize your experience.</li>
                <li>Communicate with you: We may use your personal information to contact you with information about the App or to respond to your inquiries. We will ask for your opt-in consent before sending you promotional communications.</li>
                <li>To conduct research and analysis: We may use the information we collect to conduct research and analysis to better understand our users and improve our App and services.</li>
                <li>Compliance with laws: We may use the information we collect to comply with legal obligations, such as responding to legal requests and preventing fraud.</li>
            </ol>
            <!-- Sharing of Information -->
            <div class="text-h7">Sharing of Information</div>
            <p>We will not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may share your information with third parties as follows:</p>
            <ol>
                <li>Service Providers: We may share your information with third-party service providers that perform services on our behalf, such as hosting and maintenance of the App, analytics, customer service, and marketing.</li>
                <li>As Required by Law: We may disclose your information if we are required to do so by law or if we believe that such action is necessary to comply with legal process, to respond to claims, or to protect the rights, property, or safety of us or others.</li>
                <li>Business Transfers: We may share your information in connection with or during negotiation of any merger, financing, acquisition, or dissolution, transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets.</li>
            </ol>
            <!-- Your Choices -->
            <div class="text-h7">Your Choices</div>
            <p>You have certain choices about how we collect, use, and share your information:</p>
            <ol>
                <li>Opt-out of communications: You may opt-out of receiving promotional communications from us by following the instructions in those communications or by contacting us as provided in the “Contact Us” section below.</li>
                <li>Access and update your information: You may access and update certain information about you by logging into your account and editing your profile.</li>
                <li>Control location information: If you have enabled location services on your device, you can control the collection of location information by disabling location services in your device settings.</li>
                <li>Cookies and tracking: We use cookies and similar technologies to provide, protect and improve our Services. You can set your browser to block or alert you about these technologies, but some parts of the Services may not work.</li>
            </ol>
            <!-- Security -->
            <div class="text-h7">Security</div>
            <p>We take reasonable measures to protect the personal information we collect from unauthorized access, use, disclosure, alteration or destruction. However, no security system can be 100% effective and we cannot guarantee the security of your information.</p>
            <!-- Data Retention -->
            <div class="text-h7">Data Retention</div>
            <p>We will retain your personal information for as long as your account is active or as needed to provide you services. We may also retain and use your information as necessary to comply with our legal obligations, resolve disputes and enforce our agreements.</p>
            <!-- International Transfer -->
            <div class="text-h7">International Transfer</div>
            <p>Your information may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>    
            <!-- Contact Us -->
            <div class="text-h7">Contact Us</div>
            <p>If you have any questions about this Privacy Policy, please contact us by email at <strong><a :href="'mailto:'+$config.email">{{ $config.email }}</a></strong> or by mail at <strong>{{ $config.address }}</strong>.</p>
            <!-- Changes to the Privacy Policy -->
            <div class="text-h7">Changes to the Privacy Policy</div>
            <p>We reserve the right to update or modify this Privacy Policy at any time and for any reason without notice to you. Your continued use of the App following any changes to this Privacy Policy will indicate your acceptance of such changes.</p>
    
            <!-- Cookies Policy -->
            <div class="text-h6">COOKIES POLICY</div>
            <p>This Cookies Policy explains how <strong>{{ $config.companyName }}</strong> (“we,” “us,” or “our”) use cookies and similar technologies in our mobile application (the “App”).</p>
            <!-- What Are Cookies -->
            <div class="text-h7">What Are Cookies</div>
            <p>Cookies are small text files that are stored on your device when you visit a website. They are used to remember your preferences, login information and browsing history among other things.</p>
            <p>Cookies we use</p>
            <p>We use the following types of cookies on the App:</p>
            <ol>
                <li>Essential Cookies: These cookies are necessary for the App to function properly, such as to allow you to log in or to remember your preferences.</li>
                <li>Performance Cookies: These cookies collect information about how you use the App, such as which pages you visit, and if you experience any errors. We use this information to improve the performance of the App.</li>
                <li>Functionality Cookies: These cookies allow the App to remember your preferences and provide you with a better experience.</li>
            </ol>
            <!-- Your Choices -->
            <div class="text-h7">Your Choices</div>
            <p>You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the features of the App.</p>
            <p>You can also opt-out of the collection and use of information for ad targeting by visiting the Network Advertising Initiative opt-out page, the Digital Advertising Alliance opt-out page or the European Interactive Advertising Digital Alliance opt-out page.</p>
            <!-- Contact Us -->
            <div class="text-h7">Contact Us</div>
            <p>If you have any questions about this Cookies Policy or our information practices, please contact us by email at <strong><a :href="'mailto:'+$config.email">{{ $config.email }}</a></strong> or by mail at <strong>{{ $config.address }}</strong>.</p>
            <!-- Changes to this Cookies Policy -->
            <div class="text-h7">Changes to this Cookies Policy</div>
            <p>We may change this Cookies Policy from time to time. If we make changes, we will post the new Cookies Policy on this page and will indicate the date it was last updated.</p>
            <div class="full-width text-center py-5"><v-btn @click="ACT_showTermsConditions(false)" class="mt-5" color="white black--text">Close</v-btn></div>
        </div>
    </v-overlay>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "TermsConditions",
    computed: {
        ...mapGetters({
            GET_showTermsConditions: 'GET_showTermsConditions',
        }),
        showTerms() {
            if (this.GET_showTermsConditions === true) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        ...mapActions({
            ACT_showTermsConditions: 'ACT_showTermsConditions',
        }),
        // getConfig(value) {
        //     return this.$config[]
        // }
    }
}
</script>

<style scoped>
.scrolling {
    padding-top: 10px !important;
    overflow-y: auto;
    height: calc(100vh - 50px) !important;;
}
p, li {
    margin-top: 10px !important;
    font-weight: normal !important;
    font-size: 12px !important;
}
ol {
    margin-bottom: 15px !important;
}
</style>
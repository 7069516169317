<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="px-5 pt-5">
			<v-col cols="12">
				<div class="d-flex align-center">
					<div>
						<div class="text-h4 primary--text d-flex">
							Animals
							<v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
						</div>
					</div>
					<v-spacer />
					<!-- Search -->
					<SearchField label="Search by Animal ID" v-model="filter.animalId" :dense="true" />
					<!-- Search CPH -->
					<SearchField label="Search by CPH Number" v-model="filter.cphNumber" :dense="true" mask="##/###/####" placeholder="##/###/####" />
					<AppButton @click.native="getTableData"><v-icon>icons8-refresh</v-icon></AppButton>
					<AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
					<AppButton :disabled="drawer" buttonclass="secondary" @click.native="addAnimal">Add Animal</AppButton>
					<div class="white--text">
						<DropdownMenu>
							<div class="d-flex align-center">
								<v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
								<div class="font-weight-bold secondary--text">Show Deleted</div>
							</div>
							<v-divider />
							<div class="d-flex align-center pt-2">
								<AppButton @click.native="importBox = true" block buttonclass="secondary--text"><v-icon>icons8-csv</v-icon><span class="ml-2">Import</span></AppButton>
							</div>
							<div class="d-flex align-center pt-1 pb-2">
								<AppButton @click.native="exportBox = true" block buttonclass="secondary--text"><v-icon>icons8-export-csv</v-icon><span class="ml-2">Export</span></AppButton>
							</div>
						</DropdownMenu>
					</div>
				</div>
				<div class="text--secondary pt-4" v-if="showInfo">The following page shows Aniamls which have been imported into the system. You can search or use the filter to locate results.</div>
			</v-col>
			<!-- Table -->
			<v-col cols="12" class="pt-2">
				<!-- Data Table -->
				<DataTable :tableheaders="filteredHeaders" :loading="loading" custom="animal" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteAnimal" v-on:edit="editAnimal" v-on:restore="restoreAnimal" v-on:tableoptions="updateTableOptions" />
			</v-col>
			<!-- Filter Drawer -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
				<v-row>
					<v-col cols="12">
						<div class="d-flex align-center pt-4">
							<div class="text-h5 secondary--text">Filters</div>
							<v-spacer />
							<v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the filters below to narrow your search.
					</v-col>
					<v-col cols="12">
						<!-- Animal Type -->
						<div class="flex-grow-1 mb-2">
							<DropdownSimple backgroundcolor="white" label="Filter by Animal Type" :items="animalTypes" v-model="filter.animalType" :dense="true" />
						</div>
					</v-col>

					<!-- Status -->
					<v-col cols="12">
						<div class="mt-2 text-h6 primary--text">Status</div>
						<v-btn-toggle v-model="filter.status" multiple class="rounded-of" style="flex-direction: column !important; width: 100% !important;">
							<v-btn title="Positive" value="Positive"><v-icon class="error--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Positive</span></v-btn>
							<v-btn title="Negative" value="Negative"><v-icon class="success--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Negative</span></v-btn>
							<v-btn title="Unknown" value="Unknown"><v-icon class="grey--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Unknown</span></v-btn>
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Edit Window -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
				<!-- Animal Details -->
				<v-row dense class="mt-5">
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<div class="text-h5 secondary--text">{{ action }} Animal</div>
							<v-spacer />
							<v-btn icon depressed @click="closeAnimal"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the form below to {{ action }} the Animals's details
					</v-col>
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Animal Details</div> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Animal ID *" v-model="animal.entityId" :validate="validate.entityId" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Parent ID" v-model="animal.parentId" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="CPH Number *" v-model="animal.cphNumber" :validate="validate.cphNumber" mask="##/###/####" placeholder="##/###/####" /> </v-col>
					<v-col cols="12"> <TextArea backgroundcolor="white" label="Note" v-model="animal.note" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Breedcode" v-model="animal.breedCode" :validate="validate.entityId" /> </v-col>
					<v-col cols="12"> <RadioField backgroundcolor="white" label="Sex" v-model="animal.sex" :options="['Male', 'Female']" /> </v-col>
					<v-col cols="12"> <DatePicker backgroundcolor="white" label="Birth Date" v-model="animal.birthDate" /> </v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Type" v-model="animal.type" :items="animalTypes" :validate="validate.type" /> </v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Status" v-model="animal.status" :items="animalStatus" :validate="validate.status" /> </v-col>
					<!-- <v-col cols="12"> <pre>{{ animal }}</pre></v-col> -->
				</v-row>
				<!-- Action Buttons -->
				<v-row dense class="mt-5">
					<v-col cols="6">
						<div><AppButton block buttonclass="grey" @click.native="closeAnimal">Cancel</AppButton></div>
					</v-col>
					<v-col cols="6">
						<div><AppButton block buttonclass="secondary" @click.native="saveAnimal">Save</AppButton></div>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Confirmation Box -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Confirm</div>
						<v-spacer />
						<v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirmed you want to delete this Animal</p>
				<strong>{{ animal.entityId }}</strong
				><br />
				<CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField>
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
							<AppButton buttonclass="warning" @click.native="confirmDeleteAnimal">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
			<ImportBox headerclass="primary" footerclass="grey lighten-2" :value="importBox">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Import Animals</div>
						<v-spacer />
						<v-btn icon depressed @click="importBox = false"><v-icon class="white--text">icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please Import CSV using the input box below</p>
				<BrowseFile v-model="fileInput" />
				<div class="d-flex align-center mt-5" v-if="checkingFile">
					<div><v-progress-circular indeterminate color="primary"></v-progress-circular></div>
					<div class="animate__animated animate__flash animate__infinite animate__slow primary--text">Checking File</div>
				</div>
				<div v-if="inputMessage !== 'success' && inputMessage !== 'empty'" class="error--text mt-4 text-body-2">
					<span class="font-weight-bold">File contains errors:</span><br />
					<span v-html="inputMessage"></span> <br />You must correct this before continuing.
				</div>
				<div v-if="inputMessage === 'success'" class="success--text text-body-2 font-weight-bold mt-4">
					File is <strong>Good</strong> and ready to be imported<br />
					{{ inputData?.length }} rows to be imported.
				</div>
				<!-- PROGRESS INDICATOR -->
				<v-progress-linear v-model="progress" height="25" class="mt-5" v-if="inputMessage === 'success'">
					<strong>{{ Math.ceil(progress) }}%</strong>
				</v-progress-linear>
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end" v-if="progressStatus === 'Not Started' || progressStatus === 'In Progress'">
							<AppButton buttonclass="grey" @click.native="importBox = false" :disabled="inputMessage !== 'success' || progressStatus === 'In Progress'">Cancel</AppButton>
							<AppButton buttonclass="primary" @click.native="importAnimals" :disabled="inputMessage !== 'success' || progressStatus === 'In Progress'">Import</AppButton>
						</v-col>
						<v-col cols="12" class="d-flex justify-end" v-else>
							<AppButton buttonclass="primary" @click.native="finishImport">Finish</AppButton>
						</v-col>
					</v-row>
				</template>
			</ImportBox>
			<ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Export CSV</div>
						<v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
						<v-spacer />
						<v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please select the data you wish to export:</p>
				<table width="100%" cellspacing="5">
					<tr>
						<td width="100"><strong>Current page:</strong></td>
						<td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td><strong>Current total:</strong></td>
						<td>{{ itemsTotal }} items (filtered)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td><strong>All Items:</strong></td>
						<td>{{ totalCount }} items (without filter)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td valign="top"><strong>Specific:</strong></td>
						<td>
							<div class="d-flex align-center">
								<div>From</div>
								<div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
								<div>To</div>
								<div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
							</div>
							<div class="ml-n7">
								<CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
							</div>
						</td>
						<td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
					</tr>
				</table>
			</ExportBox>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "AnimalsAdmin",
	// components: { FarmCard },
	// data
	data: () => ({
		loading: false,
		progress: 0,
		progressStatus: "Not Started",
		drawer: false,
		filterDrawer: false,
		confirmBox: false,
		importBox: false,
		fileInput: null,
		checkingFile: false,
		checkProgressInterval: null,
		inputMessage: "empty",
		inputHeaders: [],
		inputData: [],
		permenantDelete: false,
		search: "",
		filter: {
			status: [],
			animalId: "",
			cphNumber: "",
			animalType: "",
			showDeleted: false,
		},
		tableData: [],
		itemsTotal: 0,
		totalCount: 0,
		tableHeaders: [
			{ text: "Status", value: "status", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "ID", value: "entityId", align: "start", hidden: true, sortable: false, shrunk: true },
			{ text: "Animal ID", value: "animalId", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "CPH Number", value: "cphNumber", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Note", value: "note", align: "start", hidden: true, sortable: false, shrunk: true },
			{ text: "Type", value: "type", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Parent Tag No", value: "parentTagNo", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Breed Code", value: "breedCode", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Sex", value: "sex", align: "start", hidden: true, sortable: false, shrunk: true },
			{ text: "Birth Date", value: "birthDate", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedOrgId", value: "deletedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true },
		],
		tableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["animalId"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},
		validate: {},
		animal: {},
		animalTypes: [],
		animalStatus: [],
		orgStatus: [],
		action: "Add",
		// Export Box Data
		exportBox: false,
		exportItems: {
			itemsFrom: "1",
			itemsTo: "10",
		},
		exportLoading: false,
		exportFiltered: false,
		showInfo: false,
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
		filteredHeaders() {
			let headers = this.tableHeaders.filter((header) => !header.hidden);
			if (this.drawer || this.filterDrawer) {
				headers = headers.filter((header) => header.shrunk);
			}
			return headers;
		},
		filterQuery() {
			let query = "";
			// Search by Animal ID
			if (this.filter.animalId !== "" && this.filter.animalId !== null && this.filter.animalId !== undefined && this.filter.animalId.length > 2) {
				query += `@animalId:${this.filter.animalId}*`;
			}
			// Search by CPH Number
			if (this.filter.cphNumber !== "" && this.filter.cphNumber !== null && this.filter.cphNumber !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				// remove special characters from cphNumber
				let cphNumber = this.filter.cphNumber.replace(/[^a-zA-Z0-9]/g, "");
				query += `@cphNumber:{${cphNumber}}`;
			}
			// Filter by Animal Type
			if (this.filter.animalType !== "" && this.filter.animalType !== null && this.filter.animalType !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				query += `@type:{${this.filter.animalType}}`;
			}
			// Filter by Animal Status
			if (JSON.stringify(this.filter.status) !== "[]") {
				let status = this.filter.status.join(",");
				status = status.replace(/,/g, "|");
				query += ` @status:{${status}}`;
			}
			// Show Deleted / Not Deleted
			if (this.filter.showDeleted) {
				query += ` @deleted:{true}`;
			} else {
				query += ` @deleted:{false}`;
			}
			query = query.trim();
			return query;
		},
		itemsFrom() {
			return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
		},
		itemsTo() {
			if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
				return this.itemsTotal;
			} else {
				return this.tableOptions.page * this.tableOptions.itemsPerPage;
			}
		},
		itemsCount() {
			return this.tableOptions.itemsPerPage;
		},
		itemsSortBy() {
			let sortBy = this.tableOptions.sortBy;
			if (sortBy.length > 0) {
				sortBy = sortBy.map((x) => x);
				sortBy = sortBy.join(",");
			}
			return sortBy;
		},
		itemsSortDesc() {
			let sortDesc = this.tableOptions.sortDesc[0];
			if (sortDesc) {
				sortDesc = "DESC";
			} else {
				sortDesc = "ASC";
			}
			return sortDesc;
		},
	},
	// watch
	watch: {
		filterQuery: {
			handler() {
				this.tableOptions.page = 1;
				this.getTableData();
			},
			deep: true,
		},
		tableOptions: {
			handler() {
				this.getTableData();
			},
			deep: true,
		},
		fileInput: {
			handler() {
				this.inputMessage = "empty";
				this.handleImport(this.fileInput);
			},
			deep: true,
		},
	},
	// methods
	methods: {
		// Get Total Record Count
		async getTotalCount() {
			try {
				let dataResult = await this.REDIS_countFor("animal", "", "", "", "", "@deleted:{false}");
				// console.log(JSON.stringify(dataResult, null, 2))
				this.totalCount = dataResult.total;
			} catch (error) {
				console.error(error);
			}
		},
		// Get Table Data
		async getTableData() {
			try {
				this.loading = true;
				let dataResult = await this.REDIS_searchFor("animal", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
				this.tableData = dataResult.data.documents;
				this.itemsTotal = dataResult.data.total;
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
		// Update Table Options
		updateTableOptions(options) {
			this.tableOptions = options;
		},
		// Add Animal
		addAnimal() {
			this.animal = { ...this.$schema.animal };
			this.action = "Add";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Edit Animal
		editAnimal(animal) {
			this.animal = { ...animal };
			// add a mask ##/###/#### to the cphNumber
			this.animal.cphNumber = this.animal.cphNumber.replace(/(\d{2})(\d{3})(\d{4})/, "$1/$2/$3");
			this.action = "Edit";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Delete Animal
		deleteAnimal(animal) {
			this.animal = { ...animal };
			this.action = "Delete";
			this.confirmBox = true;
		},
		// Close ORg
		closeAnimal(animal) {
			this.animal = { ...animal };
			this.drawer = false;
		},
		// Restore Animal
		async restoreAnimal(animal) {
			try {
				this.loading = true;
				this.animal = { ...animal };
				this.animal.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.animal.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.animal.deleted = false;
				this.animal.deletedUserId = null;
				this.animal.deletedOrgId = null;
				this.animal.deletedDateTime = null;
				await this.REDIS_update("animal", this.animal.entityId, this.animal);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Confirm Delete Animal
		async confirmDeleteAnimal() {
			try {
				this.loading = true;
				this.animal.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.animal.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.animal.deleted = true;
				this.animal.deletedUserId = this.GET_currentUserProfile.entityId;
				this.animal.deletedOrgId = this.GET_currentUserProfile.orgId;
				this.animal.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
				await this.REDIS_update("animal", this.animal.entityId, this.animal);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Save Animal
		async saveAnimal() {
			try {
				this.loading = true;
				this.animal.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.animal.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.animal.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
				// this.org.postcode remove spaces and make uppercase
				let validationErrors = null;
				validationErrors = 0;

				// validation for the animal object
				//if (this.org.name === "" || this.org.name === undefined || this.org.name === null) { this.validate.name = "Name is required"; validationErrors++; }

				// loop through the this.org object and trim all the values
				Object.keys(this.animal).forEach((key) => {
					if (typeof this.animal[key] === "string") {
						this.animal[key] = this.animal[key].trim();
						// remove any . from the end of the string
						if (this.animal[key].slice(-1) === ".") {
							this.animal[key] = this.animal[key].slice(0, -1);
						}
					}
				});
				// If no validation errors
				if (this.action === "Add" && validationErrors === 0) {
					this.animal.createdUserId = this.GET_currentUserProfile.entityId;
					this.animal.createdOrgId = this.GET_currentUserProfile.orgId;
					this.animal.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
					await this.REDIS_createWithId("animal", this.animal, this.animal.entityId);
					this.loading = false;
					this.animal = { ...this.$schema.animal };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else if (this.action === "Edit" && validationErrors === 0) {
					await this.REDIS_update("animal", this.animal.entityId, this.animal);
					this.loading = false;
					this.animal = { ...this.$schema.animal };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else {
					this.loading = false;
				}
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Initialise
		async initialise() {
			this.loading = true;
			let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{animalStatus|animalType}");
			this.animalStatus = lookupResult.data.documents.filter((item) => item.type === "animalStatus");
			this.animalTypes = lookupResult.data.documents.filter((item) => item.type === "animalType");
			this.getTableData();
			this.loading = false;
		},
		// Handle Import
		async handleImport(file) {
			this.checkingFile = true;
			this.$papa.parse(file, {
				header: true,
				complete: (results) => {
					// loop through the resilts.meta.fields and check if they are in the schema
					let invalidFields = [];
					let invalidValues = [];
					results.meta.fields.forEach((field) => {
						if (!Object.prototype.hasOwnProperty.call(this.$schema.animal, field)) {
							invalidFields.push(field);
						}
					});
					let index = 2;
					results.data.forEach((row) => {
						row.entityId = row.animalId;
						// loop through the row and check if the values are valid
						if (row.animalId === undefined || row.animalId === null || row.animalId === "") {
							invalidValues.push(index);
						}
						// add deleted = false if not present
						if (row.deleted === undefined || row.deleted === null || row.deleted === "") {
							row.deleted = false;
						}
						// add auditing fields
						row.createdUserId = this.GET_currentUserProfile.entityId;
						row.createdOrgId = this.GET_currentUserProfile.orgId;
						row.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
						row.modifiedUserId = this.GET_currentUserProfile.entityId;
						row.modifiedOrgId = this.GET_currentUserProfile.orgId;
						row.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
						// remove any special characters from CPH Number
						row.cphNumber = row.cphNumber.replace(/[^a-zA-Z0-9]/g, "");
						index++;
					});
					if (invalidFields.length > 0) {
						this.inputMessage = `The following fields are invalid <strong>${invalidFields.join(", ")}</strong>. `;
					}
					if (invalidValues.length > 0) {
						if (this.inputMessage === "empty") {
							this.inputMessage = "";
						}
						this.inputMessage = this.inputMessage + ` The following rows have invalid or missing data <strong>${invalidValues.join(", ")}</strong>`;
					}
					if (invalidFields.length === 0 && invalidValues.length === 0) {
						this.inputMessage = "success";
					}
					// console.log(JSON.stringify(results.data, null, 2))
					this.inputHeaders = results.meta.fields;
					this.inputData = results.data;
				},
			});
			this.checkingFile = false;
		},
		// Import Animals
		async importAnimals() {
			const progressId = this.MIX_formatDateTimeNow("valueOf");
			this.REDIS_bulkCreateUpdate("animal", this.inputData, progressId);
			this.checkProgress(progressId);
		},
		// Check Progress of Import
		checkProgress(progressId) {
			let t = this;
			this.checkProgressInterval = setInterval(async () => {
				let progressResult = await t.REDIS_read("progress", progressId);
				if (progressResult.status !== "404") {
					t.progressStatus = progressResult.data.status;
					t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
					if (progressResult.data.status === "Completed") {
						clearInterval(t.checkProgressInterval);
					}
				}
			}, 1000);
		},
		// Finish Import
		finishImport() {
			this.progress = 0;
			this.progressStatus = "Not Started";
			this.inputMessage = "empty";
			this.inputHeaders = [];
			this.inputData = [];
			this.importBox = false;
			this.getTableData();
		},
		// Export Box CSV
		async exportCSV(itemsFrom, itemsTo, filter, specific) {
			if (itemsFrom > itemsTo) {
				this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
			} else {
				this.exportLoading = true;
				var itemsCount, tableData, csv, query, exportFileName;
				switch (true) {
					// Current Page / Current Total (filtered)
					case filter && !specific:
						itemsCount = itemsTo - itemsFrom;
						query = this.filterQuery;
						break;
					// All (Not filtered)
					case !filter && !specific:
						itemsCount = this.totalCount;
						query = `@deleted:{${this.filter.showDeleted}}`;
						break;
					// Specific (filtered/ not filtered)
					case specific:
						itemsFrom = itemsFrom - 1;
						itemsCount = itemsTo - itemsFrom;
						if (this.exportFiltered) {
							query = this.filterQuery;
						} else {
							query = `@deleted:{${this.filter.showDeleted}}`;
						}
						break;
				}
				// console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
				let exportResult = await this.REDIS_searchFor("animal", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
				tableData = JSON.parse(JSON.stringify(exportResult.data.documents));
				csv = this.$papa.unparse(tableData);
				if (itemsFrom === 0) {
					itemsFrom = 1; // This is just for filename purposes
				}
				if (itemsFrom >= 10) {
					itemsFrom = itemsFrom + 1;
				}
				switch (true) {
					case !specific && filter:
						exportFileName = `Animals_Filtered_${itemsFrom}_to_${itemsTo}`;
						break;
					case !specific && !filter:
						exportFileName = `Animals_${itemsFrom}_to_${itemsTo}`;
						break;
					case specific && this.exportFiltered:
						exportFileName = `Animals_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
						break;
					case specific && !this.exportFiltered:
						exportFileName = `Animals_Specific_${itemsFrom}_to_${itemsTo}`;
						break;
				}
				// console.log(exportFileName);
				// console.log(csv.length);
				this.$papa.download(csv, exportFileName);
				this.exportLoading = false;
			}
		},
		toggleFilters() {
			if (this.drawer) {
				this.drawer = false;
			}
			this.filterDrawer = !this.filterDrawer;
		}
	},
	created() {
		this.getTotalCount();
		this.initialise();
	},
};
</script>

<style scoped>
.d-flex {
	gap: 15px;
}
</style>

<template>
	<div class="rounded-of d-flex align-center justify-center flex-column" :class="bodyclass" :style="`minHeight: ${minheight} !important`">
		<div v-if="loading" style="height: 100% !important" class="d-flex align-center justify-center flex-column">
			<v-progress-circular :size="50" :width="5" indeterminate color="grey lighten-3"></v-progress-circular>
			<div class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">Loading</div>
		</div>
		<div v-if="!loading" class="text-h4 font-weight-light text-uppercase text-center" :class="valueclass">
			<slot name="value"></slot>
		</div>
		<div v-if="!loading" class="text-h7 text-center font-weight-normal" :class="textclass">
			<slot name="text"></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		valueclass: {
			type: String,
			default: "",
		},
		textclass: {
			type: String,
			default: "",
		},
		bodyclass: {
			type: String,
			default: "white",
		},
		minheight: {
			type: String,
			default: "100px",
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
value:empty {
	display: none !important;
}
text:empty {
	display: none !important;
}
</style>

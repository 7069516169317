<template>
	<v-dialog v-model="innerValue" class="foreground-highest" :width="calculateWidth" :style="`width: ${calculateWidth} !important;`">
		<div class="white grey--text rounded-of" :style="`width: ${calculateWidth} !important; min-height: 500px !important; position: relative !important;`">
            <header class="white--text text-h6 text-left rounded-of-top pa-4 d-flex align-center" :class="headerclass">
                <slot name="header"></slot>
            </header>
            <div class="pa-4"><slot></slot></div>
            <footer class="rounded-of-bottom full-width pa-4" :class="footerclass">
                <slot name="footer"></slot>
            </footer>
        </div>
	</v-dialog>
</template>

<script>
export default {
	// props
	props: {
		headerclass: {
			type: String,
			default: "",
		},
		footerclass: {
			type: String,
			default: "",
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		innerValue: false,
	}),
	computed: {
		calculateWidth() {
			switch (true) {
				case this.$vuetify.breakpoint === "xs":
					return "300px";
				default:
					return "600px";
			}
		},
	},
	methods: {
		close() {
			this.innerValue = false;
			// this.$emit("close");
		},
	},
	watch: {
		value: function (value) {
			this.innerValue = value;
		},
		innerValue: function (value) {
			this.$emit("input", value);
		},
	},
	mounted() {
		this.innerValue = this.value;
	},
};
</script>

<style scoped>
header:empty {
	display: none !important;
}
footer:empty {
	display: none !important;
}
footer {
	position: absolute !important;
	bottom: 0 !important;
}
</style>

<template>
	<v-navigation-drawer app permanent clipped class="" width="110" touchless>
		<div v-for="(item, index) in MIX_filteredNav('SideNav')" :key="index">
		<v-list-item :disabled="MIX_checkLevelAndStatus(item)"  @click="MIX_go(item.path)" class="text-center px-5"  >
			<v-list-item-content >
				<v-icon x-large :disabled="MIX_checkLevelAndStatus(item)">{{ item.icon }}</v-icon>
				<div :disabled="MIX_checkLevelAndStatus(item)" class="fullwidth text-center text-body-2 font-weight-bold grey--text text--darken-1">{{ item.title }}</div>
			</v-list-item-content>
		</v-list-item>
		</div>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "SideNav",
	data: () => ({
		showDrawer: false,
	}),
	computed: {
		...mapGetters({
			GET_currentUserAuth: "GET_currentUserAuth",
			GET_currentUserProfile: "GET_currentUserProfile",
			GET_userAuthenticated: "GET_userAuthenticated",
		}),
	},
	methods: {
		// checkLevelAndStatus(item) {
		// 	if (item.enabled) {
		// 		if (this.GET_userAuthenticated) {
		// 			if (item.status !== undefined && item.level !== undefined) {
		// 				// console.log("status = ' + item.status + ' user status = ' + this.GET_currentUserProfile.status)
		// 				// console.log('level = ' + item.level + ' user level = ' + this.GET_currentUserProfile.level)
		// 				let checkError = 0;
		// 				if (!item.status.includes(this.GET_currentUserProfile.status)) {
		// 					checkError++;
		// 				}
		// 				if (!item.level.includes(this.GET_currentUserProfile.level)) {
		// 					checkError++;
		// 				}
		// 				if (checkError === 0) {
		// 					return false;
		// 				} else {
		// 					return true;
		// 				}
		// 			} else {
		// 				return true;
		// 			}
		// 		}
		// 	} else {
		// 		console.log('item.enabled = ' + item.enabled)
		// 		return true;
		// 	}
		// },
	},
};
</script>

<template>
    <div>
        <v-banner app class="grey darken-1 full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
            <div class="d-flex align-center">
                <v-icon class="white--text pr-2" x-large>icons8-cow</v-icon>
                <div class="text-h6 white--text font-weight-bold">{{ display }}</div>
                <v-spacer />
                <div v-if="JSON.stringify(holdings) !== '[]'">
                    <AppButton :text="true" v-if="display === 'My Animals'" @click.native="display = 'Add Animal'" depressed class="secondary white--text"><v-icon>icons8-add</v-icon><span class="pl-2">Animal</span></AppButton>
                    <AppButton :text="true" v-else @click.native="closeAnimal()" depressed class="grey darken-2 white--text"><v-icon class="">icons8-back-to</v-icon><span class="pl-2">Animals</span></AppButton>
                </div>
            </div>
        </v-banner>
        <div style="margin-top: 80px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
        <transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
            <v-container v-if="display === 'My Animals'" class="">
                <v-row dense>
                    <!-- <v-col cols="12" xs="12" sm="4">
                </v-col> -->
                    <v-col cols="12" class="mt-1" v-if="JSON.stringify(holdings) !== '[]'">
                        <DropdownAdvanced label="Please Select Holding" v-model="activeHoldingId" :items="computedHoldings" background="grey lighten-4" />
                    </v-col>					
                    <v-col cols="12" xs="12" sm="4" class="d-flex align-center"  v-if="activeHoldingId !== ''">
                        <div><AppButton :disabled="page === 1" @click.native="backPage()" class="primary"><v-icon>icons8-back-to</v-icon></AppButton></div>
                        <div class="mx-4 flex-grow-1"><SearchField placeholder="Search" v-model="search" background="grey lighten-4" :dense="false" :clearable="false"/>
                            <!-- <br />search: {{ search }} || type {{  typeof(search) }} -->
                        </div>
                        <div><AppButton :disabled="page === computedPages" @click.native="nextPage()" class="primary"><v-icon>icons8-next-page</v-icon></AppButton></div>
                    </v-col>
                </v-row>
				<v-row class="dense" v-if="JSON.stringify(holdings) === '[]'">
					<v-col>
						<FarmCard class="pa-5 grey lighten-3 text--secondary">
							<strong>Information</strong><br />
							<i>There are currently no holdings associated with your account, please use the Add Holding button under My Holdings to link a holding.</i>
						</FarmCard>
					</v-col>
				</v-row>				
                <v-row dense class="mt-2">
                    <!-- <v-col cols="6" v-if="activeHoldingId !== ''">
                        
                    </v-col>
                    <v-col cols="6" v-if="activeHoldingId !== ''">
                        
                    </v-col> -->
                    <!-- <v-col cols="12">
                        {{ computedQuery }}
                    </v-col> -->
                    <v-col cols="12" v-if="activeHoldingId !== ''" class="text-center">
                        Showing
                        <span v-if="page === computedPages"
                            ><strong>{{ computedFrom }}</strong> to <strong>{{ totalAnimals }}</strong></span
                        >
                        <span v-else
                            ><strong>{{ computedFrom }}</strong> to <strong>{{ computedTo }}</strong> of <strong>{{ totalAnimals }}</strong></span
                        >
                        <!-- <br />Page {{ page }} of {{ computedPages }} -->
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" class="" v-for="animal in animals" :key="animal.entityId">
                        <FarmCard  :cardclass="getResult(animal?.tagNo).class === 'error' ? 'red lighten-5':'white'" class="pa-5">
                            <!-- @click.native="MIX_go('/animal')"  -->
                            <table width="100%">
                                <tr>
                                    <td valign="top" class="half-width font-weight-bold text-body-2">Identifier:</td>
                                    <td valign="top" class="half-width text-body-2">{{ animal.tagNo }}</td>
                                </tr>
                                <tr>
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Breed Code:</td>
                                    <td valign="top" class="half-width text-body-2">{{ animal.breedCode }} - {{ lookupBreedCode(animal.breedCode).substring(0,16) }}<span v-if="lookupBreedCode(animal.breedCode).length > 17">...</span></td>
                                </tr>
                                <tr>
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Sex:</td>
                                    <td valign="top" class="half-width text-body-2">
                                        <span v-if="animal.sex === 'M'">Male</span>
                                        <span v-if="animal.sex === 'F'">Female</span>
                                    </td>
                                </tr>
                                <tr v-if="animal.test !== undefined">
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Last Tested:</td>
                                    <td valign="top" class="half-width text-body-2">{{ MIX_formatDateTime(animal.test.eventDate, "ISOString", "DD-MMM-YYYY") }}<br />{{ animal.test.diseaseType }}<br />{{ animal.test.testType }}</td>
                                </tr>                          
                                <tr v-if="animal.test !== undefined">
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Test Result:</td>
                                    <td valign="top" class="half-width text-body-2"><v-chip label small class="text-uppercase" :class="getResult(animal?.tagNo).class">{{ getResult(animal?.tagNo).text }}</v-chip></td>
                                </tr>                                       
                            </table>
                        </FarmCard>
                    </v-col>
                </v-row>
                <v-row dense class="mb-10">
                    <v-col cols="12" xs="12" sm="4" class="d-flex align-center"  v-if="activeHoldingId !== ''">
                        <div><AppButton :disabled="page === 1" @click.native="backPage(); MIX_scrollToTop();" class="primary"><v-icon>icons8-back-to</v-icon></AppButton></div>
                        <div class="mx-4 flex-grow-1"></div>
                        <div><AppButton :disabled="page === computedPages" @click.native="nextPage(); MIX_scrollToTop();" class="primary"><v-icon>icons8-next-page</v-icon></AppButton></div>
                    </v-col>

                </v-row>
            </v-container>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "HomeView",
    data: () => ({
        loading: false,
        display: "My Animals",
        activeHoldingId: "",
        holdings: [],
        totalHoldings: 0,
        search: "",
        animals: [],
        totalAnimals: 0,
        page: 1,
        offset: 10,
        breedCodes: [],
    }),
    components: {},
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        computedHoldings() {
            return this.holdings.map((holding) => ({
                text: holding.name,
                description: holding.town + " " + holding.postcode,
                cphNumber: holding.cphNumber,
                managementGroups: holding.managementGroups,
                value: holding.entityId,
            }));
        },
        computedPages() {
            return Math.ceil(this.totalAnimals / this.offset);
        },
        computedFrom() {
            if (this.page === 0) {
                return 1;
            } else {
                return (this.page - 1) * this.offset + 1;
            }
        },
        computedTo() {
            if (this.page === 0) {
                return this.offset;
            }
            return Math.min(this.computedFrom + this.offset - 1, this.totalAnimals);
        },
        computedQuery() {
            // let query = "";
            // if (this.search.length > 2) {
            //     query += `${this.search}`;
            // } else {
            //     query += "*";
            // }
            // query += ` @deleted:{false} @type:{Cattle} @holdingOrgId:{${this.activeHoldingId}}`;
            if (this.search !== "" && this.search !== undefined) {
                return `@tagNo:${this.search}*`;
            } else {
                return "*";
            }
        },
    },
    methods: {
        async initialise() {
            this.loading = true;
            // Get Lookup Data
            let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{ctsBreedCode}");
            this.breedCodes = lookupResult.data.documents.filter((item) => item.type === "ctsBreedCode");
            await this.getHoldings();
            this.loading = false;
        },
        async getHoldings() {
            try {
                this.loading = true;
                let query = `@deleted:{false} @type:{Farm} @ownerUserId:{${this.GET_currentUserProfile.entityId}}`;
                // console.log(query)
                let holdingsResult = await this.REDIS_searchFor("org", "", "", "", "", query);
                this.holdings = holdingsResult.data.documents;
                this.totalHoldings = holdingsResult.data.total;
                this.loading = false;
            } catch (error) {
                console.log("error getting holdings");
                console.error(error);
                this.loading = false;
            }
        },
        async getAnimals() {
            try {
                this.loading = true;
                // collection, offset, count, sortBy, sortOrder, query
                // console.log("computedQuery = ", this.computedQuery);
                // REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query)
                let animalsResult = await this.REDIS_searchFor("animal", this.page, this.offset, "", "", this.computedQuery);
                // console.log('animalsResult = ', animalsResult.data.total)
                // TODO - Make this serverside combined search query to look for animals and their last test  and result
                let animals = animalsResult.data.documents;
                let identifiers = animals.map((item) => item.tagNo).join("|")
                // console.log('identifiers = ' + JSON.stringify(identifiers))
                if (identifiers !== "") {
                    // console.log('identifiers = ', identifiers)
                    let testdetailResult = await this.REDIS_searchFor("testdetail", "", "", "", "", `@identifier:{${identifiers}} @deleted:{false}`);
                    let testdetail = testdetailResult.data.documents;
                    // console.log("testdetail = ", JSON.stringify(testdetail, null, 2))
                    // loop through animals and add testdetail
                    animals.forEach((animal) => {
                        let test = testdetail.find((item) => item.identifier === animal.tagNo);
                        if (test) {
                            animal.test = test;
                        }
                    });                 
                }
                // console.log(JSON.stringify(animalsResult, null, 2));
                this.animals = animals;
                this.totalAnimals = animalsResult.data.total;
                this.loading = false;
            } catch (error) {
                console.log("error getting animals");
                console.error(error);
                this.loading = false;
            }
        },
        nextPage() {
            // if (this.page = 0)
            this.page = this.page + 1;
            this.getAnimals();
        },
        backPage() {
            if (this.page > 1) {
                this.page = this.page - 1;
                this.getAnimals();
            }
        },
        lookupBreedCode(code) {
            let breed = this.breedCodes.find((item) => item.value === code);
            return breed ? breed.text : "";
        },
        closeAnimal() {
            this.display = 'My Animals'
        },
        getResult(tagNo) {
            switch (tagNo) {
                case "UK741568303821":
                    return {
                        text: "Herd positive animal",
                        class: "error"
                    };
                case "UK741568203085":
                    return {
                        text: "Herd negative animal",
                        class: "success"
                    };
                default:
                    return {
                        text: "Result Pending",
                        class: "warning"
                    };
            }
        }

    },
    watch: {
        activeHoldingId() {
            this.getAnimals();
        },
        computedQuery: {
            handler() {
                // if (JSON.stringify(this.search) === '{}') {
                //     this.search = "";
                //     this.getAnimals();
                // }
                if (this.search?.length > 2) {
                    this.page = 1;
                    this.getAnimals();
                } else if (this.search?.length === 0) {
                    this.getAnimals();
                }
            },
            deep: true,
        },
    },
    created() {
        this.initialise();
        if (this.$route.params.id !== undefined) {
            this.activeHoldingId = this.$route.params.id;
        }
    },
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;
}
</style>

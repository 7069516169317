<template>
    <v-navigation-drawer v-model="showDrawer" absolute temporary class="foreground-highest" width="75%" overlay-opacity="0.8">
        <v-row class="pt-5" dense>
            <v-col cols="12" class="text-center px-5">
                <img src="@/assets/BVD Logo full.png" width="150" height="184" alt="Gwaredu BVD" />        
            </v-col>
            <v-col cols="12" class="text-center px-5" v-for="(item, index) in MIX_filteredNav('NavDrawer')" :key="index">
                <v-btn :disabled="MIX_checkLevelAndStatus(item)" @click="MIX_go(item.path)" class="text-body-2 font-weight-bold grey grey--text text--darken-3 lighten-2 rounded-of menubutton" block depressed x-large
                    ><v-icon class="mr-3 primary--text" x-large>{{ item.icon }}</v-icon>
                    <div class="full-width text-left">{{ item.title }}</div></v-btn
                >
            </v-col>
            <v-col cols="12" class="text-center px-5">
                <v-btn @click="logout()" class="text-body-2 font-weight-bold grey grey--text text--darken-3 lighten-2 rounded-of menubutton" block depressed x-large
                    ><v-icon class="mr-3 primary--text" x-large>icons8-open-door</v-icon>
                    <div class="full-width text-left">Log Out</div></v-btn
                >
            </v-col>
        </v-row>
        <div class="mt-5 text-center">
            <!-- <v-img src="@/assets/colegsirgar_logo.png" contain height="50"></v-img> -->
            <!-- <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width text-center mt-2">{{ GET_currentUserProfile.firstName }} {{ GET_currentUserProfile.lastName }}</div>
                <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width text-center mt-2">Version {{ appVersion }}</div>
                <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width text-center mt-2">Status: {{ GET_currentUserProfile.status }}</div>
                <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width text-center mt-2">OrgType: {{ GET_currentUserProfile.orgType }}</div>
                <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width text-center mt-2">Level: {{ GET_currentUserProfile.level }}</div> -->
            <div class="caption font-weight-bold grey--text text--darken-1 mt-2">Copyright <a :href="$config.website" target="_blank">{{ $config.companyName }}</a> {{ MIX_formatDateTimeNow('YYYY') }}</div>
			<div class="caption grey--text text--darken-3 full-width mt-2">Version {{ appVersion }}</div>
        </div>
    </v-navigation-drawer>
</template>

<script>
const version = require('@/../package.json').version
import { mapGetters, mapActions } from "vuex";
export default {
    data: () => ({
        showDrawer: false,
        menu: [
            {
                title: "Farm Lookup",
                path: "/search/",
                icon: "icons8-search",
                orgType: ["Farm", "Vet"],
                level: ["GuestUser", "FarmUser", "FarmAdmin", "FarmManager", "VetManager", "VetAdmin", "VetUser"],
                status: ["Approved", "Pending"],
            },
            {
                title: "My Holdings",
                path: "/myholdings/",
                icon: "icons8-farm-2",
                orgType: ["Farm"],
                level: ["GuestUser", "FarmUser", "FarmAdmin", "FarmManager"],
                status: ["Approved", "Pending"],
            },
            {
                title: "My Vets",
                path: "/myvet/",
                icon: "icons8-rod-of-asclepius",
                orgType: ["Farm"],
                level: ["FarmAdmin", "FarmManager"],
                status: ["Approved"],
            },
            {
                title: "My Animals",
                path: "/myanimals/",
                icon: "icons8-cow",
                orgType: ["Farm"],
                level: ["FarmUser", "FarmAdmin", "FarmManager"],
                status: ["Approved"],
            },
            {
                title: "Certificates",
                path: "/myanimals/",
                icon: "icons8-diploma",
                orgType: ["Farm"],
                level: ["FarmManager"],
                status: ["Approved"],
            },
            {
                title: "Admin",
                path: "/settings/",
                icon: "icons8-services",
                orgType: ["Farm", "Vet"],
                level: ["GuestUser", "FarmUser", "FarmAdmin", "FarmManager"],
                status: ["Approved"],
            },
        ],
    }),
    computed: {
        ...mapGetters({
            GET_showDrawer: "GET_showDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_userAuthenticated: "GET_userAuthenticated",
        }),
        appVersion() {
            return version;
        },
        computedViewportheight() {
            return window.innerHeight;
        },
        filteredMenu() {
            if (this.GET_userAuthenticated) {
                return this.menu.filter((item) => {
                return item.orgType.includes(this.GET_currentUserProfile.orgType);
                });                
            } else {
                return []
            }
        },
    },
    methods: {
        ...mapActions({
            ACT_showDrawer: "ACT_showDrawer",
        }),
        logout() {
            this.FIREBASE_logout();
        },
    },
    watch: {
        GET_showDrawer: function () {
            this.showDrawer = this.GET_showDrawer;
        },
        showDrawer: function () {
            this.ACT_showDrawer(this.showDrawer);
        },
    },
    created() {
        this.showDrawer = this.GET_showDrawer;
    },
};
</script>

<style>
/* .v-btn__content {
background-color: black;
} */
</style>

<style scoped>
.menubutton {
    min-height: 20px !important;
}
.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
}
</style>

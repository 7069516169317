<template>
	<v-app-bar clipped-left clipped-right app color="primary" dark class="elevation-0 foreground-high" height="90">
		<div class="secondary rounded-of d-flex align-center full-width px-3 py-2">
			<img src="@/assets/BVD-Icon-logo.png" width="45" height="37" alt="BVD Icon" />
			<v-spacer />
			<img src="@/assets/Gwaredu-BVD-Text-Only.png" width="150" height="26" alt="Gwaredu BVD" />
			<!-- <LanguageSelector /> -->
			<v-spacer />
			<v-icon aria-label="Menu" color="white" @click="updateShowDrawer()" v-if="$vuetify.breakpoint.xsOnly">icons8-menu</v-icon>
			<DropdownMenu v-if="!$vuetify.breakpoint.xsOnly" icon="icons8-menu" buttonclass="secondary">
					<div v-if="GET_userAuthenticated" @click="showUserDetails++" class="grey--text text--darken-3 full-width mt-5">{{ GET_currentUserProfile.firstName }} {{ GET_currentUserProfile.lastName }}</div>
					<div v-if="GET_userAuthenticated" class="body-2 font-weight-bold grey--text text--darken-3 full-width">{{ GET_currentUserProfile.orgName }}</div>					
					<div v-if="showUserDetails > 5">
						<div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width">Status: {{ GET_currentUserProfile.status }}</div>
						<div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width">OrgType: {{ GET_currentUserProfile.orgType }}</div>
						<div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width">Level: {{ GET_currentUserProfile.level }}</div>
					</div>
					<v-divider class="my-4"/>
					<AppButton block @click.native="FIREBASE_logout()" buttonclass="secondary--text"><v-icon>icons8-open-door</v-icon><span class="ml-2">Log Out</span></AppButton>
					<div class="caption grey--text text--darken-3 full-width mt-2">Version {{ appVersion }}</div>
			</DropdownMenu>
		</div>
	</v-app-bar>
</template>

<script>
const version = require('@/../package.json').version
import { mapGetters, mapActions } from "vuex";
// import LanguageSelector from "@/components/LanguageSelector.vue";
export default {
	name: "TopNav",
	data: () => ({
		showMenu: false,
		showUserDetails: 0,
	}),
	components: {
		// LanguageSelector,
	},
	computed: {
		...mapGetters({
			GET_showDrawer: "GET_showDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_userAuthenticated: "GET_userAuthenticated",
        }),
		appVersion() {
            return version;
        },
	},
	methods: {
		...mapActions({
			ACT_showDrawer: "ACT_showDrawer",
		}),
		updateShowDrawer() {
			this.ACT_showDrawer(!this.GET_showDrawer);
		},
		changeLanguage(language) {
			this.$i18n.locale = language;
		},
	},
};
</script>

<template>
	<div class="d-flex align-center">
		<input :disabled="disabled" type="file" :accept="accept" @change="onFileChange" />
	</div>
</template>

<script>
export default {
	props: {
		accept: {
			type: String,
			default: ".csv",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		innerValue: "",
	}),
	methods: {
		onFileChange(event) {
			const file = event.target.files[0];
			this.innerValue = file;
		},
	},
	watch: {
		value: function (value) {
			this.innerValue = value;
		},
		innerValue: function (value) {
			this.$emit("input", value);
		},
	},
	mounted() {
		this.innerValue = ""
	},
};
</script>

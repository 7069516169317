<template>
	<div>
		<v-banner app class="grey darken-1 full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
			<div class="d-flex align-center">
				<v-icon class="white--text pr-2" x-large>icons8-search</v-icon>
				<div class="text-h6 white--text font-weight-bold">{{ display }}</div>
				<v-spacer />
				<!-- <div>
                    <AppButton :text="true" v-if="display === 'My Animals'" @click.native="display = 'Add Holding'" depressed class="secondary white--text"><v-icon>icons8-add</v-icon><span class="pl-2">Animal</span></AppButton>
                    <AppButton :text="true" v-else @click.native="closeHolding()" depressed class="grey darken-2 white--text"><v-icon class="">icons8-back-to</v-icon><span class="pl-2">Animals</span></AppButton>
                </div> -->
			</div>
		</v-banner>
		<div style="margin-top: 80px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>

		<v-container class="">
			<v-row dense>
				<!-- <v-col cols="12" xs="12" sm="4">
                </v-col> -->
				<!-- <v-col cols="12" class="mt-1">
                        <DropdownAdvanced label="Filter by Holding" v-model="activeHoldingId" :items="computedHoldings" background="grey lighten-4" />
                    </v-col> -->
				<v-col cols="12" xs="12" sm="4" class="d-flex align-center">
					<!-- <div><AppButton :disabled="page === 1" @click.native="backPage()" class="primary"><v-icon>icons8-back-to</v-icon></AppButton></div> -->
					<div class="flex-grow-1">
						<SearchField placeholder="Search" v-model="search" background="grey lighten-4" :dense="false" :clearable="true" />

						<!-- <br />search: {{ search }} || type {{  typeof(search) }} -->
					</div>
					<div class="ml-3">
						<AppButton @click.native="getHolding()" class="secondary">Go</AppButton>
					</div>
				</v-col>
				<!-- <div @click="search = 'UK741568403521'" class="caption mt-2 ml-5">Click for Example Search</div> -->
			</v-row>
			<v-row class="dense" v-if="JSON.stringify(holdings) === '[]'">
				<v-col>
					<FarmCard class="pa-5 grey lighten-3 text--secondary">
						<strong>Information</strong><br />
						<i>To lookup a Farm's Certification Status please use the Search Bar above and click [Go].  You can search using the:</i>
						<ul>
							<li><i>Farm Name</i></li>
							<li><i>Partial Address e.g Town, County, Postcode</i></li>
						</ul>
					</FarmCard>
				</v-col>
			</v-row>
		</v-container>
		<transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
			<v-container class="" v-if="JSON.stringify(holdings) !== '[]'">
				<div class="full-width text-h6 text-center primary--text">{{ holdingsTotal }} Farms Found</div>
				<FarmCard class="pa-5 mt-3" v-for="(holding, index) in holdings" :key="index">
					<!-- @click.native="MIX_go('/animal')"  -->
					<table width="100%">
						<tr>
							<td valign="top" class="half-width font-weight-bold text-body-2">Farm Name:</td>
							<td valign="top" class="half-width text-body-2">{{ holding?.name }}</td>
						</tr>
						<tr>
							<td valign="top" class="half-width font-weight-bold text-body-2">Holding:</td>
							<td valign="top" class="half-width text-body-2">{{ holding?.cphNumber | VMask("##/###/####") }}</td>
						</tr>
						<tr>
							<td valign="top" class="half-width font-weight-bold text-body-2">Address:</td>
							<td valign="top" class="half-width text-body-2">{{ MIX_formatAddress(holding) }}</td>
						</tr>
						<tr>
							<td valign="top" class="half-width font-weight-bold text-body-2">Certificate:</td>
							<td valign="top" class="half-width text-body-2">
								<v-chip small label class="white--text font-weight-bold mr-2" :class="MIX_certificateStatus(holding.certificateStatus)">
									<span v-if="holding.certificateStatus !== '' && holding.certificateStatus !== null">{{ holding.certificateStatus }}</span>
									<span v-else>Positive</span>

									</v-chip>
							</td>
						</tr>
						<!--         <tr>
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Breed Code:</td>
                                    <td valign="top" class="half-width text-body-2">{{ holding?.breedCode }} - {{ lookupBreedCode(animal?.breedCode).substring(0,16) }}<span v-if="lookupBreedCode(animal?.breedCode).length > 17">...</span></td>
                                </tr>
                                <tr>
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Sex:</td>
                                    <td valign="top" class="half-width text-body-2">
                                        <span v-if="animal?.sex === 'M'">Male</span>
                                        <span v-if="animal?.sex === 'F'">Female</span>
                                    </td>
                                </tr> -->
						<!-- <tr v-if="animal?.test !== undefined">
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Last Tested:</td>
                                    <td valign="top" class="half-width text-body-2">{{ MIX_formatDateTime(animal?.test.eventDate, "ISOString", "DD-MMM-YYYY") }}<br />{{ animal?.test.diseaseType }}<br />{{ animal?.test.testType }}</td>
                                </tr>                          
                                <tr v-if="animal?.test !== undefined">
                                    <td valign="top" class="half-width text-body-2 font-weight-bold">Test Result:</td>
                                    <td valign="top" class="half-width text-body-2"><v-chip label small class="warning">Pending</v-chip></td>
                                </tr>                                        -->
					</table>
				</FarmCard>
			</v-container>
		</transition>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HomeView",
	data: () => ({
		loading: false,
		display: "Farm Lookup",
		activeHoldingId: "",
		holdings: [],
		totalHoldings: 0,
		search: "",
		page: 1,
		offset: 10,
		breedCodes: [],
	}),
	components: {},
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
	},
	watch: {
		search: function () {
			if (this.search === "") {
				this.holdings = [];
			}
		},
	},
	methods: {
		async initialise() {
			this.loading = true;
			// Get Lookup Data
			this.loading = false;
		},
		async getHolding() {
			this.loading = true;
			let holdingsResult = await this.REDIS_searchFor("org", "", "", "", "", `${this.search}*`);
			// console.log("holdingsResult", JSON.stringify(holdingsResult, null, 2));
			this.holdings = holdingsResult.data.documents;
			this.holdingsTotal = holdingsResult.data.total;
			this.loading = false;
		},
	},
	created() {
		this.initialise();
	},
};
</script>

<style>
.noboxshadow {
	box-shadow: none !important;
}
</style>

<template>
    <div class="d-flex align-center">
        <v-select
        :disabled="disabled"
        label="Hours"
        :items="hours"
        v-model="selectedHour"
        filled
        rounded
        class="rounded-of frontend-higher"
        background-color="grey lighten-4"
        hide-details="auto"
        :menu-props="offset"
        :attach="true"
        clearable
        @click:clear="clearHours()">
    </v-select>

        <v-select
        :disabled="disabled"
        label="Mins"
        :items="mins"
        v-model="selectedMinutes"
        filled
        rounded
        class="ml-3 rounded-of frontend-higher"
        background-color="grey lighten-4"
        hide-details="auto"
        :menu-props="offset"
        :attach="true"
        clearable
        @click:clear="clearMins()">        
    </v-select>

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        background: {
            type: String,
            default: "grey lighten-4",
        },
        hours: {
            type: Array,
            default: () => ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
        },
        mins: {
            type: Array,
            default: () => ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
        },     
        offset: {
            type: Object,
            default: () => { 
                return { top: false, offsetY: true }
            }
        },
        disabled: {
            type: Boolean,
            default: false,
        },   
    },
    data: ()=> ({
        selectedHour: "",
        selectedMinutes: ""
    }),
    computed: {
        // Computed Date
        innerValue() {
            if (this.selectedHour === "") {
                return "00" + ":" + this.selectedMinutes;
            } else if (this.selectedMinutes === "") {
                return this.selectedHour + ":" + "00";
            } else {
                return this.selectedHour + ":" + this.selectedMinutes;
            }
        },
    },
    methods: {
        clearHours() {
            setTimeout(() => {
                this.selectedHour = "00";
            }, 1);
        },
        clearMins() {
            setTimeout(() => {
                this.selectedMinutes = "00";
            }, 1);
        },
    },      
    watch: {
        value: function (value) {
            this.innerValue = value;
        },        
        // Update Parent v-model
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
};
</script>

<template>
    <div>
        <v-banner app class="darkgrey full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
            <div class="d-flex align-center">
                <div>
                    <v-btn @click="$router.back()" depressed class="grey white--text"><v-icon class="pr-2">icons8-back-to</v-icon>Back</v-btn>
                </div>
                <v-spacer />
            </div>
        </v-banner>
        <v-container class="" style="margin-top: 80px !important">
            <v-row class="dense">
                <v-col cols="12" xs="12" sm="4" class="">
                    <FarmCard headerclass="success" class="">
                        <template v-slot:header><div class="pa-5">Herd Negative Animal</div></template>
                        <p class="pa-5">From a BVD free herd that doesn’t have an individual status. The BVD database supports this response to a query on an individual animal.</p>

                        <v-simple-table class="mx-2">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Calories</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="font-weight-bold">Identifier:</td>
                                        <td>123456709</td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-bold">Last Test:</td>
                                        <td>20-Nov-2022</td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-bold">Test Result:</td>
                                        <td>Herd Negative Animal</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </FarmCard>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "HomeView",
    data: () => ({
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
        items: [
            { name: "Item 1", v: "value 1" },
            { name: "Item 2", v: "value 2" },
            { name: "Item 3", v: "value 3" },
            { name: "Item 4", v: "value 4" },
        ],
    }),
    components: {
    },
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;
}
</style>

<template>
    <div class="rounded-of" :class="cardclass" :style="`minHeight: ${minheight} !important`">
        <header class="white--text text-h6 text-center rounded-of-top" :class="headerclass" >
            <slot name="header"></slot>
        </header>
        <main><slot></slot></main>
        <footer class="rounded-of-bottom" :class="footerclass">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        headerclass: {
            type: String,
            default: "",
        },
        footerclass: {
            type: String,
            default: "",
        },
        minheight: {
            type: String,
            default: "100px",
        },    
        cardclass: {
            type: String,
            default: "white",
        },    
    }
};
</script>

<style scoped>
header:empty {
    display: none !important;
}
footer:empty {
    display: none !important;
}
</style>

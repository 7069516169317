<template>
	<div>
		<v-banner app class="grey darken-1 full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
			<div class="d-flex align-center">
				<v-icon class="white--text pr-2" x-large>icons8-timeline-week</v-icon>
				<div class="text-h6 white--text font-weight-bold">Holdings</div>
				<v-spacer />
				<div>
					<!-- <v-btn v-if="display === 'ListHoldings'" @click="display = 'ViewHolding'" depressed class="primary white--text"><v-icon class="pr-2">icons8-add</v-icon>Add Holding</v-btn> -->
					<v-btn v-if="display !== 'ListHoldings'" @click="closeHolding()" depressed class="primary white--text"><v-icon class="">icons8-multiply</v-icon></v-btn>
				</div>
			</div>
		</v-banner>
		<div style="margin-top: 80px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'accent'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
			<v-container v-if="display === 'ListHoldings'" class="" fluid>
				<v-row class="dense">
					<v-col cols="12" xs="12" sm="4" class="" v-for="(holding, index) in holdings" :key="index">
						<FarmCard class="pa-4" @click.native="viewHolding(holding.entityId)" style="min-height: 180px !important;">
							<div class="d-flex align-center" dense>
								<div class="d-flex align-start justify-start flex-column">
									<div class="text-h6 primary--text full-width">
										<div>{{ holding.name }}</div>
										<div class="text-caption text-uppercase white--text font-weight-bold" :class="MIX_accountStatus(holding.status) + '--text'" v-if="holding.status !== 'Approved'">{{ holding.status }}</div>
									</div>
									<div class="text-body-2 text--secondary my-3">{{ MIX_formatAddress(holding) }}</div>
									<div class="d-flex align-center full-width mt-2">
										<div class="d-flex align-center" v-if="holding.cattleCount !== 0">
											<v-icon class="primary--text">icons8-cow</v-icon>
											<div class="ml-1 mr-3 font-weight-bold text--secondary">{{ holding.cattleCount }}</div>
										</div>
										<v-chip small label class="white--text font-weight-bold mr-2" :class="MIX_certificateStatus(holding.certificateStatus)">
											<span  v-if="holding.certificateStatus !== ''">{{ holding.certificateStatus }}</span>
											<span v-else>Positive</span>


											</v-chip>

										<v-spacer />
									</div>
								</div>
								<v-spacer />
								<div class="d-flex align-center justify-right">
									<v-btn @click="viewHolding(holding.entityId)" icon><v-icon large class="grey--text">icons8-next-page</v-icon></v-btn>
								</div>
							</div>
						</FarmCard>
					</v-col>
				</v-row>
			</v-container>
			<ViewHolding v-if="display === 'ViewHolding'" :orgdata="activeHolding" v-on:close="closeHolding()" />
		</transition>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HoldingsList",
	components: {
		ViewHolding: () => import("@/views/vet/ViewHolding.vue"),
	},
	data: () => ({
		display: "ListHoldings",
		// display: "ListHoldings",
		loading: false,
		search: "",
		vets: [],
		vetsTotal: 0,
		vetholdings: [],
		vetholdingsTotal: 0,
		holdings: [],
		holdingsTotal: 0,
		activeHolding: {},
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
	},
	methods: {
		async initialise() {
			this.loading = true;
			// Get Vets Linked to this User
			await this.MIX_getVetsByOwner(this.GET_currentUserProfile.entityId).then((vetsResult) => {
				if (vetsResult.success) {
					this.vets = vetsResult.data;
					this.vetsTotal = vetsResult.total;
				}
			});
			// Get Vet Holdings Linked to this User Vet Practices
			await this.MIX_getVetHoldingsByVet(this.vets).then((vetholdingsResult) => {
				if (vetholdingsResult.success) {
					this.vetholdings = vetholdingsResult.data;
					this.vetholdingsTotal = vetholdingsResult.total;
				}
			});
			// Get Holding Details Linkted to this User Vet Practices
			let holdingsResult = await this.MIX_getHoldingsByVetHoldings(this.vetholdings)
				if (holdingsResult.success) {
					for (var i = 0; i < holdingsResult.total; i++) {
						let holding = holdingsResult.data[i];
						// console.log(holding.entityId)
						let cattleCount = await this.REDIS_countFor("animal", "", "", "", "", `@deleted:{false} @type:{Cattle} @holdingOrgId:{${holding.entityId}}`);
						// console.log(cattleCount)
						holding.cattleCount = cattleCount.total;
					}

					this.holdings = holdingsResult.data;
					// console.log('this.holdings', JSON.stringify(this.holdings, null, 2))
					this.holdingsTotal = holdingsResult.total;
				}
			this.loading = false;
		},
		async viewHolding(entityId) {
			try {
				this.loading = true;
				let holdingResult = await this.REDIS_read("org", entityId);
				this.activeHolding = holdingResult.data;
				let cattleCount = await this.REDIS_countFor("animal", "", "", "", "", `@deleted:{false} @type:{Cattle} @holdingOrgId:{${this.activeHolding.entityId}}`);
				this.activeHolding.cattleCount = cattleCount.total;
				this.display = "ViewHolding";
				this.loading = false;
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Loading Holding", color: "error", timeout: "4000" });
				console.error(error);
				this.loading = false;
			}
		},
		closeHolding() {
			this.display = "ListHoldings";
			this.initialise();
			this.activeHolding = null;
		},
	},
	created() {
		this.initialise();
		// console.log(this.GET_currentUserProfile.entityId);
		// this.getTests();
	},
};
</script>

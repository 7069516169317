<template>
    <div>
        <!-- <pre>{{ datadown[1].value }}</pre> -->
        <v-chart class="chart" :option="option" :loading="loading"/>
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import { TooltipComponent } from 'echarts/components';

use([GridComponent, CanvasRenderer, PieChart, TooltipComponent]);

export default defineComponent({
    name: "PieChart",
    data: () => ({
        loading: false,
        updatedData: [],
        option: ref({
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    // name: 'Predicted Vote',
                    // color: [
					// 	"#DA4C4C",
					// 	"#CD7F32",
					// 	"#C0C0C0",
					// 	"#FDC000",	
                    // ],
                    type: 'pie',
					radius: ['40%', '70%'],
                    data: [
                    ],
                    emphasis: {
                        itemStyle: {
                            // color: '#c23531',
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        })
    }),
    components: {
        VChart,
    },
    watch :{
        datadown: {
            handler: function (newData) {
                this.option.series[0].data = newData;
                // this.chart.clear();
                // this.chart.setOption(this.option);
            },
            deep: true,
        },
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
        xaxisdata: {
            type: Array,
            default: () => [],
        },
        datadown: {
            type: Array,
            default: () => [],
        }
    },
    provide: {
        [THEME_KEY]: "light",
    },
    created(){
        //DONT FORGET THAT SERIES DATA IS AN ARRAY OF OBJECTS
        this.option.series[0].data = this.datadown;
        // console.log("Datadown "+JSON.stringify(this.datadown));
        // console.log("SeriesData "+this.option.series.name);
        // this.chart.clear();
        // this.chart.setOption(this.option);
    },
});
</script>

<style scoped>
.chart {
    width: 100% !important;
    height: 350px !important;
    margin-bottom: -40px;
    margin-top: -60px;
    /* height: 100vh; */
}
</style>
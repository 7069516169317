import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/LoginView.vue";

// All Users
import SearchView from "@/views/SearchView.vue";
import HomeView from "@/views/HomeView.vue";
import CertificateView from "@/views/CertificateView.vue";

// Farm Views
import MyHoldings from "@/views/farm/MyHoldings.vue";
import MyAnimals from "@/views/farm/MyAnimals.vue";
import AnimalView from "@/views/AnimalView.vue";
import RegisterView from "@/views/RegisterView.vue";
import MyVet from "@/views/farm/MyVet.vue";
import NotAuthorised from "@/views/NotAuthorised.vue";

// Vet Views
import TestEvents from "@/views/vet/TestEvents.vue";
import HoldingsList from "@/views/vet/HoldingsList.vue";

// Admin Views
import HomeAdmin from "@/views/admin/HomeAdmin.vue";
import AnimalsAdmin from "@/views/admin/AnimalsAdmin.vue";
import TestEventsAdmin from "@/views/admin/TestEventsAdmin.vue";
import ResultsAdmin from "@/views/admin/ResultsAdmin.vue";
import VetsAdmin from "@/views/admin/VetsAdmin.vue";
import HoldingsAdmin from "@/views/admin/HoldingsAdmin.vue";
import UsersAdmin from "@/views/admin/UsersAdmin.vue";

// import axios from "axios";

// import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "@/store.js";

Vue.use(VueRouter);

const routes = [
	// All Users
	// Authentication Note Required
	{ path: "/login", name: "Login", component: Login },
	{ path: "/notauthorised", name: "NotAuthorised", component: NotAuthorised, },
	{ path: "/register", name: "Register", component: RegisterView },
	{ path: "/certificate", name: "Certificate", component: CertificateView, meta: { requiresAuth: false, hideNavbar: true } },
	// Authentication Required
	{ path: "/", name: "HomeView", component: HomeView, meta: { requiresAuth: true, hideNavbar: false } },
	{ path: "/search", name: "SearchView", component: SearchView, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Holding","Vet","Project"], level: ["FarmUser","FarmManager", "VetUser","VetManager","VetAdmin", "ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	// Famer Users
	{ path: "/myholdings", name: "MyHoldings", component: MyHoldings, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Holding"], level: ["FarmUser","FarmManager"], status: ["Approved","Pending"] } } },
	{ path: "/myanimals/:id?", name: "MyAnimals", component: MyAnimals, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Holding"], level: ["FarmUser","FarmManager"], status: ["Approved"] } } },
	{ path: "/myvet", name: "MyVet", component: MyVet, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Holding"], level: ["FarmUser","FarmManager"], status: ["Approved"] } } },
	{ path: "/animal", name: "AnimalView", component: AnimalView, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Holding"], level: ["FarmUser","FarmManager"], status: ["Approved","Pending"] } } },
	// Vet Users
	{ path: "/testevents", name: "TestEvents", component: TestEvents, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Vet"], level: ["VetUser","VetManager"], status: ["Approved"] } } },
	{ path: "/holdingslist", name: "HoldingsList", component: HoldingsList, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Vet"], level: ["VetUser","VetManager"], status: ["Approved"] } } },
	// Project Users
	{ path: "/admin/homeadmin", name: "HomeAdmin", component: HomeAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	{ path: "/admin/holdings", name: "HoldingsAdmin", component: HoldingsAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	{ path: "/admin/testevents", name: "TestEventsAdmin", component: TestEventsAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	{ path: "/admin/animals", name: "AnimalsAdmin", component: AnimalsAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	{ path: "/admin/results", name: "ResultsAdmin", component: ResultsAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	{ path: "/admin/vets", name: "VetsAdmin", component: VetsAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
	{ path: "/admin/users", name: "UsersAdmin", component: UsersAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { orgType: ["Project"], level: ["ProjectAdmin","ProjectManager"], status: ["Approved"] } } },
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

const checkLevelAndStatus = (item, userProfile, userAuthenticated) => {
	if (userAuthenticated) {
		if (item.status !== undefined && item.level !== undefined) {
			let checkError = 0;
			if (!item.status.includes(userProfile.status)) {
				checkError++;
			}
			if (!item.level.includes(userProfile.level)) {
				checkError++;
			}
			if (checkError === 0) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}
};

router.beforeEach(async (to, from, next) => {
	try {
		// console.log((to.meta.requiresAuth ? 'requiresAuth' : 'noAuth') + ' ' + to.path )
		// Does route require Auth
		if (to.meta.requiresAuth) {
			// Yes - Route Requires Auth
			// Update Service Worker
			navigator.serviceWorker.register("/service-worker.js").then((reg) => {
				reg.update();
			});
			// Get User Profile
			let userProfile = store.state.currentUserProfile;
			// If User Profile is null then user needs to login
			if (userProfile === null) {
				// Redirect to Login
				next({ name: "Login" });
			} else {
				if (to.meta.item !== undefined) {
					// console.log('to.meta.item = ' + JSON.stringify(to.meta.item, null, 2))
					// console.log('userProfile = ' + JSON.stringify(userProfile, null, 2))
					if (checkLevelAndStatus(to.meta.item, userProfile, true)) {
						// console.log('Unauthorised Access');
						next('/notauthorised');
					} else {
						// console.log('Authorised Access');
						next();
					}
				} else {
					// Move to Next Route
					next();
				}
			}
		} else {
			// No - Route Does Not Require Auth
			// Move to Next Route
			next();
		}
	} catch (error) {
		console.error("An Error Occured", error);
		next('/notauthorised');
	}
});

export default router;

<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="px-5 pt-5">
			<v-col cols="12">
				<div class="d-flex align-start">
					<div>
						<div class="text-h4 primary--text d-flex">
							Results
							<v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
						</div>
					</div>
					<v-spacer />
					<!-- Search -->
					<SearchField label="Search by Animal ID" v-model="filter.animalId" :dense="true" />
					<!-- Search CPH -->
					<SearchField label="Search By CPH Number" v-model="filter.cphNumber" :dense="true" mask="##/###/####" placeholder="##/###/####" />
					<AppButton @click.native="getTableData"><v-icon>icons8-refresh</v-icon></AppButton>
					<AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>					
					<!-- <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addResult">Add Result</AppButton> -->
					<div class="white--text">
						<DropdownMenu>
							<div class="d-flex align-center">
								<v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
								<div class="font-weight-bold secondary--text">Show Deleted</div>
							</div>
							<v-divider />
							<div class="d-flex align-center pt-2">
								<AppButton @click.native="importBox = true" block buttonclass="secondary--text"><v-icon>icons8-csv</v-icon><span class="ml-2">Import</span></AppButton>
							</div>
							<div class="d-flex align-center pt-1 pb-2">
								<AppButton @click.native="exportBox = true" block buttonclass="secondary--text"><v-icon>icons8-export-csv</v-icon><span class="ml-2">Export</span></AppButton>
							</div>
						</DropdownMenu>
					</div>
				</div>
				<div class="text--secondary pt-4" v-if="showInfo">The following page shows Test Results which have been imported into the system.  You can search or use the filter to locate results.</div>
			</v-col>
			<!-- Table -->
			<v-col cols="12" class="pt-2">
				<!-- Data Table -->
				<DataTable :tableheaders="filteredHeaders" :loading="loading" custom="testresult" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteResult" v-on:edit="editResult" v-on:restore="restoreResult" v-on:tableoptions="updateTableOptions"/>
			</v-col>
			<!-- Filter Drawer -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
				<v-row>
					<v-col cols="12">
						<div class="d-flex align-center pt-4">
							<div class="text-h5 secondary--text">Filters</div>
							<v-spacer />
							<v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the filters below to narrow your search.
					</v-col>
					<v-col cols="12">
						<!-- Lab  -->
						<div class="flex-grow-1 mb-2">
							<DropdownAdvanced backgroundcolor="white" label="Filter by Lab" :items="labs" itemtext="name" itemvalue="entityId" v-model="filter.labOrgId" :dense="true" />
						</div>
						<!-- Result Type -->
						<div class="flex-grow-1 my-2">
							<DropdownSimple backgroundcolor="white" label="Filter by Type" :items="animalTypes" v-model="filter.animalType" :dense="true" />
						</div>
						<!-- Sample Type -->
						<div class="flex-grow-1 my-2">
							<DropdownSimple backgroundcolor="white" label="Filter by Sample" :items="sampleTypes" v-model="filter.sampleType" :dense="true" />
						</div>
						<!-- Disease Type -->
						<div class="flex-grow-1 my-2">
							<DropdownSimple backgroundcolor="white" label="Filter by Disease" :items="diseaseTypes" v-model="filter.diseaseType" :dense="true" />
						</div>
						<!-- Test Type -->
						<div class="flex-grow-1 my-2">
							<DropdownSimple backgroundcolor="white" label="Filter by Test" :items="testTypes" v-model="filter.testType" :dense="true" />
						</div>
					</v-col>
					<v-col cols="12">
						<div class="mt-2 text-h6 primary--text">Dates</div>
						<!-- Sample Date Start -->
						<div class="flex-grow-1 my-2">
							<DatePicker backgroundcolor="white" label="Sample Start Date" v-model="filter.sampleDateStart" :dense="true" />
						</div>
						<!-- Sample Date End -->
						<div class="flex-grow-1 my-2">
							<DatePicker backgroundcolor="white" label="Sample End Date" v-model="filter.sampleDateEnd" :dense="true" />
						</div>
						<!-- Processed Date Start -->
						<div class="flex-grow-1 my-2">
							<DatePicker backgroundcolor="white" label="Processed Start Date" v-model="filter.processedDateStart" :dense="true" />
						</div>
						<!-- Processed Date End -->
						<div class="flex-grow-1 my-2">
							<DatePicker backgroundcolor="white" label="Processed End Date" v-model="filter.processedDateEnd" :dense="true" />
						</div>
					</v-col>
					<v-col cols="12">
						<div class="mt-2 text-h6 primary--text">Result</div>
						<v-btn-toggle v-model="filter.status" multiple class="rounded-of">
							<v-btn title="Positive" value="Positive"><v-icon class="error--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Positive</span></v-btn>
							<v-btn title="Negative" value="Negative"><v-icon class="success--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Negative</span></v-btn>
							<!-- <v-btn title="Unknown" value="Unknown"><v-icon class="grey--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Unknown</span></v-btn> -->
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Edit Window -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
				<!-- Result Details -->
				<v-row dense class="mt-5">
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<div class="text-h5 secondary--text">{{ action }} Result</div>
							<v-spacer />
							<v-btn icon depressed @click="closeResult"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the form below to {{ action }} the Results's details
					</v-col>
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Result Details</div> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Tube ID *" v-model="testresult.tubeId" :validate="validate.entityId" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Amimal ID *" v-model="testresult.animalId" :validate="validate.entityId" /> </v-col>
					<v-col cols="12"> <DropdownSimple backgroundcolor="white" label="Type" v-model="testresult.animalType" :items="animalTypes" :validate="validate.type" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="CPH Number *" v-model="testresult.cphNumber" :validate="validate.cphNumber" mask="##/###/####" placeholder="##/###/####" /> </v-col>
					<v-col cols="6"> <DatePicker backgroundcolor="white" label="Sample Date" v-model="testresult.sampleDate" /> </v-col>
					<v-col cols="6"> <DatePicker backgroundcolor="white" label="Processed Date" v-model="testresult.processedDate" /> </v-col>
					<v-col cols="6"> <DropdownSimple backgroundcolor="white" label="Sample Type" v-model="testresult.sampleType" :items="sampleTypes" :validate="validate.type" /> </v-col>
					<v-col cols="6"> <DropdownSimple backgroundcolor="white" label="Disease Type" v-model="testresult.diseaseType" :items="diseaseTypes" :validate="validate.type" /> </v-col>
					<v-col cols="12"> <DropdownSimple backgroundcolor="white" label="Test Type" v-model="testresult.testType" :items="testTypes" :validate="validate.type" /> </v-col>
					<v-col cols="12"> <CheckboxField backgroundcolor="white" v-model="testresult.pooled">Pooled</CheckboxField></v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Lab" v-model="testresult.labOrgId" :items="labs" itemtext="name" itemvalue="entityId" /> </v-col>

					<v-col cols="12" class="d-flex align-center" v-if="testresult.resultText !== ''">
							<span>Result:</span>
							<span class="font-weight-bold error--text" v-if="testresult.resultText === 'Positive'">{{ testresult.resultText }}</span>
							<span class="font-weight-bold success--text" v-if="testresult.resultText === 'Negative'">{{ testresult.resultText }}</span>
						<v-spacer></v-spacer>
						<!-- <div><RadioField :disabled="!allowResultChange && action !== 'Add'" backgroundcolor="white" label="Result" v-model="testresult.resultText" :options="['Positive', 'Negative']" /></div> -->
						<div class="flex-grow-1 text-right"><AppButton :disabled="action === 'Add'" buttonclass="grey lighten-4 black--text" @click.native="resultConfirmBox = true">Change</AppButton></div>
					</v-col>
					<!-- <v-col cols="12">
						<pre>{{ testresult }}</pre>
					</v-col> -->
				</v-row>
				<!-- Action Buttons -->
				<v-row dense class="mt-5">
					<v-col cols="6">
						<div><AppButton block buttonclass="grey" @click.native="closeResult">Cancel</AppButton></div>
					</v-col>
					<v-col cols="6">
						<div><AppButton block buttonclass="secondary" @click.native="saveResult">Save</AppButton></div>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Confirmation Box -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Confirm</div>
						<v-spacer />
						<v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirmed you want to delete this Result</p>
				<strong>{{ testresult.entityId }}</strong
				><br />
				<CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField>
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
							<AppButton buttonclass="warning" @click.native="confirmDeleteResult">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
			<!-- Change Result Confirm Box -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="resultConfirmBox" v-on:close="resultConfirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Change Test Result</div>
						<v-spacer />
						<v-btn icon depressed @click="resultConfirmBox = false"><v-icon class="white--text">icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirm you want to change the test Result from</p>

				<span class="text-h7" v-if="testresult.resultText === 'Positive'"><strong class="error--text">Positive</strong> to <strong class="success--text">Negative</strong></span>
				<span class="text-h7" v-if="testresult.resultText === 'Negative'"><strong class="success--text">Negative</strong> to <strong class="error--text">Positive</strong></span>

				<br />
				<CheckboxField v-model="confirmResultChange" class="mt-5">Are you sure?</CheckboxField>
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="resultConfirmBox = false">Cancel</AppButton>
							<AppButton :disabled="!confirmResultChange" buttonclass="primary" @click.native="changeTestResult()">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
			<ImportBox headerclass="primary" footerclass="grey lighten-2" :value="importBox">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Import Results</div>
						<v-spacer />
						<v-btn icon depressed @click="importBox = false"><v-icon class="white--text">icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please select the Lab CSV using the input box below</p>
				<v-row>
					<v-col cols="6">
						<DropdownAdvanced :disabled="progressData.count > 0" label="Select Lab" v-model="lab" :items="labs" itemtext="name" itemvalue="entityId" />
						<RadioField :disabled="progressData.count > 0" backgroundcolor="white" label="Animal Type" v-model="animalType" :options="['Cattle']" />
					</v-col>
					<v-col cols="6">
						<BrowseFile :disabled="progressData.count > 0" v-if="lab !== null" v-model="fileInput" class="my-2" />
					</v-col>
				</v-row>
				<div class="d-flex align-center mt-5" v-if="checkingFile">
					<div><v-progress-circular indeterminate color="primary"></v-progress-circular></div>
					<div class="animate__animated animate__flash animate__infinite animate__slow primary--text">Checking File</div>
				</div>
				<div v-if="inputMessage !== 'success' && inputMessage !== 'empty'" class="error--text mt-4 text-body-2">
					<span class="font-weight-bold">File contains errors:</span><br />
					<span v-html="inputMessage"></span> <br />You must correct this before continuing.
				</div>
				<div v-if="inputMessage === 'success'" class="success--text text-body-2 font-weight-bold mt-4">File is <strong>Good</strong> and ready to be imported. {{ inputData?.length }} rows to be imported.</div>
				<!-- PROGRESS INDICATOR -->
				<v-progress-linear v-model="progress" height="25" class="mt-5" v-if="inputMessage === 'success'">
					<strong>{{ Math.ceil(progress) }}%</strong>
				</v-progress-linear>
				<table width="100%" style="border: 1px solid lightgrey" class="mt-5" v-if="progressData.count > 0">
					<tr>
						<th width="80" style="border-bottom: 1px solid lightgrey">Total</th>
						<th width="80" style="border-bottom: 1px solid lightgrey">Count</th>
						<th width="80" style="border-bottom: 1px solid lightgrey" class="success--text">Success</th>
						<th width="80" style="border-bottom: 1px solid lightgrey" class="error--text">Errors</th>
						<th style="border-bottom: 1px solid lightgrey">Status</th>
						<th style="border-bottom: 1px solid lightgrey">Report</th>
					</tr>
					<tr>
						<td align="center">{{ progressData?.totalItems }}</td>
						<td align="center">{{ progressData?.count }}</td>
						<td align="center" class="success--text">{{ progressData?.success }}</td>
						<td align="center" class="error--text">{{ progressData?.error }}</td>
						<td align="center">{{ progressData?.status }}</td>
						<td align="center"><v-btn depressed small :disabled="progressData?.status !== 'Completed'" buttonclass="primary" @click.native="downloadTextFile">Download</v-btn></td>
					</tr>
				</table>
				<!-- <pre>{{ progressData }}</pre> -->
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end" v-if="progressStatus === 'Not Started' || progressStatus === 'In Progress'">
							<AppButton buttonclass="grey" @click.native="importBox = false" :disabled="inputMessage !== 'success' || progressStatus === 'In Progress'">Cancel</AppButton>
							<AppButton buttonclass="primary" @click.native="importResults" :disabled="inputMessage !== 'success' || progressStatus === 'In Progress'">Import</AppButton>
						</v-col>
						<v-col cols="12" class="d-flex justify-end" v-else>
							<AppButton buttonclass="primary" @click.native="finishImport">Finish</AppButton>
						</v-col>
					</v-row>
				</template>
			</ImportBox>
			<ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Export CSV</div>
						<v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
						<v-spacer />
						<v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please select the data you wish to export:</p>
				<table width="100%" cellspacing="5">
					<tr>
						<td width="100"><strong>Current page:</strong></td>
						<td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td><strong>Current total:</strong></td>
						<td>{{ itemsTotal }} items (filtered)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td><strong>All Items:</strong></td>
						<td>{{ totalCount }} items (without filter)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td valign="top"><strong>Specific:</strong></td>
						<td>
							<div class="d-flex align-center">
								<div>From</div>
								<div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
								<div>To</div>
								<div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
							</div>
							<div class="ml-n7">
								<CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
							</div>
						</td>
						<td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
					</tr>
				</table>
			</ExportBox>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import mappings from "@/labfieldmap.js";
import DropdownAdvanced from "@/components/form/DropdownAdvanced.vue";
export default {
	name: "ResultsAdmin",
	// components: { FarmCard },
	// data
	data: () => ({
		loading: false,
		progress: 0,
		progressData: {
			id: "",
			totalItems: 0,
			count: 0,
			success: 0,
			error: 0,
			status: "Not Started",
			report: "",
		},
		progressStatus: "Not Started",
		drawer: false,
		filterDrawer: false,
		confirmBox: false,
		importBox: false,
		fileInput: null,
		checkingFile: false,
		checkProgressInterval: null,
		inputMessage: "empty",
		inputHeaders: [],
		inputData: [],
		permenantDelete: false,
		search: "",
		filter: {
			status: [],
			animalId: "",
			cphNumber: "",
			animalType: "",
			sampleType: "",
			diseaseType: "",
			testType: "",
			labOrgId: "",
			showDeleted: false,
			sampleDateStart: "",
			sampleDateEnd: "",
			processedDateStart: "",
			processedDateEnd: "",
		},
		tableData: [],
		itemsTotal: 0,
		totalCount: 0,
		tableHeaders: [
			{ text: "Tube Id", value: "tubeId", hidden: false, sortable: false, shrunk: true },
			{ text: "Animal ID", value: "animalId", hidden: false, sortable: false, shrunk: true },
			{ text: "Animal Type", value: "animalType", hidden: false, sortable: false, shrunk: true },
			{ text: "CPHNumber", value: "cphNumber", hidden: false, sortable: false, shrunk: true },
			{ text: "Sample Date", value: "sampleDate", hidden: false, sortable: false, shrunk: false },
			{ text: "Process Date", value: "processedDate", hidden: false, sortable: false, shrunk: true },
			{ text: "Sample Type", value: "sampleType", hidden: false, sortable: false, shrunk: true },
			{ text: "Disease Type", value: "diseaseType", hidden: false, sortable: false, shrunk: true },
			{ text: "Test Type", value: "testType", hidden: false, sortable: false, shrunk: true },
			{ text: "Pooled", value: "pooled", hidden: false, sortable: false, shrunk: true },
			{ text: "Result", value: "resultText", align: "center", hidden: false, sortable: false, shrunk: true },
			{ text: "Result Value", value: "resultValue", hidden: true, sortable: false, shrunk: true },
			{ text: "Lab", value: "labName", hidden: false, sortable: false, shrunk: true },
			{ text: "Lab ID", value: "labOrgId", hidden: true, sortable: false, shrunk: true },
			{ text: "Interpreted Result", value: "interpretedResult", hidden: true, sortable: false, shrunk: true },
			{ text: "Original Result", value: "originalResult", hidden: true, sortable: false, shrunk: true },
			{ text: "Result Changed", value: "resultChangedDate", hidden: true, sortable: false, shrunk: true },
			{ text: "Result Changed User Id", value: "resultChangeUserId", hidden: true, sortable: false, shrunk: true },
			{ text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedOrgId", value: "deletedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true },
		],
		tableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["tubeId"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},
		validate: {},
		testresult: {},
		animalStatus: [],
		orgStatus: [],
		action: "Add",
		// Export Box Data
		exportBox: false,
		exportItems: {
			itemsFrom: "1",
			itemsTo: "10",
		},
		exportLoading: false,
		exportFiltered: false,
		lab: null,
		labs: [],
		animalType: "Cattle",
		animalTypes: [],
		sampleTypes: [],
		diseaseTypes: [],
		testTypes: [],
		allowResultChange: false,
		resultConfirmBox: false,
		confirmResultChange: false,
		showInfo: false,
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
		filteredHeaders() {
			let headers = this.tableHeaders.filter((header) => !header.hidden);
			if (this.drawer || this.filterDrawer) {
				headers = headers.filter((header) => header.shrunk);
			}
			return headers;
		},
		filterQuery() {
			let query = "";
			// Search by Result ID
			if (this.filter.animalId !== "" && this.filter.animalId !== null && this.filter.animalId !== undefined && this.filter.animalId.length > 2) {
				query += `@animalId:${this.filter.animalId}*`;
			}
			// Search by CPH Number
			if (this.filter.cphNumber !== "" && this.filter.cphNumber !== null && this.filter.cphNumber !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				// remove special characters from cphNumber
				let cphNumber = this.filter.cphNumber.replace(/[^a-zA-Z0-9]/g, "");
				query += `@cphNumber:${cphNumber}`;
			}
			// Filter by Sample Type
			if (this.filter.sampleType !== "" && this.filter.sampleType !== null && this.filter.sampleType !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				query += `@sampleType:{${this.filter.sampleType}}`;
			}
			// Filter by Disease Type
			if (this.filter.diseaseType !== "" && this.filter.diseaseType !== null && this.filter.diseaseType !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				query += `@diseaseType:{${this.filter.diseaseType}}`;
			}
			// Filter by Disease Type
			if (this.filter.testType !== "" && this.filter.testType !== null && this.filter.testType !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				query += `@testType:{${this.filter.testType}}`;
			}
			// Filter by Animal Type
			if (this.filter.animalType !== "" && this.filter.animalType !== null && this.filter.animalType !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				query += `@animalType:{${this.filter.animalType}}`;
			}
			// Filter by labOrgId
			if (this.filter.labOrgId !== "" && this.filter.labOrgId !== null && this.filter.labOrgId !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				query += `@labOrgId:{${this.filter.labOrgId}}`;
			}
			// Filter by Result Status
			if (JSON.stringify(this.filter.status) !== "[]") {
				let status = this.filter.status.join(",");
				status = status.replace(/,/g, "|");
				query += ` @resultText:{${status}}`;
			}
			// Filter by sampleDateStart and sampleDateEnd
			if (this.filter.sampleDateStart !== "" && this.filter.sampleDateStart !== null && this.filter.sampleDateStart !== undefined && this.filter.sampleDateEnd !== "" && this.filter.sampleDateEnd !== null && this.filter.sampleDateEnd !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				let startDate = this.MIX_formatDateTime(this.filter.sampleDateStart, "YYYY-MM-DD", "unix");
				let endDate = this.MIX_formatDateTime(this.filter.sampleDateEnd, "YYYY-MM-DD", "unix");
				query += `@sampleDate:[${startDate} ${endDate}]`;
			}
			// Filter by processedDateStart and processedDateEnd
			if (this.filter.processedDateStart !== "" && this.filter.processedDateStart !== null && this.filter.processedDateStart !== undefined && this.filter.processedDateEnd !== "" && this.filter.processedDateEnd !== null && this.filter.processedDateEnd !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				let startDate = this.MIX_formatDateTime(this.filter.processedDateStart, "YYYY-MM-DD", "unix");
				let endDate = this.MIX_formatDateTime(this.filter.processedDateEnd, "YYYY-MM-DD", "unix");
				query += `@processedDate:[${startDate} ${endDate}]`;
			}
			// Show Deleted / Not Deleted
			if (this.filter.showDeleted) {
				query += ` @deleted:{true}`;
			} else {
				query += ` @deleted:{false}`;
			}
			query = query.trim();
			return query;
		},
		itemsFrom() {
			return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
		},
		itemsTo() {
			if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
				return this.itemsTotal;
			} else {
				return this.tableOptions.page * this.tableOptions.itemsPerPage;
			}
		},
		itemsCount() {
			return this.tableOptions.itemsPerPage;
		},
		itemsSortBy() {
			let sortBy = this.tableOptions.sortBy;
			if (sortBy.length > 0) {
				sortBy = sortBy.map((x) => x);
				sortBy = sortBy.join(",");
			}
			return sortBy;
		},
		itemsSortDesc() {
			let sortDesc = this.tableOptions.sortDesc[0];
			if (sortDesc) {
				sortDesc = "DESC";
			} else {
				sortDesc = "ASC";
			}
			return sortDesc;
		},
	},
	// watch
	watch: {
		filterQuery: {
			handler() {
				this.tableOptions.page = 1;
				this.getTableData();
			},
			deep: true,
		},
		tableOptions: {
			handler() {
				this.getTableData();
			},
			deep: true,
		},
		fileInput: {
			handler() {
				this.inputMessage = "empty";
				this.handleImport(this.fileInput);
			},
			deep: true,
		},
	},
	// methods
	methods: {
		// Get Total Record Count
		async getTotalCount() {
			try {
				let dataResult = await this.REDIS_countFor("testresult", "", "", "", "", "@deleted:{false}");
				// console.log(JSON.stringify(dataResult, null, 2))
				this.totalCount = dataResult.total;
			} catch (error) {
				console.error(error);
			}
		},
		// Get Table Data
		async getTableData() {
			try {
				this.loading = true;
				let dataResult = await this.REDIS_searchFor("testresult", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
				let tableData = dataResult.data.documents;
				// loop through tableData and lookup lab name
				for (let i = 0; i < tableData.length; i++) {
					tableData[i].labName = this.lookupLab(tableData[i].labId);
				}
				this.tableData = dataResult.data.documents;
				this.itemsTotal = dataResult.data.total;
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
		// Update Table Options
		updateTableOptions(options) {
			this.tableOptions = options;
		},
		// Add Result
		addResult() {
			this.testresult = { ...this.$schema.testresult };
			this.action = "Add";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Edit Result
		editResult(testresult) {
			this.testresult = { ...testresult };
			// add a mask ##/###/#### to the cphNumber
			// this.testresult.cphNumber = this.testresult.cphNumber.replace(/(\d{2})(\d{3})(\d{4})/, "$1/$2/$3");
			this.filterDrawer = false;
			this.action = "Edit";
			this.drawer = true;
		},
		// Delete Result
		deleteResult(testresult) {
			this.testresult = { ...testresult };
			this.action = "Delete";
			this.confirmBox = true;
		},
		// Close ORg
		closeResult(testresult) {
			this.testresult = { ...testresult };
			this.drawer = false;
		},
		// Restore Result
		async restoreResult(testresult) {
			try {
				this.loading = true;
				this.testresult = { ...testresult };
				this.testresult.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.testresult.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.testresult.deleted = false;
				this.testresult.deletedUserId = null;
				this.testresult.deletedOrgId = null;
				this.testresult.deletedDateTime = null;
				await this.REDIS_update("testresult", this.testresult.entityId, this.testresult);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Confirm Delete Result
		async confirmDeleteResult() {
			try {
				this.loading = true;
				this.testresult.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.testresult.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.testresult.deleted = true;
				this.testresult.deletedUserId = this.GET_currentUserProfile.entityId;
				this.testresult.deletedOrgId = this.GET_currentUserProfile.orgId;
				this.testresult.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
				await this.REDIS_update("testresult", this.testresult.entityId, this.testresult);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Save Result
		async saveResult() {
			try {
				this.loading = true;
				this.testresult.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.testresult.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.testresult.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
				// this.org.postcode remove spaces and make uppercase
				let validationErrors = null;
				validationErrors = 0;
				// validation for the testresult object
				//if (this.org.name === "" || this.org.name === undefined || this.org.name === null) { this.validate.name = "Name is required"; validationErrors++; }
				// loop through the this.org object and trim all the values
				Object.keys(this.testresult).forEach((key) => {
					if (typeof this.testresult[key] === "string") {
						this.testresult[key] = this.testresult[key].trim();
						// remove any . from the end of the string
						if (this.testresult[key].slice(-1) === ".") {
							this.testresult[key] = this.testresult[key].slice(0, -1);
						}
					}
				});
				// If no validation errors
				if (this.action === "Add" && validationErrors === 0) {
					this.testresult.entityId = this.testresult.tubeId + "_" + this.testresult.animalId;
					this.testresult.createdUserId = this.GET_currentUserProfile.entityId;
					this.testresult.createdOrgId = this.GET_currentUserProfile.orgId;
					this.testresult.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
					await this.REDIS_createWithId("testresult", this.testresult, this.testresult.entityId);
					this.loading = false;
					this.testresult = { ...this.$schema.testresult };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else if (this.action === "Edit" && validationErrors === 0) {
					await this.REDIS_update("testresult", this.testresult.entityId, this.testresult);
					this.loading = false;
					this.testresult = { ...this.$schema.testresult };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else {
					this.loading = false;
				}
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Initialise
		async initialise() {
			this.loading = true;
			this.mappings = mappings;
			let labResult = await this.REDIS_searchFor("org", "", "", "name", "asc", "@type:{Lab} @deleted:{false}");
			this.labs = labResult.data.documents;
			let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{animalType|sampleType|diseaseType|testType}");
			this.animalTypes = lookupResult.data.documents.filter((item) => item.type === "animalType");
			this.sampleTypes = lookupResult.data.documents.filter((item) => item.type === "sampleType");
			this.diseaseTypes = lookupResult.data.documents.filter((item) => item.type === "diseaseType");
			this.testTypes = lookupResult.data.documents.filter((item) => item.type === "testType");
			// this.animalStatus = lookupResult.data.documents.filter((item) => item.type === "animalStatus");
			// this.animalTypes = lookupResult.data.documents.filter((item) => item.type === "animalType");
			this.getTableData();
			this.loading = false;
		},
		// Handle Import
		async handleImport(file) {
			// console.log(file);
			this.checkingFile = true;
			let mappingResult = this.mappings.find((item) => item.labOrgId === this.lab);
			let mapping = mappingResult.fields;
			// console.log(JSON.stringify(mapping, null, 2));
			this.$papa.parse(file, {
				header: true,
				complete: (results) => {
					// loop through the resilts.meta.fields and check if they are in the schema
					let invalidFields = [];
					let invalidValues = [];
					results.meta.fields.forEach((field) => {
						if (!Object.prototype.hasOwnProperty.call(mapping, field)) {
							invalidFields.push(field);
						}
					});
					// let index = 2;
					results.data.forEach((row) => {
						// let testresult = {}
						// add deleted = false if not present
						if (row.deleted === undefined || row.deleted === null || row.deleted === "") {
							row.deleted = false;
						}
						// add lab id
						row.labOrgId = this.lab;
						// add animalType
						// console.log('animalType', this.animalType)
						row.animalType = this.animalType;
						// add auditing fields
						row.createdUserId = this.GET_currentUserProfile.entityId;
						row.createdOrgId = this.GET_currentUserProfile.orgId;
						row.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
						row.modifiedUserId = this.GET_currentUserProfile.entityId;
						row.modifiedOrgId = this.GET_currentUserProfile.orgId;
						row.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
						// remove any special characters from CPH Number
						//row.cphNumber = row.cphNumber.replace(/[^a-zA-Z0-9]/g, "");
						// index++;
					});
					if (invalidFields.length > 0) {
						this.inputMessage = `The following fields are invalid <strong>${invalidFields.join(", ")}</strong>. `;
					}
					if (invalidValues.length > 0) {
						if (this.inputMessage === "empty") {
							this.inputMessage = "";
						}
						this.inputMessage = this.inputMessage + ` The following rows have invalid or missing data <strong>${invalidValues.join(", ")}</strong>`;
					}
					if (invalidFields.length === 0 && invalidValues.length === 0) {
						this.inputMessage = "success";
					}
					// console.log(JSON.stringify(results.data, null, 2))
					this.inputHeaders = results.meta.fields;
					this.inputData = results.data;
				},
			});
			this.checkingFile = false;
		},
		// Import Results
		async importResults() {
			const progressId = this.MIX_formatDateTimeNow("valueOf");
			// console.log(JSON.stringify(this.inputData, null, 2))
			// console.log('this.inputData', JSON.stringify(this.inputData, null, 2))
			this.REDIS_bulkCreateUpdate("testresult", this.inputData, progressId);
			this.checkProgress(progressId);
		},
		// Check Progress of Import
		checkProgress(progressId) {
			let t = this;
			this.checkProgressInterval = setInterval(async () => {
				try {
					let progressResult = await t.REDIS_read("progress", progressId);
					if (progressResult.status !== "404") {
						t.progressData = progressResult.data;
						t.progressStatus = progressResult.data.status;
						t.progress = (progressResult.data.count / progressResult.data.totalItems) * 100;
						if (progressResult.data.status === "Completed") {
							clearInterval(t.checkProgressInterval);
						}
					} else if (progressResult.status === "404") {
						clearInterval(t.checkProgressInterval);
					}
				} catch (error) {
					console.error(error);
					clearInterval(t.checkProgressInterval);
				}
			}, 1000);
		},
		// Finish Import
		finishImport() {
			this.progress = 0;
			this.progressData = {
				id: "",
				status: "Not Started",
				totalItems: 0,
				count: 0,
				success: 0,
				errors: 0,
				report: "",
			};
			this.progressStatus = "Not Started";
			this.inputMessage = "empty";
			this.inputHeaders = [];
			this.inputData = [];
			this.importBox = false;
			this.getTableData();
		},
		// Export Box CSV
		async exportCSV(itemsFrom, itemsTo, filter, specific) {
			if (itemsFrom > itemsTo) {
				this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
			} else {
				this.exportLoading = true;
				var itemsCount, tableData, csv, query, exportFileName;
				switch (true) {
					// Current Page / Current Total (filtered)
					case filter && !specific:
						itemsCount = itemsTo - itemsFrom;
						query = this.filterQuery;
						break;
					// All (Not filtered)
					case !filter && !specific:
						itemsCount = this.totalCount;
						query = `@deleted:{${this.filter.showDeleted}}`;
						break;
					// Specific (filtered/ not filtered)
					case specific:
						itemsFrom = itemsFrom - 1;
						itemsCount = itemsTo - itemsFrom;
						if (this.exportFiltered) {
							query = this.filterQuery;
						} else {
							query = `@deleted:{${this.filter.showDeleted}}`;
						}
						break;
				}
				// console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
				let exportResult = await this.REDIS_searchFor("testresult", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
				tableData = JSON.parse(JSON.stringify(exportResult.data.documents));
				csv = this.$papa.unparse(tableData);
				if (itemsFrom === 0) {
					itemsFrom = 1; // This is just for filename purposes
				}
				if (itemsFrom >= 10) {
					itemsFrom = itemsFrom + 1;
				}
				switch (true) {
					case !specific && filter:
						exportFileName = `Results_Filtered_${itemsFrom}_to_${itemsTo}`;
						break;
					case !specific && !filter:
						exportFileName = `Results_${itemsFrom}_to_${itemsTo}`;
						break;
					case specific && this.exportFiltered:
						exportFileName = `Results_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
						break;
					case specific && !this.exportFiltered:
						exportFileName = `Results_Specific_${itemsFrom}_to_${itemsTo}`;
						break;
				}
				// console.log(exportFileName);
				// console.log(csv.length);
				this.$papa.download(csv, exportFileName);
				this.exportLoading = false;
			}
		},
		downloadTextFile() {
			// the code for creating a downloadable text file
			const dataVariable = this.progressData.report;
			const blob = new Blob([dataVariable], { type: "text/plain" });
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = "filename.txt";
			link.click();
			URL.revokeObjectURL(url);
		},
		lookupLab(labId) {
			var lab = '';
			if (JSON.stringify(this.labs) !== '[]' && labId !== '') {
				lab = this.labs.find((lab) => lab.id === labId);
				return lab.name;
			} else {
				return lab;
			}
		},
		changeTestResult() {
			switch (this.testresult.resultText) {
				case "Positive":
					(this.testresult.originalResult = this.testresult.resultText), (this.testresult.resultText = "Negative");
					(this.testresult.interpretedResult = "Negative"), (this.testresult.resultChangedDate = this.MIX_formatDateTimeNow("toISOString")), (this.testresult.resultChangeUserId = this.GET_currentUserProfile.entityId);
					break;
				case "Negative":
					(this.testresult.originalResult = this.testresult.resultText), (this.testresult.resultText = "Positive");
					(this.testresult.interpretedResult = "Positive"), (this.testresult.resultChangedDate = this.MIX_formatDateTimeNow("toISOString")), (this.testresult.resultChangeUserId = this.GET_currentUserProfile.entityId);
					break;
			}
			this.resultConfirmBox = false;
			this.allowResultChange = false;
		},
		toggleFilters() {
			if (this.drawer) {
				this.drawer = false;
			}
			this.filterDrawer = !this.filterDrawer;
		}
	},
	created() {
		this.getTotalCount();
		this.initialise();
	},
	components: { DropdownAdvanced },
};
</script>

<style scoped>
.d-flex {
	gap: 15px;
}
</style>

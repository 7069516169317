<template>
    <v-banner app class="error full-width fixed foreground-high elevation-0 pa-0 ma-0 bottom-border foreground-medium" height="80">
        <div class="d-flex align-center justify-center">
            <v-icon x-large class="white--text">icons8-error-cloud</v-icon>
            <div class="pl-3 text-h7 white--text"><strong>Unable to Connect to Cloud</strong><br /><span class="text-body-2">App will have Limited Functionality</span></div>
            <v-spacer />
            <v-spacer />
            <v-btn icon><v-icon class="white--text" @click="ACT_noCloud(false)">icons8-multiply</v-icon></v-btn>
        </div>
    </v-banner>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'NoCloud',
    methods: {
        ...mapActions({
            ACT_noCloud: 'ACT_noCloud'
        })
    }
}
</script>

// import { mapActions } from "vuex";

const mixin = {
	data: () => ({
		// API_URL: process.env.VUE_APP_MOFSERVER,
	}),
	methods: {
		async MIX_getVetsByOwner(ownerUserId) {
			return new Promise((resolve, reject) => {
				try {
					let query = `@deleted:{false} @type:{Vet} @ownerUserId:{${ownerUserId}}`;
					this.REDIS_searchFor("org", "", "", "", "", query).then((vetsResult) => {
						resolve({
							success: true,
							total: vetsResult.data.total,
							data: vetsResult.data.documents,
						});
					});
				} catch (error) {
					console.error(error);
					reject({
						success: false,
						error: error,
					});
				}
			});
		},
		async MIX_getVetHoldingsByVet(vets) {
			return new Promise((resolve, reject) => {
				try {
					let vetsIds = vets.map((vet) => vet.entityId);
					let query = `@deleted:{false} @vetOrgId:{${vetsIds.join("|")}}`;
					this.REDIS_searchFor("vetholding", "", "", "", "", query).then((vetholdingsResult) => {
						resolve({
							success: true,
							total: vetholdingsResult.data.total,
							data: vetholdingsResult.data.documents,
						});
					});
				} catch (error) {
					console.error(error);
					reject({
						success: false,
						error: error,
					});
				}
			});
		},
		async MIX_getHoldingsByVetHoldings(vetholdings) {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						let vetholdingIds = vetholdings.map((vetholding) => vetholding.holdingOrgId);
						let holdings = [];
						for (let i = 0; i < vetholdingIds.length; i++) {
							let holdingResult = await this.REDIS_read("org", vetholdingIds[i]);
							holdings.push(holdingResult.data);
						}
						resolve({
							success: true,
							data: holdings,
							total: holdings.length,
						});
					} catch (error) {
						console.error(error);
						reject({
							success: false,
							error: error,
						});
					}
				})();
			});
		},
		// Get Tests
		async MIX_getTestsByTestEvent(eventId) {
			return new Promise((resolve, reject) => {
				try {
					let query = `@deleted:{false} @eventId:{${eventId}}`;
					// console.log('query', query)
					this.REDIS_searchFor("testdetail", "", "", "", "", query).then((testdetailResult) => {
						resolve({
							success: true,
							total: testdetailResult.data.total,
							data: testdetailResult.data.documents,
						});
					});
				} catch (error) {
					console.error(error);
					reject({
						success: false,
						error: error,
					});
				}
			});
		},
		// Get Animals
		async MIX_getAnimalsByHolding(holdingOrgId) {
			return new Promise((resolve, reject) => {
				try {
					let query = `@deleted:{false} @holdingOrgId:{${holdingOrgId}}`;
					// console.log('query', query)
					this.REDIS_searchFor("animal", "", "", "", "", query).then((animalResult) => {
						resolve({
							success: true,
							total: animalResult.data.total,
							data: animalResult.data.documents,
						});
					});
				} catch (error) {
					console.error(error);
					reject({
						success: false,
						error: error,
					});
				}
			});
		},
		// Lookup Holding
		MIX_lookupHolding(entityId, holdings, field) {
			if (JSON.stringify(holdings) !== "[]") {
				let holding = holdings.find((holding) => holding.entityId === entityId);
				if (holding === undefined) {
					return "";
				} else {
					if (field === "") {
						return holding;
					} else {
						return holding[field];
					}
				}
			}
		},
		// Lookup Vet
		MIX_lookupVet(entityId, vets, field) {
			if (JSON.stringify(vets) !== "[]") {
				let vet = vets.find((vet) => vet.entityId === entityId);
				if (vet === undefined) {
					return "";
				} else {
					if (field === "") {
						return vet;
					} else {
						return vet[field];
					}
				}
			}
		},
		// Org Stats by Type
		MIX_getOrgStatsByType() {
			return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}stats/org/type/`;
                        let result = await this.$axios.get(url, this.apiOptions)
						resolve(result.data)
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
		},
		MIX_getOrgStatsByCertificate() {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						const url = `${this.apiUrl}stats/org/certificate/`;
						let result = await this.$axios.get(url, this.apiOptions)
						resolve(result.data)
					} catch (error) {
						// this.ACT_noCloud(true);
						reject(error);
					}
				})();
			});
		},
		MIX_getAnimalStatsByStatus() {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						const url = `${this.apiUrl}stats/animal/status/`;
						let result = await this.$axios.get(url, this.apiOptions)
						resolve(result.data)
					} catch (error) {
						// this.ACT_noCloud(true);
						reject(error);
					}
				})();
			}
		)},
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};

<template>
	<div>
		<v-banner app class="grey darken-1 full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
			<div class="d-flex align-center">
				<v-icon class="white--text pr-2" x-large>icons8-farm-2</v-icon>
				<div class="text-h6 white--text font-weight-bold">{{ display }}</div>
				<v-spacer />
				<div>
					<AppButton :text="true" v-if="display === 'My Holdings'" @click.native="display = 'Add Holding'" depressed class="secondary white--text"><v-icon>icons8-add</v-icon><span class="pl-2">Holding </span></AppButton>
					<AppButton :text="true" v-else @click.native="closeHolding()" depressed class="grey darken-2 white--text"><v-icon class="">icons8-back-to</v-icon><span class="pl-2">Holdings</span></AppButton>
				</div>
			</div>
		</v-banner>
		<div style="margin-top: 80px !important"><v-progress-linear aria-label="Progress Loader" :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
			<v-container v-if="display === 'My Holdings'" class="">
				<v-row class="dense">
					<v-col cols="12" xs="12" sm="4" class="">
						<FarmCard v-for="(holding, index) in holdings" :key="index" @click.native="viewHolding(holding.entityId)" class="pa-4 mb-4">
							<div class="d-flex align-center" dense>
								<div class="d-flex align-start justify-start flex-column">
									<div class="d-flex align-center text-h6 primary--text full-width">
										<div>
											{{ holding.name }}<br />
											<div class="text-body-2 font-weight-bold" :class="MIX_accountStatus(holding.status) + '--text'" v-if="holding.status !== 'Approved'">Holding is {{ holding.status }}</div>
										</div>
										<v-spacer />
									</div>
									<div class="text-body-2 text--secondary my-3">{{ MIX_formatAddress(holding) }}</div>
									<div class="d-flex align-center full-width mt-2">
										<div class="d-flex align-center">
											<v-icon class="primary--text">icons8-cow</v-icon>
											<div class="ml-1 mr-3 font-weight-bold text--secondary">{{ holding.cattleCount }}</div>
										</div>
										<v-chip small label class="white--text font-weight-bold mr-2" :class="MIX_certificateStatus(holding.certificateStatus)" v-if="holding.certificateStatus">{{ holding.certificateStatus }} Certified</v-chip>

										<v-spacer />
									</div>
								</div>
								<v-spacer />
								<div class="d-flex align-center justify-right">
									<v-btn @click="viewHolding(holding.entityId)" icon><v-icon large class="secondary--text">icons8-next-page</v-icon></v-btn>
								</div>
							</div>
						</FarmCard>
					</v-col>
				</v-row>
				<v-row class="dense" v-if="JSON.stringify(holdings) === '[]'">
					<v-col>
						<FarmCard class="pa-5 grey lighten-3 text--secondary">
							<strong>Information</strong><br />
							<i>There are currently no holdings associated with your account, please use the Add Holding button to link a holding.</i>
						</FarmCard>
					</v-col>
				</v-row>
			</v-container>
			<AddHolding v-if="display === 'Add Holding'" v-on:close="closeHolding()" />
			<ViewHolding v-if="display === 'View Holding'" :orgdata="activeHolding" v-on:close="closeHolding()" />
		</transition>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HomeView",
	// comopnents
	components: {
		AddHolding: () => import("@/views/farm/AddHolding.vue"),
		ViewHolding: () => import("@/views/farm/ViewHolding.vue"),
	},
	// data
	data: () => ({
		loading: false,
		search: "",
		display: "My Holdings",
		form: {
			name: "",
			email: "",
			password: "",
			testType: "",
			date: "",
			time: "",
		},
		activeHolding: {},
		totalHoldings: 0,
		holdings: [],
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
	},
	methods: {
		async initialise() {
			this.loading = true;
			await this.getHoldings();
			this.loading = false;
		},
		async getHoldings() {
			try {
				let query = `@deleted:{false} @type:{Holding} @ownerUserId:{${this.GET_currentUserProfile.entityId}}`;
				// console.log(query)
				let holdingsResult = await this.REDIS_searchFor("org", "", "", "name", "asc", query);
				for (var i = 0; i < holdingsResult.data.documents.length; i++) {
					let holding = holdingsResult.data.documents[i];
					// console.log(holding.entityId)
					let cattleCount = await this.REDIS_countFor("animal", "", "", "", "", `@deleted:{false} @type:{Cattle} @holdingOrgId:{${holding.entityId}}`);
					// console.log(cattleCount)
					holding.cattleCount = cattleCount.total;
				}
				this.holdings = holdingsResult.data.documents;
				this.totalHoldings = holdingsResult.data.total;
			} catch (error) {
				console.error("error getting holdings");
				console.error(error);
			}
		},
		addHolding() {
			this.display = "Add Holding";
		},
		async viewHolding(entityId) {
			try {
				this.loading = true;
				let holdingResult = await this.REDIS_read("org", entityId);
				this.activeHolding = holdingResult.data;
				let cattleCount = await this.REDIS_countFor("animal", "", "", "", "", `@deleted:{false} @type:{Cattle} @holdingOrgId:{${holdingResult.data.entityId}}`);
				this.activeHolding.cattleCount = cattleCount.total;
				this.display = "View Holding";
				this.loading = false;
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Loading Holding", color: "error", timeout: "4000" });
				console.error(error);
				this.loading = false;
			}
		},
		editHolding() {
			this.display = "EditHolding";
		},
		closeHolding() {
			this.display = "My Holdings";
			this.activeHolding = null;
			this.getHoldings();
		},
	},
	created() {
		this.initialise();
	},
};
</script>

<style>
.noboxshadow {
	box-shadow: none !important;
}
</style>

import { mapGetters } from "vuex";

const mixin = {
	data: () => ({
		MIX_nav: [
			// Project Nav (Admin)
			{
				title: "Home",
				path: "/admin/homeadmin",
				icon: "icons8-home-page",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Animals",
				path: "/admin/animals",
				icon: "icons8-cow",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Holdings",
				path: "/admin/holdings",
				icon: "icons8-farm-2",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Vets",
				path: "/admin/vets",
				icon: "icons8-rod-of-asclepius",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Tests",
				path: "/admin/testevents",
				icon: "icons8-syringe",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Results",
				path: "/admin/results",
				icon: "icons8-lab-items",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Users",
				path: "/admin/users",
				icon: "icons8-team",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav"],
			},
			{
				title: "Farm Lookup",
				path: "/search/",
				icon: "icons8-search",
				orgType: ["Project"],
				level: ["ProjectAdmin", "ProjectManager"],
				status: ["Approved", "Pending"],
				enabled: true,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},			
			// Holding Nav
			{
				title: "Farm Lookup",
				path: "/search/",
				icon: "icons8-search",
				orgType: ["Holding", "Vet"],
				level: ["GuestUser", "FarmUser", "FarmAdmin", "FarmManager", "VetManager", "VetAdmin", "VetUser"],
				status: ["Approved", "Pending"],
				enabled: true,
				nav: ["SideNav", "NavDrawer"],
			},
			{
				title: "My Holdings",
				path: "/myholdings/",
				icon: "icons8-farm-2",
				orgType: ["Holding"],
				level: ["GuestUser", "FarmUser", "FarmAdmin", "FarmManager"],
				status: ["Approved", "Pending"],
				enabled: true,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},
			{
				title: "My Animals",
				path: "/myanimals/",
				icon: "icons8-cow",
				orgType: ["Holding"],
				level: ["FarmUser", "FarmAdmin", "FarmManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},
			{
				title: "My Vets",
				path: "/myvet/",
				icon: "icons8-rod-of-asclepius",
				orgType: ["Holding"],
				level: ["FarmAdmin", "FarmManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},
			// {
			// 	title: "Certificates",
			// 	path: "/myanimals/",
			// 	icon: "icons8-diploma",
			// 	orgType: ["Holding"],
			// 	level: ["FarmManager"],
			// 	status: ["Approved"],
			// 	enabled: true,
			// 	nav: ["SideNav", "NavDrawer"],
			// },
			// Vet Nav
			{
				title: "Holdings",
				path: "/holdingslist/",
				icon: "icons8-farm-2",
				orgType: ["Vet"],
				level: ["VetUser", "VetAdmin", "VetManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},
			{
				title: "Testing",
				path: "/testevents/",
				icon: "icons8-syringe",
				orgType: ["Vet"],
				level: ["VetUser", "VetAdmin", "VetManager"],
				status: ["Approved"],
				enabled: true,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},
			{
				title: "My Practice",
				path: "/mypractice/",
				icon: "icons8-rod-of-asclepius",
				orgType: ["Vet"],
				level: ["VetManager"],
				status: ["Approved"],
				enabled: false,
				nav: ["SideNav", "NavDrawer", "BottomNav"],
			},
		],
	}),
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
			GET_userAuthenticated: "GET_userAuthenticated",
		}),
	},
	methods: {
		MIX_filteredNav(nav) {
			if (this.GET_userAuthenticated) {
				return this.MIX_nav.filter((item) => {
					return item.orgType.includes(this.GET_currentUserProfile.orgType) && item.nav.includes(nav);
				});
			} else {
				return [];
			}
		},
		// CHeck User Level and Status
		MIX_checkLevelAndStatus(item) {
			if (item.enabled) {
				if (this.GET_userAuthenticated) {
					if (item.status !== undefined && item.level !== undefined) {
						let checkError = 0;
						if (!item.status.includes(this.GET_currentUserProfile.status)) {
							checkError++;
						}
						if (!item.level.includes(this.GET_currentUserProfile.level)) {
							checkError++;
						}
						if (checkError === 0) {
							return false;
						} else {
							return true;
						}
					} else {
						return true;
					}
				}
			} else {
				return true;
			}
		},
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};

<template>
    <div>
        <v-container class="d-flex align-center justify-center">
            <v-row class="dense d-flex justify-center">
                <v-col cols="12" xs="12" sm="4" md="6" class="">
                    <v-row class="px-1 pt-2" dense>
                        <v-col cols="12">
                            <v-img src="@/assets/BVD.png" contain height="60px" />
                        </v-col>
                    </v-row>
                    <v-row class="px-1 pt-2" dense>
                        <v-window v-model="step">
                            <!-- Register Screen 1 -->
                            <v-window-item :value="1">
                                <!-- <v-form ref="step1" lazy-validation> -->
                                <v-row class="mt-5">
                                    <v-col cols="12" class="text-body-2 font-weight-bold px-4"> To register, please fill out the form below: </v-col>
                                    <v-col cols="12">
                                        <TextField label="First Name" v-model="user.firstName" :validate="errors.firstName" />
                                    </v-col>
                                    <v-col cols="12">
                                        <TextField label="Last Name" v-model="user.lastName" :validate="errors.lastName" />
                                    </v-col>
                                    <v-col cols="12">
                                        <TextField label="Email" v-model="user.email" :validate="errors.email" />
                                    </v-col>
                                    <v-col cols="12">
                                        <DropdownAdvanced label="Organisation Type" v-model="user.orgType" :items="orgTypes" :validate="errors.orgType" />
                                    </v-col>
                                    <v-col cols="12">
                                        <DropdownAdvanced label="Organisation Role" v-model="user.jobRole" :items="jobRoles" :filter="user.orgType" :disabled="user.orgType === ''" :validate="errors.jobRole" />
                                    </v-col>
                                </v-row>
                                <!-- </v-form> -->
                            </v-window-item>
                            <!-- Register Screen 2 -->
                            <v-window-item :value="2">
                                <v-row dense>
                                    <v-col cols="12" class="text-body-1">
                                        <v-expansion-panels  v-model="showPasswordRequirements" class="rounded-of elevation-0" flat accordion>
                                            <v-expansion-panel dense>
                                                <v-expansion-panel-header disable-icon-rotate class="grey lighten-2 font-weight-bold text-body-2 ">
                                                    Please enter a Password
                                                    <template v-slot:actions>
                                                        <v-icon color="primary">icons8-info</v-icon>
                                                    </template>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="grey lighten-2">
                                                    <ul class="text-body-2">
                                                        <li>Minimum length of 8 characters</li>
                                                        <li>At least one uppercase letter</li>
                                                        <li>At least one lowercase letter</li>
                                                        <li>At least one numerical digit</li>
                                                        <li>At least one special character</li>
                                                    </ul>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                    <v-col cols="12">
                                        <PasswordField label="Password" v-model="password" :validate="errors.password" />
                                    </v-col>
                                    <v-col cols="12">
                                        <PasswordField label="Password Confirmation" v-model="passwordConfirm" :validate="errors.passwordConfirm" />
                                    </v-col>
                                    <v-col cols="12">
                                    </v-col>
                                    <v-col cols="12">
                                        <CheckboxField v-model="user.termsAgreed">
                                            I agree and have read the <a @click="ACT_showTermsConditions(true)">Terms & Conditions</a>
                                        </CheckboxField>
                                        <CheckboxField v-model="user.optInAgreed">
                                            I agree to opt-in to communications
                                        </CheckboxField>
                                    </v-col>
                                </v-row>
                                <!-- <pre>{{ user }}</pre> -->
                            </v-window-item>
                        </v-window>
                        <div class="bottom grey rounded-of-top d-flex align-center">
                            <v-row dense class="d-flex justify-center" :class="$vuetify.breakpoint.xsOnly ? 'mx-2':''">
                                <v-col cols="6" xs="6" sm="2">
                                    <v-btn x-large @click="backStep(step)" large depressed block class="grey lighten-2 grey--text text--darken-2 rounded-of">Back</v-btn>
                                </v-col>
                                <v-col cols="6" xs="6" sm="2">
                                    <v-btn x-large @click="verifyStep(step)" large depressed block class="primary white--text rounded-of">Next</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { bannedPasswords } from "@/bannedPasswords.js";
import DropdownAdvanced from "@/components/form/DropdownAdvanced.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "RegisterView",
    components: { DropdownAdvanced },
    data: () => ({
        step: 1,
        showPasswordRequirements: -1,
        user: {},
        passwords: [],
        password: "",
        passwordConfirm: "",
        errors: {
            firstName: "",
            lastName: "",
            email: "",
            orgType: "",
            jobRole: "",
            password: "",
            passwordConfirm: "",
        },
        rules: {},
        addHolding: false,
        orgTypes: [],
        jobRoles: [],
    }),
    computed: {
        ...mapGetters({
            GET_currentUserAuth: "GET_currentUserAuth",
        }),
    },
    methods: {
        ...mapActions({
            ACT_showTermsConditions: 'ACT_showTermsConditions',
            ACT_currentUserProfile: "ACT_currentUserProfile",
        }),
        backStep(step) {
            if (step === 1) {
                this.MIX_go("/");
            } else {
                this.step--;
            }
        },
        checkPasswordComplexity(password) {
            if (password.length < 8) {
                return "Password must be at least 8 characters long.";
            } else if (!/[a-z]/.test(password)) {
                return "Password must contain at least one lowercase letter.";
            } else if (!/[A-Z]/.test(password)) {
                return "Password must contain at least one uppercase letter.";
            } else if (!/[0-9]/.test(password)) {
                return "Password must contain at least one numerical digit.";
            } else if (!/[!@#$%^&*()]/.test(password)) {
                return "Password must contain at least one special character.";
            } else {
                return "Password is valid.";
            }
        },
        async verifyStep(step) {
            // Validate Steps
            if (step === 1) {
                // Validate Step 1
                let errorCount = 0;
                if (this.user.firstName === "") {
                    this.errors.firstName = "First Name is required";
                    errorCount++;
                } else {
                    this.errors.firstName = "";
                }
                if (this.user.lastName === "") {
                    this.errors.lastName = "Last Name is required";
                    errorCount++;
                } else {
                    this.errors.lastName = "";
                }
                if (this.user.email === "") {
                    this.errors.email = "Email is required";
                    errorCount++;
                } else {
                    this.errors.email = "";
                }
                if (this.user.orgType === "") {
                    this.errors.orgType = "Organisation Type is required";
                    errorCount++;
                } else {
                    this.errors.orgType = "";
                }
                if (this.user.jobRole === "") {
                    this.errors.jobRole = "Organisation Role is required";
                    errorCount++;
                } else {
                    this.errors.jobRole = "";
                }
                if (errorCount === 0) {
                    this.step++;
                }
            } else if (step === 2) {
                // Validate Step 2
                let errorCount = 0;
                switch (true) {
                    // Missing Password
                    case this.password === "":
                        this.errors.password = "Password is required";
                        errorCount++;
                        break;
                    // Missing Password Confirmation
                    case this.passwordConfirm === "":
                        this.errors.passwordConfirm = "Password Confirmation is required";
                        errorCount++;
                        break;
                    // Passwords do not match
                    case this.password !== this.passwordConfirm:
                        this.errors.password = "Passwords do not match";
                        this.errors.passwordConfirm = "Passwords do not match";
                        errorCount++;
                        break;
                    // Password is not in banned list
                    case bannedPasswords.includes(this.password):
                        this.errors.password = "Password is too common";
                        this.errors.passwordConfirm = "Password is too common";
                        errorCount++;
                        break;
                    // Password meets complexity requirements
                    case this.checkPasswordComplexity(this.password) !== "Password is valid.":
                        this.showPasswordRequirements = 0;
                        this.errors.password = this.checkPasswordComplexity(this.password);
                        errorCount++;
                        break;
                    // Defaults
                    default:
                        this.errors.password = "";
                        this.errors.passwordConfirm = "";
                        break;
                }
                if (errorCount === 0) {
                    // TODO - RG - Add Firebase and Redis Registration
                    const fullName = this.user.firstName + " " + this.user.lastName;
                    let firebaseAuthResult = await this.FIREBASE_register(fullName, this.user.email, this.password);
                    // console.log('firebaseAuthResult = ', firebaseAuthResult)
                    if (firebaseAuthResult.success) {
                        // console.log('this.GET_currentUserAuth = ', this.GET_currentUserAuth)
                        this.user.createdUserId = this.GET_currentUserAuth.uid;
                        this.user.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                        this.user.modifiedUserId = this.GET_currentUserAuth.uid;
                        this.user.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
                        // console.log("user = ", JSON.stringify(this.user, null, 2));
                        let createNewUserResult = await this.REDIS_createNewUser(this.user, this.GET_currentUserAuth.uid);
                        // console.log(createNewUserResult);
                        if (createNewUserResult.success) {
                            this.ACT_currentUserProfile(this.user)
                            this.user = { ...this.$schema.user }
                            this.MIX_go("/home");
                        }
                    } else {
                        if (firebaseAuthResult.error.toString().search("auth/email-already-in-use") !== -1) {
                            console.error('Email Already in Use')
                        } else {
                            console.error('Unknown Authentication Error')
                        }
                    }
                }
            }
        },
    },
    async created() {
        this.user = this.$schema.user;
        // Lookups
        // TODO - Replace with unauthenticated lookup as lookup is public
        let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{orgType|jobRole}");
        this.orgTypes = lookupResult.data.documents.filter((item) => item.type === "orgType");
        this.jobRoles = lookupResult.data.documents.filter((item) => item.type === "jobRole");
        this.passwords = bannedPasswords;
    },
};
</script>

<style scoped>
.bottom {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100% !important;
    position: fixed !important;
    height: 100px !important;
}
</style>

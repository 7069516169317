<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="px-5 pt-5">
			<v-col cols="12">
				<div class="d-flex align-center">
					<div>
						<div class="text-h4 primary--text d-flex">
							{{ orgType }}s
							<v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
						</div>
					</div>
					<v-spacer />
					<!-- Search -->
					<SearchField label="Search for Vets" v-model="search" :disabled="loading" :dense="true" />
					<AppButton @click.native="getTableData"><v-icon>icons8-refresh</v-icon></AppButton>
					<AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
					<AppButton :disabled="drawer" buttonclass="secondary" @click.native="addOrg">Add {{ orgType }}</AppButton>
					<div class="white--text">
						<DropdownMenu>
							<div class="d-flex align-center">
								<v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
								<div class="font-weight-bold secondary--text">Show Deleted</div>
							</div>
						</DropdownMenu>
					</div>
				</div>
				<div class="text--secondary pt-4" v-if="showInfo">The following page shows Test Results which have been imported into the system. You can search or use the filter to locate results.</div>
			</v-col>
			<!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
			<!-- Table -->
			<v-col cols="12" class="pt-2">
				<!-- Data Table -->
				<DataTable :tableheaders="filteredHeaders" :loading="loading" :custom="orgType" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteOrg" v-on:edit="editOrg" v-on:restore="restoreOrg" v-on:tableoptions="updateTableOptions" />
			</v-col>
			<!-- Filter Drawer -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
				<v-row>
					<v-col cols="12">
						<div class="d-flex align-center pt-4">
							<div class="text-h5 secondary--text">Filters</div>
							<v-spacer />
							<v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the filters below to narrow your search.
					</v-col>
					<v-col cols="12">
						<!-- Status -->
						<div class="flex-grow-1">
							<div class="my-2 text-h6 primary--text">Vet Status</div>
							<!-- Status -->
							<v-btn-toggle v-model="filter.status" multiple style="flex-direction: column !important; width: 100% !important">
								<v-btn title="Approved" value="Approved"><v-icon class="success--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn>
								<v-btn title="Pending" value="Pending"><v-icon class="warning--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Pending</span></v-btn>
								<v-btn title="Rejected" value="Rejected"><v-icon class="error--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Rejected</span></v-btn>
								<v-btn title="Suspended" value="Suspended"><v-icon class="info--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Suspended</span></v-btn>
								<v-btn title="Archived" value="Archived"><v-icon class="grey--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Archived</span></v-btn>
							</v-btn-toggle>
						</div>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Edit Window -->
			<v-navigation-drawer width="25%" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
				<!-- Practice Details -->
				<v-row dense class="mt-5">
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<div class="text-h5 secondary--text">{{ action }} {{ orgType }}</div>
							<v-spacer />
							<v-btn icon depressed @click="closeOrg"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the form below to edit the Vet's details
					</v-col>
					<v-col cols="12" class="d-flex align-center" v-if="action === 'Edit'">
						<div v-if="org.status === 'Pending'" class="flex-grow-1"><AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Approve</AppButton></div>
						<div v-if="org.status === 'Pending'" class="flex-grow-1"><AppButton block buttonclass="error" @click.native="updateStatus('Rejected')">Reject</AppButton></div>
						<div v-if="org.status === 'Approved'" class="flex-grow-1"><AppButton block buttonclass="info" @click.native="updateStatus('Suspended')">Suspend</AppButton></div>
						<div v-if="org.status === 'Approved'" class="flex-grow-1"><AppButton block buttonclass="grey white--text" @click.native="updateStatus('Archived')">Archive</AppButton></div>
						<div v-if="org.status === 'Suspended' || org.status === 'Archived'" class="flex-grow-1"><AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Restore</AppButton></div>
					</v-col>					
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Practice Details</div> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Organisation Name *" v-model="org.name" :validate="validate.name" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="CPH Number *" v-model="org.cphNumber" :validate="validate.cphNumber" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Address Line 1 *" v-model="org.addressLine1" :validate="validate.addressLine1" donotautocomplete /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Address Line 2" v-model="org.addressLine2" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Address Line 3" v-model="org.addressLine3" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Town *" v-model="org.town" :validate="validate.town" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="County *" v-model="org.county" :validate="validate.county" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Postcode *" v-model="org.postcode" :validate="validate.postcode" /> </v-col>
				</v-row>
				<!-- Contact Details -->
				<v-row dense class="mt-5">
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Contact Details</div> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="First Name *" v-model="org.contactFirstName" :validate="validate.contactFirstName" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Last Name *" v-model="org.contactLastName" :validate="validate.contactLastName" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Telephone *" v-model="org.telephone" :validate="validate.telephone" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Email *" v-model="org.email" :validate="validate.email" /> </v-col>
				</v-row>
				<!-- Org Status -->
				<v-row dense class="mt-5">
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Status</div> </v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Status" v-model="org.status" :items="orgStatus" :validate="validate.status" /> </v-col>
				</v-row>
				<!-- Action Buttons -->
				<v-row dense class="mt-5">
					<v-col cols="6">
						<div><AppButton block buttonclass="grey" @click.native="closeOrg">Cancel</AppButton></div>
					</v-col>
					<v-col cols="6">
						<div><AppButton block buttonclass="secondary" @click.native="saveOrg">Save</AppButton></div>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Confirmation Box -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Confirm</div>
						<v-spacer />
						<v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirmed you want to delete this Vet</p>
				<strong>{{ org.name }}</strong
				><br />
				<!-- permenantDelete : {{ permenantDelete }}<br /> -->
				<CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField>
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
							<AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
		</v-row>
	</div>
</template>

<script>
// import FarmCard from "@/components/cards/FarmCard.vue";
import { mapGetters } from "vuex";
export default {
	name: "VetsAdmin",
	// components: { FarmCard },
	// data
	data: () => ({
		loading: false,
		drawer: false,
		filterDrawer: false,
		confirmBox: false,
		permenantDelete: false,
		search: "",
		filter: {
			status: ["Approved", "Pending"],
			certificateStatus: [],
			showDeleted: false,
		},
		items: [{ title: "Click Me" }, { title: "Click Me" }, { title: "Click Me" }, { title: "Click Me 2" }],
		tableData: [],
		itemsTotal: 0,
		tableHeaders: [
			{ text: "Status", value: "status", align: "center", hidden: false, sortable: false, shrunk: true },
			{ text: "Name", value: "name", align: "start", hidden: false, sortable: true, shrunk: true },
			{ text: "First Name", value: "contactFirstName", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Last Name", value: "contactLastName", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Job Role", value: "contactJobRole", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Telephone", value: "telephone", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Email", value: "email", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Website", value: "website", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Address Line 1", value: "addressLine1", align: "start", hidden: false, sortable: false, shrunk: false },
			{ text: "Address Line 2", value: "addressLine2", align: "start", hidden: false, sortable: false, shrunk: false },
			{ text: "Address Line 3", value: "addressLine3", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Town", value: "town", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "County", value: "county", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Postcode", value: "postcode", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Latitude", value: "latitude", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Longitude", value: "longitude", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Type", value: "type", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "ownerUserId", value: "ownerUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedOrgId", value: "deletedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true },
		],
		tableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["name"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},
		validate: {},
		org: {},
		orgType: "Vet",
		orgStatus: [],
		action: "Add",
		showInfo: false,
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
		filteredHeaders() {
			let headers = this.tableHeaders.filter((header) => !header.hidden);
			if (this.drawer || this.filterDrawer) {
				headers = headers.filter((header) => header.shrunk);
			}
			return headers;
		},
		filterQuery() {
			let query = "";
			if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
				query += `${this.search}*`;
			}
			if (this.filter.showDeleted) {
				query += ` @deleted:{true}`;
			} else {
				query += `@deleted:{false}`;
			}
			// Set Org Type
			query += ` @type:{Vet}`;
			// Filter Status
			if (JSON.stringify(this.filter.status) !== "[]") {
				query += ` @status:{${this.filter.status.join("|")}}`;
			}
			// Filter Certificate Status
			if (JSON.stringify(this.filter.certificateStatus) !== "[]") {
				query += ` @certificateStatus:{${this.filter.certificateStatus.join("|")}}`;
			}
			return query;
		},
	},
	// watch
	watch: {
		filterQuery: {
			handler() {
				this.getTableData();
			},
			deep: true,
		},
		tableOptions: {
			handler() {
				this.getTableData();
			},
			deep: true,
		},
	},
	// methods
	methods: {
		// Generate Fake Orgs
		async generateOrgs() {
			try {
				this.loading = true;
				let generateOrgResult = await this.$axios.get("https://api.mockaroo.com/api/4f496560?count=100&key=3a2d3c20");
				this.ACT_noCloud(false);
				let generatedOrgResultLength = generateOrgResult.data.length;
				let orgs = [];
				for (let i = 0; i < generatedOrgResultLength; i++) {
					let org = generateOrgResult.data[i];
					org.createdUserId = this.GET_currentUserProfile.entityId;
					(org.createdOrgId = ""), (org.createdDateTime = this.MIX_formatDateTimeNow("toISOString"));
					org.modifiedUserId = this.GET_currentUserProfile.entityId;
					(org.modifedOrgId = ""), (org.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString"));
					(org.ownerUserId = ""), (org.deletedUserId = ""), (org.deletedOrgId = ""), (org.deletedDateTime = ""), (org.deleted = false);
					orgs.push(org);
				}
				await this.$axios.post("/api/orgs", orgs);
				this.ACT_noCloud(false);
				this.getTableData();
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.ACT_noCloud(true);
				this.$store.dispatch("ACT_error", error);
			}
		},
		// Get Table Data
		async getTableData() {
			try {
				let dataResult = await this.REDIS_searchFor("org", "", "", "", "", this.filterQuery);
				// console.log('dataResult', JSON.stringify(dataResult, null, 2))
				this.tableData = dataResult.data.documents;
				this.itemsTotal = dataResult.data.total;
			} catch (error) {
				console.error(error);
			}
		},
		// Add Org
		addOrg() {
			this.org = { ...this.$schema.org };
			this.action = "Add";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Edit Org
		editOrg(org) {
			this.org = { ...org };
			this.action = "Edit";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Delete Org
		deleteOrg(org) {
			this.org = { ...org };
			this.action = "Delete";
			this.confirmBox = true;
		},
		// Close ORg
		closeOrg(org) {
			this.org = { ...org };
			this.drawer = false;
		},
		// Restore Org
		async restoreOrg(org) {
			try {
				this.loading = true;
				this.org = { ...org };
				this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.org.deleted = false;
				this.org.deletedUserId = null;
				this.org.deletedOrgId = null;
				this.org.deletedDateTime = null;
				await this.REDIS_update("org", this.org.entityId, this.org);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Confirm Delete Org
		async confirmDeleteOrg() {
			try {
				this.loading = true;
				this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.org.deleted = true;
				this.org.deletedUserId = this.GET_currentUserProfile.entityId;
				this.org.deletedOrgId = this.GET_currentUserProfile.orgId;
				this.org.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
				await this.REDIS_update("org", this.org.entityId, this.org);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Save Org
		async saveOrg() {
			try {
				this.loading = true;
				this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
				// this.org.postcode remove spaces and make uppercase
				this.org.type = this.orgType;
				if (this.orgType !== "Farm") {
					delete this.org.certificateStatus;
					delete this.org.cphNumber;
				}
				let validationErrors = null;
				validationErrors = 0;
				if (this.org.name === "") {
					this.validate.name = "Name is required";
					validationErrors++;
				}
				if (this.org.addressLine1 === "") {
					this.validate.addressLine1 = "Address Line 1 is required";
					validationErrors++;
				}
				if (this.org.town === "") {
					this.validate.town = "Town is required";
					validationErrors++;
				}
				if (this.org.county === "") {
					this.validate.county = "County is required";
					validationErrors++;
				}
				if (this.org.postcode === "") {
					this.validate.postcode = "Postcode is required";
					validationErrors++;
				}
				if (this.org.contactFirstName === "") {
					this.validate.contactFirstName = "First Name is required";
					validationErrors++;
				}
				if (this.org.contactLastName === "") {
					this.validate.contactLastName = "Last Name is required";
					validationErrors++;
				}
				if (this.org.email === "") {
					this.validate.email = "Email is required";
					validationErrors++;
				}
				if (this.org.telephone === "") {
					this.validate.telephone = "Telephone is required";
					validationErrors++;
				}
				if (this.org.status === "") {
					this.validate.status = "Status is required";
					validationErrors++;
				}
				if (this.org.postoicde !== null) {
					this.org.postcode = this.org.postcode.replace(/\s/g, "").toUpperCase();
				}
				// loop through the this.org object and trim all the values
				Object.keys(this.org).forEach((key) => {
					if (typeof this.org[key] === "string") {
						this.org[key] = this.org[key].trim();
						// remove any . from the end of the string
						if (this.org[key].slice(-1) === ".") {
							this.org[key] = this.org[key].slice(0, -1);
						}
					}
				});
				// If no validation errors
				if (this.action === "Add" && validationErrors === 0) {
					this.org.createdUserId = this.GET_currentUserProfile.entityId;
					this.org.createdOrgId = this.GET_currentUserProfile.orgId;
					this.org.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
					await this.REDIS_create("org", this.org);
					this.loading = false;
					this.org = { ...this.$schema.org };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else if (this.action === "Edit") {
					await this.REDIS_update("org", this.org.entityId, this.org);
					this.loading = false;
					this.org = { ...this.$schema.org };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else {
					this.loading = false;
				}
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Initialise
		async initialise() {
			this.loading = true;
			let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{orgStatus}");
			this.orgStatus = lookupResult.data.documents.filter((item) => item.type === "orgStatus");
			this.getTableData();
			this.loading = false;
		},
		// Update Table Options
		updateTableOptions(options) {
			this.tableOptions = options;
		},
		updateStatus(status) {
			this.org.status = status;
			this.saveOrg();
		},
		toggleFilters() {
			if (this.drawer) {
				this.drawer = false;
			}
			this.filterDrawer = !this.filterDrawer;
		},
	},
	created() {
		this.initialise();
	},
};
</script>

<style scoped>
.d-flex {
	gap: 15px;
}
</style>

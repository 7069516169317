<template>
    <div class="d-flex align-center justify-center flex-column greyborder">
        <div class="text-h4 font-weight-black grey--text">{{ day }}</div>
        <div class="text-h7 font-weight-bold grey--text text--darken-1 text-uppercase">{{ month}}</div>
    </div>
</template>

<script>
export default {
    name: "DateCalendar",
    // Props
    props: {
        datevalue: {
            type: String,
            default: ""
        }
    },
    // Computed
    computed: {
        day() {
            return this.MIX_formatDateTime(this.datevalue, 'ISOString', 'DD')
        },
        month() {
            return this.MIX_formatDateTime(this.datevalue, 'ISOString', 'MMM')
        }
    },    
}
</script>

<style scoped>
.greyborder {
    border: 2px solid #e0e0e0;
    border-radius: 15px;
    padding: 10px;
    width: 80px !important;
    height: 80px !important;
}
</style>
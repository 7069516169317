<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="px-5 pt-5">
			<v-col cols="12">
				<div class="d-flex align-center">
					<div>
						<div class="text-h4 primary--text d-flex">
							{{ orgType }}s
							<v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
						</div>
					</div>
					<v-spacer />
					<!-- Search -->
					<SearchField label="Search by Name" v-model="search" :disabled="loading" :dense="true" />
					<!-- Search CPH -->
					<SearchField label="CPH Search" v-model="filter.cphNumber" :dense="true" mask="##/###/####" placeholder="##/###/####" />
					<AppButton @click.native="getTableData"><v-icon>icons8-refresh</v-icon></AppButton>
					<AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
					<AppButton :disabled="drawer" buttonclass="secondary" @click.native="addOrg">Add {{ orgType }}</AppButton>
					<div class="white--text">
						<DropdownMenu>
							<div class="d-flex align-center">
								<v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
								<div class="font-weight-bold secondary--text">Show Deleted</div>
							</div>
							<v-divider />
							<div class="d-flex align-center pt-2">
								<AppButton @click.native="importBox = true" block buttonclass="secondary--text"><v-icon>icons8-csv</v-icon><span class="ml-2">Import</span></AppButton>
							</div>
							<div class="d-flex align-center pt-1 pb-2">
								<AppButton @click.native="exportBox = true" block buttonclass="secondary--text"><v-icon>icons8-export-csv</v-icon><span class="ml-2">Export</span></AppButton>
							</div>
						</DropdownMenu>
					</div>
				</div>
				<div class="text--secondary pt-4" v-if="showInfo">The following page shows Test Results which have been imported into the system.  You can search or use the filter to locate results.</div>
			</v-col>
			<!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
			<!-- Table -->
			<v-col cols="12" class="pt-2">
					<!-- Data Table -->
					<DataTable :tableheaders="filteredHeaders" :loading="loading" :custom="orgType" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteOrg" v-on:edit="editOrg" v-on:restore="restoreOrg" v-on:tableoptions="updateTableOptions" />
			</v-col>
			<!-- Filter Drawer -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" app clipped right>
				<v-row>
					<v-col cols="12">
						<div class="d-flex align-center pt-4">
							<div class="text-h5 secondary--text">Filters</div>
							<v-spacer />
							<v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the filters below to narrow your search.
					</v-col>
					<v-col cols="12">
						<!-- Status -->
						<div class="flex-grow-1">
							<div class="my-2 text-h6 primary--text">Holding Status</div>
							<v-btn-toggle v-model="filter.status" multiple class="" style="flex-direction: column !important; width:100% !important;">
								<v-btn title="Approved" value="Approved"><v-icon class="success--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn>
								<v-btn title="Pending" value="Pending"><v-icon class="warning--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Pending</span></v-btn>
								<v-btn title="Rejected" value="Rejected"><v-icon class="error--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Rejected</span></v-btn>
								<v-btn title="Suspended" value="Suspended"><v-icon class="info--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Suspended</span></v-btn>
								<v-btn title="Archived" value="Archived"><v-icon class="grey--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Archived</span></v-btn>
							</v-btn-toggle>
						</div>
						<!-- Certificate Status -->
						<div v-if="orgType === 'Holding'" class="mt-5">
							<div class="my-2 text-h6 primary--text">Certificate Status</div>							
							<v-btn-toggle v-model="filter.certificateStatus" multiple class="" style="flex-direction: column !important; width:100% !important;">
								<v-btn title="Positive" value="Positive"><v-icon class="red--text">icons8-livestock</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Positive</span></v-btn>
								<v-btn title="Bronze" value="Bronze"><v-icon class="bronze--text">icons8-certificate</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Bronze</span></v-btn>
								<v-btn title="Silver" value="Silver"><v-icon class="silver--text">icons8-certificate</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Silver</span></v-btn>
								<v-btn title="Gold" value="Gold"><v-icon class="gold--text">icons8-certificate</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Gold</span></v-btn>
							</v-btn-toggle>
						</div>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Edit Window -->
			<v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="drawer" app clipped right>
				<!-- Holding Details -->
				<v-row dense class="mt-5">
					<v-col cols="12">
						<div class="d-flex align-center pb-2">
							<div class="text-h5 secondary--text">{{ action }} {{ orgType }}</div>
							<v-spacer />
							<v-btn icon depressed @click="closeOrg"><v-icon>icons8-close</v-icon></v-btn>
						</div>
						Use the form below to edit the Holding's details
					</v-col>
					<v-col cols="12" class="d-flex align-center" v-if="action === 'Edit'">
						<div v-if="org.status === 'Pending'" class="flex-grow-1"><AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Approve</AppButton></div>
						<div v-if="org.status === 'Pending'" class="flex-grow-1"><AppButton block buttonclass="error" @click.native="updateStatus('Rejected')">Reject</AppButton></div>
						<div v-if="org.status === 'Approved'" class="flex-grow-1"><AppButton block buttonclass="info" @click.native="updateStatus('Suspended')">Suspend</AppButton></div>
						<div v-if="org.status === 'Approved'" class="flex-grow-1"><AppButton block buttonclass="grey white--text" @click.native="updateStatus('Archived')">Archive</AppButton></div>
						<div v-if="org.status === 'Suspended' || org.status === 'Archived'" class="flex-grow-1"><AppButton block buttonclass="success" @click.native="updateStatus('Approved')">Restore</AppButton></div>
					</v-col>
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Holding Details</div> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Holding Name *" v-model="org.name" :validate="validate.name" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="CPH Number *" v-model="org.cphNumber" :validate="validate.cphNumber" mask="##/###/####" placeholder="##/###/####" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Address Line 1 *" v-model="org.addressLine1" :validate="validate.addressLine1" donotautocomplete /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Address Line 2" v-model="org.addressLine2" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Address Line 3" v-model="org.addressLine3" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Town *" v-model="org.town" :validate="validate.town" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="County *" v-model="org.county" :validate="validate.county" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Postcode *" v-model="org.postcode" :validate="validate.postcode" /> </v-col>
				</v-row>
				<!-- Contact Details -->
				<v-row dense class="mt-5">
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Contact Details</div> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="First Name *" v-model="org.contactFirstName" :validate="validate.contactFirstName" /> </v-col>
					<v-col cols="6"> <TextField backgroundcolor="white" label="Last Name *" v-model="org.contactLastName" :validate="validate.contactLastName" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Telephone *" v-model="org.telephone" :validate="validate.telephone" /> </v-col>
					<v-col cols="12"> <TextField backgroundcolor="white" label="Email *" v-model="org.email" :validate="validate.email" /> </v-col>
				</v-row>
				<!-- Owner Details -->
				<v-row dense class="mt-5">
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Owner/User Details</div> </v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Owner" v-model="org.ownerUserId" :items="users" itemtext="firstName" itemvalue="entityId" /></v-col>
				</v-row>
				<!-- Certificate Status -->
				<v-row dense class="mt-5">
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Certification Status</div> </v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Status" v-model="org.certificateStatus" :items="certificateStatus" :validate="validate.certificateStatus" /> </v-col>
				</v-row>
				<!-- Org Status -->
				<v-row dense class="mt-5">
					<v-col cols="12"> <div class="mt-2 text-h6 primary--text">Status</div> </v-col>
					<v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Status" v-model="org.status" :items="orgStatus" :validate="validate.status" /> </v-col>
				</v-row>
				<!-- Action Buttons -->
				<v-row dense class="mt-5">
					<v-col cols="6">
						<div><AppButton block buttonclass="grey" @click.native="closeOrg">Cancel</AppButton></div>
					</v-col>
					<v-col cols="6">
						<div><AppButton block buttonclass="secondary" @click.native="saveOrg">Save</AppButton></div>
					</v-col>
				</v-row>
			</v-navigation-drawer>
			<!-- Confirmation Box -->
			<ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Confirm</div>
						<v-spacer />
						<v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please confirmed you want to delete this Holding</p>
				<strong>{{ org.name }}</strong
				><br />
				<!-- permenantDelete : {{ permenantDelete }}<br /> -->
				<CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField>
				<template v-slot:footer>
					<v-row>
						<v-col cols="12" class="d-flex justify-end">
							<AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
							<AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
						</v-col>
					</v-row>
				</template>
			</ConfirmBox>
			<ExportBox headerclass="primary" footerclass="grey lighten-2" :value="exportBox">
				<template v-slot:header>
					<div class="full-width d-flex align-center">
						<div>Export CSV</div>
						<v-progress-circular indeterminate color="white" v-if="exportLoading"></v-progress-circular>
						<v-spacer />
						<v-btn icon depressed @click="exportBox = false"><v-icon class="white--text">icons8-close</v-icon></v-btn>
					</div>
				</template>
				<p>Please select the data you wish to export:</p>
				<table width="100%" cellspacing="5">
					<tr>
						<td width="100"><strong>Current page:</strong></td>
						<td>{{ itemsFrom + 1 }} to {{ itemsTo }} of {{ itemsTotal }} items (filtered)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(itemsFrom, itemsTo, true, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td><strong>Current total:</strong></td>
						<td>{{ itemsTotal }} items (filtered)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, itemsTotal, true, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td><strong>All Items:</strong></td>
						<td>{{ totalCount }} items (without filter)</td>
						<td align="right"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(0, totalCount, false, false)">Download</AppButton></td>
					</tr>
					<tr>
						<td valign="top"><strong>Specific:</strong></td>
						<td>
							<div class="d-flex align-center">
								<div>From</div>
								<div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsFrom" :clearable="false" /></div>
								<div>To</div>
								<div class="ml-2 mr-2"><TextField backgroundcolor="grey lighten-4" v-model="exportItems.itemsTo" :clearable="false" /></div>
							</div>
							<div class="ml-n7">
								<CheckboxField backgroundcolor="white" v-model="exportFiltered"><span class="grey--text">Filtered Only</span></CheckboxField>
							</div>
						</td>
						<td align="right" valign="top"><AppButton :disabled="exportLoading" buttonclass="primary" @click.native="exportCSV(exportItems.itemsFrom, exportItems.itemsTo, false, true)">Download</AppButton></td>
					</tr>
				</table>
			</ExportBox>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HoldingsAdmin",
	// components: { FarmCard },
	// data
	data: () => ({
		loading: false,
		drawer: false,
		filterDrawer: false,
		confirmBox: false,
		permenantDelete: false,
		search: "",
		filter: {
			status: ["Approved", "Pending"],
			cphNumber: "",
			certificateStatus: [],
			showDeleted: false,
		},
		tableData: [],
		totalCount: 0,
		itemsTotal: 0,
		tableHeaders: [
			{ text: "Status", value: "status", align: "center", hidden: false, sortable: false, shrunk: true },
			{ text: "Name", value: "name", align: "start", hidden: false, sortable: true, shrunk: true },
			{ text: "CPH Number", value: "cphNumber", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "First Name", value: "contactFirstName", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Last Name", value: "contactLastName", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Job Role", value: "contactJobRole", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Telephone", value: "telephone", align: "start", hidden: false, sortable: false, shrunk: false },
			{ text: "Email", value: "email", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "Website", value: "website", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Address Line 1", value: "addressLine1", align: "start", hidden: false, sortable: false, shrunk: false },
			{ text: "Address Line 2", value: "addressLine2", align: "start", hidden: false, sortable: false, shrunk: false },
			{ text: "Address Line 3", value: "addressLine3", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Town", value: "town", align: "start", hidden: false, sortable: false, shrunk: true },
			{ text: "County", value: "county", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Postcode", value: "postcode", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Latitude", value: "latitude", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Longitude", value: "longitude", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "Type", value: "type", align: "start", hidden: false, sortable: false, shrunk: false },
			{ text: "Certificate", value: "certificateStatus", align: "center", hidden: false, sortable: false, shrunk: false },
			{ text: "ownerUserId", value: "ownerUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedOrgId", value: "deletedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false },
			{ text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true },
		],
		tableOptions: {
			page: 1,
			itemsPerPage: 10,
			sortBy: ["name"],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [],
			mustSort: false,
			multiSort: false,
		},
		validate: {},
		org: {},
		orgType: "Holding",
		certificateStatus: [],
		orgStatus: [],
		action: "Add",
		// Export Box Data
		exportBox: false,
		exportItems: {
			itemsFrom: "1",
			itemsTo: "10",
		},
		exportLoading: false,
		exportFiltered: false,
		showInfo: false,
		users: [],
	}),
	// computed
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
		}),
		filteredHeaders() {
			let headers = this.tableHeaders.filter((header) => !header.hidden);
			if (this.drawer || this.filterDrawer) {
				headers = headers.filter((header) => header.shrunk);
			}
			return headers;
		},
		filterQuery() {
			let query = "";
			if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
				query += `${this.search}*`;
			}
			if (this.filter.showDeleted) {
				query += ` @deleted:{true}`;
			} else {
				query += `@deleted:{false}`;
			}
			// Set Org Type
			query += ` @type:{holding}`;
			// Filter Status
			if (JSON.stringify(this.filter.status) !== "[]") {
				query += ` @status:{${this.filter.status.join("|")}}`;
			}
			// Search by CPH Number
			if (this.filter.cphNumber !== "" && this.filter.cphNumber !== null && this.filter.cphNumber !== undefined) {
				if (query !== "") {
					query += ` `;
				}
				// remove special characters from cphNumber
				let cphNumber = this.filter.cphNumber.replace(/[^a-zA-Z0-9]/g, "");
				query += `@cphNumber:{${cphNumber}}`;
			}
			// Filter Certificate Status
			if (JSON.stringify(this.filter.certificateStatus) !== "[]") {
				query += ` @certificateStatus:{${this.filter.certificateStatus.join("|")}}`;
			}
			return query;
		},
		itemsFrom() {
			return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
		},
		itemsTo() {
			if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
				return this.itemsTotal;
			} else {
				return this.tableOptions.page * this.tableOptions.itemsPerPage;
			}
		},
		itemsCount() {
			return this.tableOptions.itemsPerPage;
		},
		itemsSortBy() {
			let sortBy = this.tableOptions.sortBy;
			if (sortBy.length > 0) {
				sortBy = sortBy.map((x) => x);
				sortBy = sortBy.join(",");
			}
			return sortBy;
		},
		itemsSortDesc() {
			let sortDesc = this.tableOptions.sortDesc[0];
			if (sortDesc) {
				sortDesc = "DESC";
			} else {
				sortDesc = "ASC";
			}
			return sortDesc;
		},
	},
	// watch
	watch: {
		filterQuery: {
			handler() {
				this.tableOptions.page = 1;
				this.getTableData();
			},
			deep: true,
		},
		tableOptions: {
			handler() {
				this.getTableData();
			},
			deep: true,
		},
	},
	// methods
	methods: {
		// Get Total Record Count
		async getTotalCount() {
			try {
				let dataResult = await this.REDIS_countFor("org", "", "", "", "", "@deleted:{false} @type:{holding}");
				// console.log(JSON.stringify(dataResult, null, 2))
				this.totalCount = dataResult.total;
			} catch (error) {
				console.error(error);
			}
		},
		// Get Table Data
		async getTableData() {
			try {
				let dataResult = await this.REDIS_searchFor("org", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
				this.tableData = dataResult.data.documents;
				this.itemsTotal = dataResult.data.total;
			} catch (error) {
				console.error(error);
			}
		},
		// Update Table Options
		updateTableOptions(options) {
			this.tableOptions = options;
		},
		// Add Org
		addOrg() {
			this.org = { ...this.$schema.org };
			this.getUsers();
			this.action = "Add";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Edit Org
		editOrg(org) {
			this.org = { ...org };
			// add a mask ##/###/#### to the cphNumber
			this.org.cphNumber = this.org.cphNumber.replace(/(\d{2})(\d{3})(\d{4})/, "$1/$2/$3");
			this.getUsers();
			this.action = "Edit";
			this.filterDrawer = false;
			this.drawer = true;
		},
		// Delete Org
		deleteOrg(org) {
			this.org = { ...org };
			this.action = "Delete";
			this.confirmBox = true;
		},
		// Close ORg
		closeOrg(org) {
			this.org = { ...org };
			this.drawer = false;
		},
		// Restore Org
		async restoreOrg(org) {
			try {
				this.loading = true;
				this.org = { ...org };
				this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.org.deleted = false;
				this.org.deletedUserId = null;
				this.org.deletedOrgId = null;
				this.org.deletedDateTime = null;
				await this.REDIS_update("org", this.org.entityId, this.org);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Confirm Delete Org
		async confirmDeleteOrg() {
			try {
				this.loading = true;
				this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
				this.org.deleted = true;
				this.org.deletedUserId = this.GET_currentUserProfile.entityId;
				this.org.deletedOrgId = this.GET_currentUserProfile.orgId;
				this.org.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
				await this.REDIS_update("org", this.org.entityId, this.org);
				this.loading = false;
				this.confirmBox = false;
				this.permenantDelete = false;
				this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Save Org
		async saveOrg() {
			try {
				this.loading = true;
				this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
				this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
				// this.org.postcode remove spaces and make uppercase
				this.org.type = this.orgType;
				let validationErrors = null;
				validationErrors = 0;
				if (this.org.name === "" || this.org.name === undefined || this.org.name === null) {
					this.validate.name = "Name is required";
					validationErrors++;
				}
				if (this.org.addressLine1 === "" || this.org.addressLine1 === undefined || this.org.addressLine2 === null) {
					this.validate.addressLine1 = "Address Line 1 is required";
					validationErrors++;
				}
				if (this.org.town === "" || this.org.town === undefined || this.org.town === null) {
					this.validate.town = "Town is required";
					validationErrors++;
				}
				if (this.org.county === "" || this.org.county === undefined || this.org.county === null) {
					this.validate.county = "County is required";
					validationErrors++;
				}
				if (this.org.postcode === "" || this.org.postcode === undefined || this.org.postcode === null) {
					this.validate.postcode = "Postcode is required";
					validationErrors++;
				}
				if (this.org.contactFirstName === "" || this.org.contactFirstName === undefined || this.org.contactFirstName === null) {
					this.validate.contactFirstName = "First Name is required";
					validationErrors++;
				}
				if (this.org.contactLastName === "" || this.org.contactLastName === undefined || this.org.contactLastName === null) {
					this.validate.contactLastName = "Last Name is required";
					validationErrors++;
				}
				if (this.org.email === "" || this.org.email === undefined || this.org.email === null) {
					this.validate.email = "Email is required";
					validationErrors++;
				}
				if (this.org.telephone === "" || this.org.telephone === undefined || this.org.telephone === null) {
					this.validate.telephone = "Telephone is required";
					validationErrors++;
				}
				if (this.org.status === "" || this.org.status === undefined || this.org.status === null) {
					this.validate.status = "Status is required";
					validationErrors++;
				}
				if (this.org.postoicde !== null) {
					this.org.postcode = this.org.postcode.replace(/\s/g, "").toUpperCase();
				}
				// loop through the this.org object and trim all the values
				Object.keys(this.org).forEach((key) => {
					if (typeof this.org[key] === "string") {
						this.org[key] = this.org[key].trim();
						// remove any . from the end of the string
						if (this.org[key].slice(-1) === ".") {
							this.org[key] = this.org[key].slice(0, -1);
						}
					}
				});
				// remove special characters from the cphNumber
				this.org.cphNumber = this.org.cphNumber.replace(/[^a-zA-Z0-9]/g, "");
				// If no validation errors
				if (this.action === "Add" && validationErrors === 0) {
					this.org.createdUserId = this.GET_currentUserProfile.entityId;
					this.org.createdOrgId = this.GET_currentUserProfile.orgId;
					this.org.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
					await this.REDIS_create("org", this.org);
					this.loading = false;
					this.org = { ...this.$schema.org };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else if (this.action === "Edit" && validationErrors === 0) {
					await this.REDIS_update("org", this.org.entityId, this.org);
					this.loading = false;
					this.org = { ...this.$schema.org };
					this.drawer = false;
					this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
					this.getTableData();
				} else {
					this.loading = false;
				}
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
				console.error(error);
			}
		},
		// Initialise
		async initialise() {
			this.loading = true;
			let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{orgStatus|certificateStatus}");
			this.orgStatus = lookupResult.data.documents.filter((item) => item.type === "orgStatus");
			this.certificateStatus = lookupResult.data.documents.filter((item) => item.type === "certificateStatus");
			this.getTableData();
			this.loading = false;
		},
		// Export Box CSV
		async exportCSV(itemsFrom, itemsTo, filter, specific) {
			if (itemsFrom > itemsTo) {
				this.MIX_alertBox({ show: true, message: "From must be less than To", color: "error", timeout: "4000" });
			} else {
				this.exportLoading = true;
				var itemsCount, tableData, csv, query, exportFileName;
				switch (true) {
					// Current Page / Current Total (filtered)
					case filter && !specific:
						itemsCount = itemsTo - itemsFrom;
						query = this.filterQuery;
						break;
					// All (Not filtered)
					case !filter && !specific:
						itemsCount = this.totalCount;
						query = `@deleted:{${this.filter.showDeleted}} @type:{holding}`;
						break;
					// Specific (filtered/ not filtered)
					case specific:
						itemsFrom = itemsFrom - 1;
						itemsCount = itemsTo - itemsFrom;
						if (this.exportFiltered) {
							query = this.filterQuery;
						} else {
							query = `@deleted:{${this.filter.showDeleted}} @type:{holding}`;
						}
						break;
				}
				// console.log(`itemsFrom: ${itemsFrom}, itemsTo: ${itemsTo}, itemsCount: ${itemsCount}, filter: ${filter}, specific: ${specific}`);
				let exportResult = await this.REDIS_searchFor("org", itemsFrom, itemsCount, this.itemsSortBy, this.itemsSortDesc, query);
				tableData = JSON.parse(JSON.stringify(exportResult.data.documents));
				csv = this.$papa.unparse(tableData);
				if (itemsFrom === 0) {
					itemsFrom = 1; // This is just for filename purposes
				}
				if (itemsFrom >= 10) {
					itemsFrom = itemsFrom + 1;
				}
				switch (true) {
					case !specific && filter:
						exportFileName = `Holdings_Filtered_${itemsFrom}_to_${itemsTo}`;
						break;
					case !specific && !filter:
						exportFileName = `Holdings_${itemsFrom}_to_${itemsTo}`;
						break;
					case specific && this.exportFiltered:
						exportFileName = `Holdings_Specific_Filtered_${itemsFrom}_to_${itemsTo}`;
						break;
					case specific && !this.exportFiltered:
						exportFileName = `Holdings_Specific_${itemsFrom}_to_${itemsTo}`;
						break;
				}
				// console.log(exportFileName);
				// console.log(csv.length);
				this.$papa.download(csv, exportFileName);
				this.exportLoading = false;
			}
		},
		updateStatus(status) {
			this.org.status = status;
			this.saveOrg();
		},
		toggleFilters() {
			if (this.drawer) {
				this.drawer = false;
			}
			this.filterDrawer = !this.filterDrawer;
		},
		// Get Table Data
		async getUsers() {
			try {
				this.loading = true;
				let filterQuery = `@deleted:{false} @orgType:{Holding}`;
				let dataResult = await this.REDIS_searchFor("user", 0, 9999, "firstName", "ASC", filterQuery);
				this.users = dataResult.data.documents;
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
	},
	created() {
		this.getTotalCount();
		this.initialise();
	},
};
</script>

<style scoped>
.d-flex {
	gap: 15px;
}
</style>

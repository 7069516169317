<template>
    <div>
        <v-banner app class="grey darken-1 full-width fixed foreground-high elevation-0 pa-0 ma-0 foreground-low" height="80">
            <div class="d-flex align-center" :class="$vuetify.breakpoint.smAndUp ? 'desktopheader':''">
                <v-icon class="white--text pr-2" x-large>icons8-timeline-week</v-icon>
                <div class="text-h6 white--text font-weight-bold" v-if="display === 'ListEvents'">Test Events</div>
                <div class="text-h6 white--text font-weight-bold" v-if="display === 'AddEvent'">Add Test Event</div>
                <div class="text-h6 white--text font-weight-bold" v-if="display === 'ViewEvent'">Test Event</div>
                <v-spacer />
                <div>
                    <AppButton :text="true" v-if="display === 'ListEvents'" @click.native="display = 'AddEvent'; MIX_scrollToTop();" depressed class="grey darken-2 white--text"><v-icon>icons8-add</v-icon><span class="pl-2">Event </span></AppButton>
                    <AppButton :text="true" v-else @click.native="closeEvent();  MIX_scrollToTop();" depressed class="grey darken-2 white--text"><v-icon class="">icons8-back-to</v-icon><span class="pl-2">Events</span></AppButton>
                </div>
            </div>
        </v-banner>
        <div style="margin-top: 80px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary':'accent'" :value="!loading ? 100 : 0"></v-progress-linear></div>        
        <transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
            <v-container v-if="display === 'ListEvents'" class="" fluid>
                <v-row class="dense">
                    <v-col cols="12" xs="12" sm="4" class="d-flex align-center">
                        <!-- <div class="flex-grow-1"><SearchField label="Search" v-model="search" background="grey lighten-4" /></div> -->
						<div class="flex-grow-1"><DropdownAdvanced label="Filter by Holding" :items="computedHoldings" v-model="filter.holdingOrgId" background="grey lighten-4"/></div>
                        <div class="ml-3"><AppButton @click.native="filterBox = true;" background="grey lighten-4"><v-icon>icons8-adjust</v-icon></AppButton></div>
                    </v-col>
                    <v-col cols="12" class="text-body-2 mx-2 text--secondary" v-if="filter.holdingOrgId !== '' || filter.status !== '' || filter.startDate !== '' || filter.endDate !== ''">
                        <i>Filters have been applied to the search results, to change them click the filter button above.</i>
                    </v-col>
                </v-row>
                <v-row class="dense" v-if="!loading && JSON.stringify(testevents) !== '[]'">
                    <v-col cols="12" xs="12" sm="4" class="" v-for="(event, index) in testevents" :key="index">
                        <FarmCard @click.native="viewEvent(event.entityId); MIX_scrollToTop();" class="pa-4">
                            <div class="d-flex align-center" dense>
                                <div><DateCalendar :datevalue="event.eventDate" /></div>
                                <div class="d-flex align-start justify-start flex-column">
                                    <div class="d-flex align-start justify-start text-h7 primary--text full-width ml-3  flex-column">
                                        <div class="font-weight-bold grey--text text--darken-2">{{ MIX_lookupHolding(event.holdingOrgId, holdings, 'name') }}</div>
                                        <!-- <div class="text-body-2 text--secondary">{{ MIX_formatAddress(MIX_lookupHolding(event.holdingOrgId, holdings, '')) }}</div> -->
                                        <div class="text-body-2 text--secondary">{{ event.cphNumber }}</div>
                                        
                                        <v-spacer />
                                        <v-chip small label class="mt-2 white--text font-weight-bold" :class="MIX_accountStatus(event.status)" v-if="event.status !== 'Approved'">{{ event.status }}</v-chip>
                                        <!-- {{ event.eventDate }}<br />
                                        {{ MIX_formatDateTime(event.eventDate, "ISOString", "unix") }} -->
                                    
                                    </div>
                                    <!-- <div class="text-body-2 text--secondary my-3">{{ MIX_formatAddress(holding) }}</div>
                                    <div class="d-flex align-center full-width mt-2">
                                        <div class="d-flex align-center"><v-icon class="primary--text">icons8-cow</v-icon><div class="ml-1 mr-3 font-weight-bold text--secondary">0</div></div>
                                        <v-chip small label class="white--text font-weight-bold mr-2" :class="MIX_certificateStatus(holding.certificateStatus)" v-if="holding.certificateStatus !== undefined">{{ holding.certificateStatus }}</v-chip>
                                        
                                        <v-spacer />
                                    </div> -->
                                </div>
                                <v-spacer />
                                <div class="d-flex align-center justify-right">
                                    <v-btn @click="viewEvent(event.entityId); MIX_scrollToTop();" icon><v-icon large class="secondary--text">icons8-next-page</v-icon></v-btn>
                                </div>
                            </div>
                        </FarmCard>
                    </v-col>
                </v-row>
                <v-row class="dense" v-if="JSON.stringify(testevents) === '[]'">
                        <v-col>
                            <FarmCard class="pa-5 grey lighten-3 text--secondary">
                                <strong>Information</strong><br />
                                <i>There are currently no test events that match the search criteria, please use the Add Event button to create a test event.</i>
                        </FarmCard>
                        </v-col>
                    </v-row>
            </v-container>
            <AddEvent v-if="display === 'AddEvent'" v-on:close="closeEvent()" />
            <ViewEvent v-if="display === 'ViewEvent'" :testevent="activeEvent" v-on:close="closeEvent()" /> 
        </transition>
        <ConfirmBox v-model="filterBox" v-on:close="filterBox = false" background-color="primary">
                <div class="full-width d-flex align-center">
                        <div class="text-h6 text--secondary">Filters</div>
                        <v-spacer />
                        <v-btn icon depressed @click="filterBox = false"><v-icon class="grey--text">icons8-close</v-icon></v-btn>
                    </div>
                <v-row class="mt-2">
                    <v-col cols="12"><DropdownSimple label="Filter by Status" :items="testeventStatus" v-model="filter.status" background="grey lighten-4"/></v-col>
                    <v-col cols="12"><DatePicker label="Start Date" v-model="filter.startDate" :validate="validate.startDate" background="grey lighten-4"/></v-col>
                    <v-col cols="12"><DatePicker label="End Date" v-model="filter.endDate" :validate="validate.endDate" background="grey lighten-4"/></v-col>
                    <v-col cols="6"><AppButton block @click.native="clearFilters()" buttonclass="grey lighten-2">Clear</AppButton></v-col>
                    <v-col cols="6"><AppButton block @click.native="filterBox = false" buttonclass="secondary">Apply</AppButton></v-col>
                    <!-- <v-col><pre>{{ filter }}</pre></v-col> -->
                </v-row>
        </ConfirmBox>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "TestsList",
    components: {
        AddEvent: () => import('../vet/AddEvent.vue'),
        ViewEvent: () => import('../vet/ViewEvent.vue'),
    },
    data: () => ({
        // display: "AddEvent",
        display: "ListEvents",
        loading: false,
        search: "",
        activeEvent: {},
        vets: [],
        vetsTotal: 0,
        vetholdings: [],
        vetholdingsTotal: 0,
        holdings: [],
        holdingsTotal: 0,
        testevents: [],
        testeventsTotal: 0,
        testeventStatus: [],
        filter: {
            holdingOrgId: "",
            status: "",
            startDate: "",
            endDate: "",
        },
        validate: {
            startDate: "",
            endDate: "",
        },
        filterBox: false
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        computedHoldings() {
            return this.holdings.map((holding) => ({
                text: holding.name,
                description: holding.town + " " + holding.postcode,
                cphNumber: holding.cphNumber,
                managementGroups: holding.managementGroups,
                value: holding.entityId,
            }));
        },
    },
    // watch
    watch: {
        search: {
            handler: function () {
                this.getEvents();
            },
            deep: true,
        },
        filter: {
            handler: function () {
                let startDate = this.MIX_formatDateTime(this.filter.startDate, "ISOString", "unix");
                let endDate = this.MIX_formatDateTime(this.filter.endDate, "ISOString", "unix");
                if (startDate !== '' && endDate !== '') {             
                    if (endDate < startDate) {
                        this.validate.startDate = "Start Date must be before End Date";
                        this.validate.endDate = "End Date must be after Start Date";
                    } else {
                        this.validate.startDate = "";
                        this.validate.endDate = "";
                        this.getEvents();
                    }
                } else {
                    this.validate.startDate = "";
                    this.validate.endDate = "";
                    this.getEvents();
                }
            },
            deep: true,
        }
    },
    // methods
    methods: {
        async initialise() {
            this.loading = true;
            // Get Vets Linked to this User
            await this.MIX_getVetsByOwner(this.GET_currentUserProfile.entityId).then((vetsResult) => {
                if (vetsResult.success) {
                    this.vets = vetsResult.data;
                    this.vetsTotal = vetsResult.total;
                }
            });
            // Get Vet Holdings Linked to this User Vet Practices
            await this.MIX_getVetHoldingsByVet(this.vets).then((vetholdingsResult) => {
                if (vetholdingsResult.success) {
                    this.vetholdings = vetholdingsResult.data;
                    this.vetholdingsTotal = vetholdingsResult.total;
                }
            });
            // Get Holding Details Linkted to this User Vet Practices
            await this.MIX_getHoldingsByVetHoldings(this.vetholdings).then((holdingsResult) => {
                if (holdingsResult.success) {
                    this.holdings = holdingsResult.data;
                    this.holdingsTotal = holdingsResult.total;
                }
            });
            // Get Lookup Data
            let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{testeventStatus}");
            this.testeventStatus = lookupResult.data.documents.filter((item) => item.type === "testeventStatus");
            this.getEvents();
            this.loading = false;
        },
        async getEvents() {
            try {
                // Get Test Events Linked to the Vet
                let query = `@deleted:{false}`;
                if (this.filter.holdingOrgId) {
                    query += ` @holdingOrgId:{${this.filter.holdingOrgId}}`;
                }
                if (this.filter.status) {
                    query += ` @status:{${this.filter.status}}`;
                }
                let startDate = '';
                let endDate = '';
                switch (true) {
                    case (this.filter.startDate !== '' && this.filter.endDate === ''):
                        startDate = this.MIX_formatDateTime(this.$dayjs(this.filter.startDate).startOf('day'), "ISOString", "unix");
                        // Event Date is after Start Date
                        query += ` @eventDate:[(${startDate} inf]`;
                        break;
                    case (this.filter.startDate === '' && this.filter.endDate !== ''):
                        endDate = this.MIX_formatDateTime(this.$dayjs(this.filter.endDate).endOf('day'), "ISOString", "unix");
                        // Event Date is before End Date
                        query += ` @eventDate:[(-inf ${endDate}]`;
                        break;
                    case (this.filter.startDate !== '' && this.filter.endDate !== ''):
                        startDate = this.MIX_formatDateTime(this.$dayjs(this.filter.startDate).startOf('day'), "ISOString", "unix");
                        endDate = this.MIX_formatDateTime(this.$dayjs(this.filter.endDate).endOf('day'), "ISOString", "unix");
                        // Event Date is in Between Start and End Date
                        query += ` @eventDate:[(${startDate} ${endDate}]`;
                        break; 
                }
                // TODO - Restrict results to the Vet
                if (JSON.stringify(this.vets) !== '[]') {
                    query += ` @vetOrgId:{${this.vets.map((vet) => vet.entityId).join('|')}}`;
                }
                // TEXT console.log('vets = ', JSON.stringify(this.vets.map((vet) => vet.entityId), null, 2))
                // console.log('vets = ', JSON.stringify(this.vets, null, 2))
                // console.log('query', query)  
                await this.REDIS_searchFor('testevent', 0, 9999, 'eventDate', 'DESC', query).then((testeventsResult) => {
                    // console.log('testeventsResult', JSON.stringify(testeventsResult, null, 2))
                    if (testeventsResult.success) {
                        this.testevents = testeventsResult.data.documents;
                        this.testeventsTotal = testeventsResult.data.total;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        async viewEvent(entityId) {
            try {
                this.loading = true;
                let eventResult = await this.REDIS_read('testevent', entityId);
                // console.log('eventResult', JSON.stringify(eventResult, null, 2))
                this.activeEvent = eventResult.data;
                this.activeEvent.holdingName = this.MIX_lookupHolding(this.activeEvent.holdingOrgId, this.holdings, 'name');
                this.activeEvent.vetName = this.MIX_lookupVet(this.activeEvent.vetOrgId, this.vets, 'name');
                this.display = "ViewEvent";
                this.loading = false;
            }
            catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Loading Event", color: "error", timeout: "4000" });
                console.error(error);
                this.loading = false;
            }
        },
        closeEvent() {
            this.display = "ListEvents";
            this.getEvents();
            this.activeEvent = null;
        },
        clearFilters() {
            this.filter.holdingOrgId = "";
            this.filter.status = "";
            this.filter.startDate = "";
            this.filter.endDate = "";
            // this.filterBox = false;
        },
    },
    created() {
        this.initialise();
    },
}
</script>

<style scoped>
.desktopheader {
	width: calc(100vw - 150px) !important;
}
</style>
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: {
            customProperties: true,
        },
		themes: {    
            light: {
                primary: "#6DA743",
                secondary: "#662B7F",
                accent: "#DBE442", //"#DBE442",
                error: "#DA4C4C",
                success: "#43A047",
                warning: "#FB8C00",
                darkgrey: "#828282",
                lightgrey: "#F2F2F2",
                background: "#E0E0E0",
                gold: "#FDC000",
                silver: "#C0C0C0",
                bronze: "#CD7F32",
            },
        },
    },
});

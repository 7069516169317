<template>
    <div>
    <v-bottom-navigation height="100" class="fixed rounded-of-top" app color="primary" horizontal>
		<v-btn :disabled="MIX_checkLevelAndStatus(item)"  width="30%" @click="MIX_go(item.path)" v-for="(item, index) in MIX_filteredNav('BottomNav')" :key="index">
            <div class="d-flex flex-column">
                <v-icon x-large>{{ item.icon }}</v-icon>
                <div class="text-body-2 font-weight-bold mt-1">{{ item.title }}</div>
            </div>
        </v-btn>
    </v-bottom-navigation>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "BottomNav",
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_userAuthenticated: "GET_userAuthenticated",
        }),
    },
    created() {
        // console.log(this.GET_currentUserProfile.orgType)
		
    }

};
</script>

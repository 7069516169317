<template>
	<div class="full-width" style="background-color: #743189 !important; max-height: 100vh !important">
		<div class="printbutton">
			<v-btn class="secondary" @click="print()">Click to Print</v-btn>
		</div>
		<div v-if="!printMobile" style="max-width: 100vw !important" class="noprint">
			<div style="background-color: #743189 !important">
				<div style="background-color: #9cb2cf !important; width: 100% !important; height: 80px !important"></div>
				<div class="d-flex align-start">
					<div style="background-color: #743189 !important">
						<img src="@/assets/certificatebackground.svg" alt="Background" width="50" />
					</div>
					<div class="flex-grow-1 white pa-2 mb-10">
						<div class="text-center">
							<img src="@/assets/BVD.png" alt="Agriculture Research Centre" width="150" />
						</div>
						<div class="d-flex align-center">
							<div>
								<div class="text-center full-width font-weight-bold text-uppercase text-h6">
									<span :class="MIX_certificateStatus(org?.certificateStatus) + '--text'">{{ org?.certificateStatus }} Status</span>
								</div>
								<div class="text-center full-width font-weight-bold text-uppercase text-h7">
									<span class="grey--text text--darken-2">Certificate of <br />Gwaredu BVD status</span>
								</div>
								<div class="text-center full-width font-weight-bold text-body-2 mt-5">
									<span class="grey--text text--darken-2">This certifies that:</span><br />
									<span class="grey--text text--darken-2"
										>Keeper of Herd: <strong>{{ org?.contactFirstName }} {{ org?.contactLastName }}</strong></span
									>
								</div>
								<v-divider class="mt-2" />
								<table class="mt-5 mx-5 text-body-2 grey--text text--darken-2" width="100%">
									<tr>
										<td class="flex-grow-1" width="50%">
											Farm name: <strong>{{ org?.name }}</strong>
										</td>
									</tr>
									<tr>
										<td class="flex-grow-1">
											CPH: <strong>{{ org?.cphNumber | VMask("##/###/####") }}</strong>
										</td>
									</tr>
									<tr>
										<td class="flex-grow-1">
											BVD Screening Result:<br />
											<span v-if="org?.certificateStatus !== 'Positive'" class="font-weight-bold">ANTIBODY FREE</span>
										</td>
									</tr>
									<tr>
										<td class="flex-grow-1">
											Since: <strong>{{ MIX_formatDateTime(org?.lastTestDate, "ISOString", "DD-MMM-YYYY") }}</strong>
										</td>
									</tr>
								</table>
								<div class="pt-2 px-5">
									<p class="primary--text font-weight-bold text-body-2">For details of Gwaredu BVD and what this Screening Result means please visit the website <a class="secondary--text" href="https://www.ahww.cymru" target="_blank">www.ahww.cymru</a></p>
								</div>
								<!-- <div class="pt-2 px-5 mx-5 body-2" style="border: 2px solid grey !important">
										<p>The screening result is only an indicator of the Herd BVD status at the date of screening. Any exposure to BVD after the test date could change the status of the Herd and pose a risk to the purchasers herd.</p>
										<p>Disclaimer: The screening result is based on a representative sample taken on the above date of selected management group(s) of the whole Herd and Gwaredu BVD take no responsibility for the interpretation of the screening result nor for errors made by the relevant veterinary surgeons who carry out the test, the laboratory who interpret those test results or for the movement on to the CPH after the date of the test.</p>
									</div> -->
							</div>
						</div>
					</div>
					<div style="background-color: #743189 !important">
						<img src="@/assets/certificatebackground.svg" alt="Background" width="50" />
					</div>
				</div>
			</div>
		</div>
		<!-- Page Body -->
		<table cellpadding="0" cellspacing="0" v-if="printMobile">
			<tbody>
				<tr>
					<td>
						<!-- INSERT PAGE CONTENT HERE -->
						<div style="background-color: #743189 !important">
							<div style="background-color: #9cb2cf !important; width: 100% !important; height: 80px !important"></div>
							<div class="d-flex align-start">
								<div style="background-color: #743189 !important">
									<img src="@/assets/certificatebackground.svg" alt="Background" width="100" />
								</div>
								<div class="flex-grow-1 white pa-10">
									<div class="d-flex align-center">
										<div class="mr-10">
											<img src="@/assets/BVD.png" alt="Agriculture Research Centre" width="200" />
										</div>
										<div>
											<div class="text-left full-width font-weight-bold text-uppercase text-h4">
												<span :class="MIX_certificateStatus(org?.certificateStatus) + '--text'">{{ org?.certificateStatus }} Status</span>
											</div>
											<div class="text-left full-width font-weight-bold text-uppercase text-h4">
												<span class="grey--text text--darken-2">Certificate of Gwaredu BVD status</span>
											</div>
											<div class="text-left full-width font-weight-bold text-h6 mt-5">
												<span class="grey--text text--darken-2">This certifies that:</span><br />
												<span class="grey--text text--darken-2"
													>Keeper of Herd: <strong>{{ org?.contactFirstName }} {{ org?.contactLastName }}</strong></span
												>
											</div>
										</div>
									</div>
									<v-divider class="mt-2" />
									<table class="mt-5 mx-5 text-h6 grey--text text--darken-2" width="100%">
										<tr>
											<td class="flex-grow-1" width="50%">
												Farm name: <strong>{{ org?.name }}</strong>
											</td>
											<td class="flex-grow-1">
												CPH: <strong>{{ org?.cphNumber | VMask("##/###/####") }}</strong>
											</td>
										</tr>
										<tr>
											<td class="flex-grow-1">
												BVD Screening Result:
												<span v-if="org?.certificateStatus !== 'Positive'" class="font-weight-bold">ANTIBODY FREE</span>
											</td>
											<td class="flex-grow-1">
												Since: <strong>{{ MIX_formatDateTime(org?.lastTestDate, "ISOString", "DD-MMM-YYYY") }}</strong>
											</td>
										</tr>
									</table>

									<div class="pt-2 px-5">
										<p class="primary--text font-weight-bold text-h6">For details of Gwaredu BVD and what this Screening Result means please visit the website <a class="secondary--text" href="https://www.ahww.cymru" target="_blank">www.ahww.cymru</a></p>
									</div>
									<div class="pt-2 px-5 mx-5 body-2" style="border: 2px solid grey !important">
										<p>The screening result is only an indicator of the Herd BVD status at the date of screening. Any exposure to BVD after the test date could change the status of the Herd and pose a risk to the purchasers herd.</p>
										<p>Disclaimer: The screening result is based on a representative sample taken on the above date of selected management group(s) of the whole Herd and Gwaredu BVD take no responsibility for the interpretation of the screening result nor for errors made by the relevant veterinary surgeons who carry out the test, the laboratory who interpret those test results or for the movement on to the CPH after the date of the test.</p>
									</div>
									<div class="container" style="width: 100vw !important">
										<div class="item">
											<a href="https://www.ahww.cymru/en/" target="_blank"><img src="@/assets/BVD.png" alt="Agriculture Research Centre" /></a>
										</div>
										<div class="item">
											<a href="https://arc-csg.cymru/" target="_blank"><img src="@/assets/ARC.png" alt="Agriculture Research Centre" /></a>
										</div>
										<div class="item">
											<a href="https://www.rvc.ac.uk/" target="_blank"><img src="@/assets/RVC.png" alt="Agriculture Research Centre" /></a>
										</div>
										<div class="item">
											<a href="https://www.gov.wales/" target="_blank"><img src="@/assets/WelshGov.png" alt="Gwaredu BVD" /></a>
										</div>
									</div>
								</div>
								<div style="background-color: #743189 !important">
									<img src="@/assets/certificatebackground.svg" alt="Background" width="100" />
								</div>
							</div>
						</div>
						<div style="background-color: #743189 !important; width: 100% !important; height: 80px !important"></div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "PrintTemplate",
	data: () => ({
		org: null,
		printMobile: false,
	}),
	methods: {
		print() {
			this.printMobile = true; 
			setTimeout(() => {
				window.print();
			}, 1000);
		},
	},
	async created() {
		let orgId = this.$route.query.orgid;
		if (this.$route.query.print === 'desktop') {
			this.printMobile = true;
		}
		let orgResult = await this.REDIS_read("org", orgId);
		this.org = orgResult.data;
		// console.log("org = ", JSON.stringify(this.org, null, 2));
	},
};
</script>

<style>
body {
	-webkit-print-color-adjust:exact
}
</style>

<style scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}

.item {
	flex-basis: 25%;
	flex: 1;
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.item img {
	width: 100%;
	height: auto;
	max-height: 100px;
	object-fit: contain;
}

.printbutton {
	z-index: 999 !important;
	position: fixed;
	left: 0;
	padding: 10px;

}

@media print {
	@page {
		size: landscape;
	}
	.noprint {
		display: none;
	}
	.printbutton {
		display: none;
	}
}
</style>

<template>
	<div>
		<v-container class="full-viewpoint-height">
			<v-overlay :value="authenticating" opacity="1" color="primary" class="foreground-highest">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-row class="dense">
				<v-col cols="12" xs="12" sm="12" class="d-flex justify-center mt-3">
					<img src="@/assets/BVD Logo full.png" width="150" height="184" />
				</v-col>
			</v-row>
			<v-row class="dense d-flex justify-center">
				<v-col cols="12" xs="12" sm="8" lg="7" xl="5" class="" style="margin: 0px !important; padding: 0px !important">
					<FarmCard class="mt-6 py-5 px-5 primary rounded-xl" footerclass="text-center ma-0 pa-0">
						<v-row class="px-1 pt-2" dense>
							<v-col cols="12" class="white--text my-5 text-center"> Welcome to the On Farm App. <br />Please login to continue. </v-col>
							<v-col cols="12">
								<TextField label="Email" v-model="form.email" />
							</v-col>
							<v-col cols="12">
								<PasswordField label="Password" v-model="form.password" type="password" v-on:keyup.enter.native="login" />
							</v-col>
						</v-row>
						<template v-slot:footer>
							<v-row class="mt-2">
								<v-col cols="6">
									<v-btn @click="MIX_go('/register')" x-large depressed block class="grey white--text rounded-of">Register</v-btn>
								</v-col>
								<v-col cols="6">
									<v-btn @click="login()" x-large depressed block class="secondary white--text rounded-of">Login</v-btn>
								</v-col>
								
								<!-- <v-col cols="12" class="d-flex justify-center">
                                    <v-btn disabled @click="login()" x-large depressed block class="primary white--text rounded-of">Offline Access</v-btn>
                                </v-col> -->
								<!-- <v-col cols="12" class="d-flex align-center">
                            <v-btn @click="form.email = 'ryan+farmer@vindico.net'; form.password = 'Vindico1975!'; login()" x-large depressed class="mx-1 primary lighten-1 white--text rounded-of">Farmer</v-btn>
                            <v-btn @click="form.email = 'ryan+vet@vindico.net'; form.password = 'Vindico1975!'; login()" x-large depressed class="mx-1 primary lighten-1 white--text rounded-of">Vet</v-btn>
                            <v-btn @click="form.email = 'ryan@vindico.net'; form.password = 'Vindico1975!'; login()" x-large depressed class="mx-1 primary lighten-1 white--text rounded-of">Project</v-btn>																		
                        </v-col> -->
							</v-row>
						</template>
					</FarmCard>
				</v-col>
			</v-row>
			<v-row class="dense d-flex justify-center align-start">
				<v-col cols="12" xs="12" sm="8" lg="7" xl="5" class="primary ma-0 pa-0">
					<FarmCard class="grey lighten-5 rounded-xl d-flex align-start justify-center pt-10" footerclass="text-center" style="height: 240px !important; width: 100% !important">
						<div class="pt-5 fullwidth d-flex align-center justify-center">
							<div><img src="@/assets/RVC.png" alt="Agriculture Research Centre" :height="$vuetify.breakpoint.xsOnly ? 50:80" /></div>							
							<div><img  src="@/assets/ARC.png" alt="Agriculture Research Centre" :height="$vuetify.breakpoint.xsOnly ? 50:80" /></div>
							<div><img src="@/assets/WelshGov.png" alt="Gwaredu BVD" :height="$vuetify.breakpoint.xsOnly ? 50:80" /></div>
						</div>
						<div class="font-weight-bold grey--text text--darken-1 mt-2 text-center caption">Copyright <a :href="$config.website" target="_blank">{{ $config.companyName }}</a> {{ MIX_formatDateTimeNow('YYYY') }}</div>
						<div class="font-weight-bold grey--text text-center caption">Version {{ appVersion }} </div>
						<!-- <div class="pt-3"> <img src="@/assets/logo-bar.jpeg" contain width="340" /></div> -->
					</FarmCard>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
const version = require("@/../package.json").version;
import { mapGetters } from "vuex";
export default {
	name: "LoginView",
	data: () => ({
		authenticating: false,
		form: {
			email: "",
			password: "",
		},
	}),
	computed: {
		...mapGetters({
			GET_currentUserAuth: "GET_currentUserAuth",
			GET_currentUserProfile: "GET_currentUserProfile",
			GET_userAuthenticated: "GET_userAuthenticated",
		}),
		appVersion() {
			return version;
		},
	},
	components: {},
	methods: {
		async login() {
			let t = this;
			let firebaseLoginResult = await t.FIREBASE_login(t.form.email, t.form.password);
			// console.log(firebaseLoginResult)
			if (firebaseLoginResult.success === true) {
				// console.log('GET_currentUserProfile:', JSON.stringify(t.GET_currentUserProfile, null, 2))
				t.authenticating = true;
				setTimeout(() => {
					switch (t.GET_currentUserProfile.orgType) {
						case "Holding":
							t.$router.push("/MyHoldings");
							break;
						case "Vet":
							t.$router.push("/holdingslist");
							break;
						case "Project":
							t.$router.push("/admin/homeadmin");
							break;
						default:
							t.$router.push("/");
							break;
					}
					// Redirect to Home after successful login
					// t.$router.push("/"); // used to be /home
					t.authenticating = false;
				}, 3000);
			} else {
				// Show Alert Box with Login Error
				t.MIX_alertBox({ show: true, message: firebaseLoginResult.error, color: "error", timeout: "4000" });
			}
		},
	},
	created() {},
};
</script>

<style scoped>
.rounded-xl {
	border-top-left-radius: 50px !important;
	border-top-right-radius: 50px !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}
</style>

<template>
	<div>
		<div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
		<v-row dense class="px-5 pt-5" v-if="$vuetify.breakpoint.xsOnly">
			<v-col>
				<FarmCard class="pa-5 grey lighten-3 text--secondary">
					<strong>Information</strong><br />
					<i>Admin Dashboard Only Available on Desktop.</i>
				</FarmCard>
			</v-col>
		</v-row>
		<v-row dense class="px-5 pt-5" v-if="$vuetify.breakpoint.smAndUp">
			<v-col cols="4">
				<div class="d-flex align-center full-width pb-5">
					<div class="flex-grow-1 pr-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ holdingsCount }}</template>
							<template v-slot:text>Holdings</template>
						</DashCard>
					</div>
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ totalAnimalCount }}</template>
							<template v-slot:text>Total Cattle</template>
						</DashCard>
					</div>
					<div class="flex-grow-1 pl-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ vetsCount }}</template>
							<template v-slot:text>Total Vets</template>
						</DashCard>
					</div>
				</div>
				<FarmCard headerclass="grey darken-1 white--text" class="text-center" style="height: 530px !important">
					<template v-slot:header><div class="pa-5">Holding Certification Status</div></template>
					<DonutChart v-if="!loadingHolding" :datadown="certificateStatus" class="mt-10" style="height: 330px !important" />
					<div v-if="loadingHolding" style="height: 450px !important" class="d-flex align-center justify-center flex-column">
						<v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
						<div class="grey--text text--lighten-1 mt-5 font-weight-bold animate__animated animate__flash animate__slower animate__infinite">Loading</div>
					</div>
					<v-row class="mx-2 mt-5" v-if="!loadingHolding">
						<v-col cols="3" class="">
							<DashCard :loading="loading" class="white" valueclass="error--text font-weight-bold" textclass="error--text" style="border: 2px solid #da4c4c !important">
								<template v-slot:value>{{ positiveCertifiedPercentage }}%</template>
								<template v-slot:text>Positive</template>
							</DashCard>
						</v-col>
						<v-col cols="3" class="">
							<DashCard :loading="loading" class="white" valueclass="bronze--text font-weight-bold" textclass="bronze--text" style="border: 2px solid #cd7f32 !important">
								<template v-slot:value>{{ bronzeCertifiedPercentage }}%</template>
								<template v-slot:text>Bronze</template>
							</DashCard>
						</v-col>
						<v-col cols="3" class="">
							<DashCard :loading="loading" class="white" valueclass="silver--text font-weight-bold" textclass="silver--text" style="border: 2px solid #c0c0c0 !important">
								<template v-slot:value>{{ silverCertifiedPercentage }}%</template>
								<template v-slot:text>Silver</template>
							</DashCard>
						</v-col>
						<v-col cols="3" class="">
							<DashCard :loading="loading" class="white" valueclass="gold--text font-weight-bold" textclass="gold--text" style="border: 2px solid #fdc000 !important">
								<template v-slot:value>{{ goldCertifiedPercentage }}%</template>
								<template v-slot:text>Gold</template>
							</DashCard>
						</v-col>
					</v-row>
				</FarmCard>
			</v-col>

			<v-col cols="4">
				<div class="d-flex align-center full-width pb-5">
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ totalTestEvents }}</template>
							<template v-slot:text>Test Events</template>
						</DashCard>
					</div>
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>-</template>
							<template v-slot:text>Total Results</template>
						</DashCard>
					</div>
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>-</template>
							<template v-slot:text>Processed</template>
						</DashCard>
					</div>
				</div>
				<FarmCard headerclass="grey darken-1 white--text" class="mx-2" style="height: 530px !important">
					<template v-slot:header><div class="pa-5">Animal BVD Antibody Status</div></template>
					<PieChart v-if="!loadingAnimal" :datadown="bvdStatus" class="mt-10" style="height: 330px !important" />
					<div v-if="loadingAnimal" style="height: 450px !important" class="d-flex align-center justify-center flex-column">
						<v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
						<div class="grey--text text--lighten-1 mt-5 font-weight-bold animate__animated animate__flash animate__slower animate__infinite">Loading</div>
					</div>
					<v-row class="mx-2 mt-5" v-if="!loadingAnimal">
						<v-col cols="3" class="">
							<DashCard :loading="loading" class="white" valueclass="success--text font-weight-bold" textclass="success--text" style="border: 2px solid #43a047 !important">
								<template v-slot:value>{{ negativeAnimalPercentage }}%</template>
								<template v-slot:text>Negative</template>
							</DashCard>
						</v-col>
						<v-col cols="3" class="white">
							<DashCard :loading="loading" class="" valueclass="error--text font-weight-bold" textclass="error--text" style="border: 2px solid #da4c4c !important">
								<template v-slot:value>{{ positiveAnimalPercentage }}%</template>
								<template v-slot:text>Positive</template>
							</DashCard>
						</v-col>
						<v-col cols="3" class="white">
							<DashCard :loading="loading" class="white" valueclass="grey--text font-weight-bold" textclass="grey--text" style="border: 2px solid #828282 !important">
								<template v-slot:value>{{ unknownAnimalPercentage }}%</template>
								<template v-slot:text>Unknown</template>
							</DashCard>
						</v-col>
					</v-row>
				</FarmCard>
			</v-col>

			<v-col cols="4">
				<div class="d-flex align-center full-width pb-5">
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ vetUserCount }}</template>
							<template v-slot:text>Vet Users</template>
						</DashCard>
					</div>
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ holdingUserCount }}</template>
							<template v-slot:text>Farm Users</template>
						</DashCard>
					</div>
					<div class="flex-grow-1 px-2">
						<DashCard :loading="loading" class="white" valueclass="black--text font-weight-bold" textclass="black--text">
							<template v-slot:value>{{ otherUserCount }}</template>
							<template v-slot:text>Other Users</template>
						</DashCard>
					</div>
				</div>
				<FarmCard headerclass="grey darken-1 white--text" class="mx-2" style="height: 530px !important">
					<template v-slot:header
						><div class="pa-5">Awaiting Approval ({{ itemsTotal }})</div></template
					>
					<div v-if="loadingApproval" style="height: 450px !important" class="d-flex align-center justify-center flex-column">
						<v-progress-circular :size="100" :width="10" indeterminate color="grey lighten-3"></v-progress-circular>
						<div class="grey--text text--lighten-1 mt-5 font-weight-bold animate__animated animate__flash animate__slower animate__infinite">Loading</div>
					</div>
					<div v-if="!loadingApproval" style="height: 455px !important; overflow: scroll !important">
						<FarmCard class="pa-5 my-3 grey lighten-3 mx-5" v-for="(org, index) in tableData" :key="index">
							<div class="d-flex align-start">
								<v-icon large v-if="org?.type === 'Holding'">icons8-farm-2</v-icon>
								<v-icon large v-if="org?.type === 'Vet'">icons8-rod-of-asclepius</v-icon>
								<div class="ml-3">
									<span class="text-h5 font-weight-bold grey--text text--darken-2"> {{ org?.name }} </span><br />
									{{ MIX_formatAddress(org) }}
									<br />
									<span v-if="org?.type === 'Holding'"><strong>CPH:</strong> {{ org?.cphNumber | VMask("##/###/####") }}<br /></span>
									<span><strong>Contact:</strong> {{ org?.contactFirstName }} {{ org?.contactLastName }} | {{ org?.telephone }}<br /></span>
									<span v-if="org?.website"><strong>Website:</strong> {{ org?.website }}</span>
								</div>
							</div>

							<div class="fullwidth text-left mt-3">
								<AppButton @click.native="updateStatus(org, 'Rejected')" depressed class="mr-2 grey lighten-2 black--text"><v-icon class="error--text">icons8-cancel</v-icon><span class="pl-2">Reject</span></AppButton>
								<AppButton @click.native="updateStatus(org, 'Approved')" depressed class="grey lighten-2 black--text"><v-icon class="success--text">icons8-ok</v-icon><span class="pl-2">Approve</span></AppButton>
							</div>
						</FarmCard>
					</div>
				</FarmCard>
			</v-col>
		</v-row>
		<v-row dense class="px-5 pt-5 pr-7" v-if="$vuetify.breakpoint.smAndUp">
			<v-col cols="12">
				<FarmCard class="py-2 text-center">
					<div class="pt-5 d-flex align-center justify-center">
						<v-spacer />
						<div class="text-center mx-10">
							<a href="https://www.ahww.cymru/en/" target="_blank"><img src="@/assets/BVD.png" alt="Agriculture Research Centre" height="100" /></a>
						</div>
						<div class="text-center mx-10">
							<a href="https://www.rvc.ac.uk/" target="_blank"><img src="@/assets/RVC.png" alt="Agriculture Research Centre" height="100" /></a>
						</div>
						<div class="text-center mx-10">
							<a href="https://arc-csg.cymru/" target="_blank"><img src="@/assets/ARC.png" alt="Agriculture Research Centre" height="100" /></a>
						</div>
						<div class="text-center mx-10">
							<a href="https://www.gov.wales/" target="_blank"><img src="@/assets/WelshGov.png" alt="Gwaredu BVD" height="100" /></a>
						</div>
						<v-spacer />
					</div>
				</FarmCard>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "HomeAdmin",
	data: () => ({
		loading: false,
		loadingHolding: false,
		loadingAnimal: false,
		loadingApproval: false,
		tableData: [],
		itemsTotal: 0,
		holdingsCount: 0,
		vetsCount: 0,
		totalTestEvents: 0,
		holdingUserCount: 0,
		vetUserCount: 0,
		otherUserCount: 0,

		positiveAnimalPercentage: 0,
		negativeAnimalPercentage: 0,
		unknownAnimalPercentage: 0,

		positiveAnimalCount: 0,
		negativeAnimalCount: 0,
		unknownAnimalCount: 0,
		totalAnimalCount: 0,

		positiveCertifiedCount: 0,
		bronzeCertifiedCount: 0,
		silverCertifiedCount: 0,
		goldCertifiedCount: 0,
		totalCertificateCount: 0,

		positiveCertifiedPercentage: 0,
		bronzeCertifiedPercentage: 0,
		silverCertifiedPercentage: 0,
		goldCertifiedPercentage: 0,
		animalCount: 0,

		certificateStatus: [],
		bvdStatus: [],
	}),
	methods: {
		// Get Total Record Count
		async getCount(index, query) {
			try {
				let dataResult = await this.REDIS_countFor(index, "", "", "", "", query);
				// console.log('dataResult',JSON.stringify(dataResult));
				return dataResult.total;
			} catch (error) {
				console.error(error);
			}
		},

		// Get Table Data
		async getTableData() {
			try {
				this.loadingApproval = true;
				let filterQuery = `@deleted:{false} @status:{Pending} @type:{Vet|Holding}`;
				let dataResult = await this.REDIS_searchFor("org", 0, 9999, "", "", filterQuery);
				// console.log("dataResult", JSON.stringify(dataResult, null, 2));
				this.tableData = dataResult.data.documents;
				this.itemsTotal = dataResult.data.total;
				this.loadingApproval = false;
			} catch (error) {
				console.error(error);
			}
		},
		async updateStatus(org, status) {
			try {
				org.status = status;
				await this.REDIS_update("org", org.entityId, org);
				this.MIX_alertBox({ show: true, message: status, color: "success", timeout: "2000" });
				this.getTableData();
			} catch (error) {
				this.MIX_alertBox({ show: true, message: "Error Occured", color: "error", timeout: "2000" });
				console.error(error);
			}
		},
		async getOrgStatsByType() {
			this.loadingHolding = true;
			// Get Org Stats By Type
			let orgStatsByTypeResult = await this.MIX_getOrgStatsByType();
			// Get Holding Count from orgStatsByTypeResult object array
			this.holdingsCount = orgStatsByTypeResult.data.find((item) => item.type === "Holding")?.count;
			// Get Vet Count from orgStatsByTypeResult object array
			this.vetsCount = orgStatsByTypeResult.data.find((item) => item.type === "Vet")?.count;
			// Get Other Count from orgStatsByTypeResult object array
			this.othersCount = orgStatsByTypeResult.data.find((item) => item.type === "Other")?.count;
			this.loadingHolding = false;
		},
		async getOrgStatsByCertificate() {
			this.loadingHolding = true;
			// Get Org Stats By certificate
			let orgStatsByCertificateResult = await this.MIX_getOrgStatsByCertificate();
			// console.log('orgStatsByCertificateResult', JSON.stringify(orgStatsByCertificateResult, null, 2))
			// Get Positive Count from orgStatsByCertificateResult object array
			this.positiveCertifiedCount = orgStatsByCertificateResult.data.find((item) => item.certificateStatus === "Positive")?.count;
			// Get Bronze Count from orgStatsByCertificateResult object array
			this.bronzeCertifiedCount = orgStatsByCertificateResult.data.find((item) => item.certificateStatus === "Bronze")?.count;
			// Get Silver Count from orgStatsByCertificateResult object array
			this.silverCertifiedCount = orgStatsByCertificateResult.data.find((item) => item.certificateStatus === "Silver")?.count;
			// Get Gold Count from orgStatsByCertificateResult object array
			this.goldCertifiedCount = orgStatsByCertificateResult.data.find((item) => item.certificateStatus === "Gold")?.count;

			// get total certificate countparseInt(this.silverCertifiedCount) + parseInt(this.goldCertifiedCount);
			if (this.positiveCertifiedCount !== undefined) {
				this.totalCertificateCount += parseInt(this.positiveCertifiedCount);
			} else {
				this.positiveCertifiedCount = 0;
			}
			if (this.bronzeCertifiedCount !== undefined) {
				this.totalCertificateCount += parseInt(this.bronzeCertifiedCount);
			} else {
				this.bronzeCertifiedCount = 0;
			}
			if (this.silverCertifiedCount !== undefined) {
				this.totalCertificateCount += parseInt(this.silverCertifiedCount);
			} else {
				this.silverCertifiedCount = 0;
			}
			if (this.goldCertifiedCount !== undefined) {
				this.totalCertificateCount += parseInt(this.goldCertifiedCount);
			} else {
				this.goldCertifiedCount = 0;
			}

			this.positiveCertifiedPercentage = parseInt((this.positiveCertifiedCount / this.totalCertificateCount) * 100);
			this.bronzeCertifiedPercentage = parseInt((this.bronzeCertifiedCount / this.totalCertificateCount) * 100);
			this.silverCertifiedPercentage = parseInt((this.silverCertifiedCount / this.totalCertificateCount) * 100);
			this.goldCertifiedPercentage = parseInt((this.goldCertifiedCount / this.totalCertificateCount) * 100);

			this.certificateStatus = [
				{ value: this.positiveCertifiedCount, name: "Positive", itemStyle: { color: "#DA4C4C" } },
				{ value: this.bronzeCertifiedCount, name: "Bronze", itemStyle: { color: "#CD7F32" } },
				{ value: this.silverCertifiedCount, name: "Silver", itemStyle: { color: "#C0C0C0" } },
				{ value: this.goldCertifiedCount, name: "Gold", itemStyle: { color: "#FDC000" } },
			];
			this.loadingHolding = false;
		},
		async getAnimalStatsByStatus() {
			// Get Animal Stats By Status
			this.loadingAnimal = true;

			let animalStatsByStatusResult = await this.MIX_getAnimalStatsByStatus();
			this.positiveAnimalCount = animalStatsByStatusResult.data.find((item) => item.status === "Positive")?.count;
			this.negativeAnimalCount = animalStatsByStatusResult.data.find((item) => item.status === "Negative")?.count;
			this.unknownAnimalCount = animalStatsByStatusResult.data.find((item) => item.status === "Unknown")?.count;
			if (this.positiveAnimalCount !== undefined) {
				this.totalAnimalCount += parseInt(this.positiveAnimalCount);
			} else {
				this.positiveAnimalCount = 0;
			}
			if (this.negativeAnimalCount !== undefined) {
				this.totalAnimalCount += parseInt(this.negativeAnimalCount);
			} else {
				this.negativeAnimalCount = 0;
			}
			if (this.unknownAnimalCount !== undefined) {
				this.totalAnimalCount += parseInt(this.unknownAnimalCount);
			} else {
				this.unknownAnimalCount = 0;
			}
			this.positiveAnimalPercentage = parseInt((this.positiveAnimalCount / this.totalAnimalCount) * 100);
			this.negativeAnimalPercentage = parseInt((this.negativeAnimalCount / this.totalAnimalCount) * 100);
			this.unknownAnimalPercentage = parseInt((this.unknownAnimalCount / this.totalAnimalCount) * 100);
			this.bvdStatus = [
				{ name: "Negative", value: this.negativeAnimalCount, itemStyle: { color: "#43A047" } },
				{ name: "Positive", value: this.positiveAnimalCount, itemStyle: { color: "#DA4C4C" } },
				{ name: "Unknown", value: this.unknownAnimalCount, itemStyle: { color: "#828282" } },
			];
			this.loadingAnimal = false;
		},
	},

	async created() {
		this.getOrgStatsByType();
		this.getOrgStatsByCertificate();
		this.getAnimalStatsByStatus();
		this.getTableData();

		this.loading = true
		// Holding User Count
		await this.getCount("user", "@deleted:{false} @orgType:{Holding}").then((data) => {
			this.holdingUserCount = data;
		});
		// Vet User Count
		await this.getCount("user", "@deleted:{false} @orgType:{Vet}").then((data) => {
			this.vetUserCount = data;
		});
		// Other User Count
		await this.getCount("user", "@deleted:{false} -@orgType:{Holding|Vet}").then((data) => {
			this.otherUserCount = data;
		});

		await this.getCount("testevent", "@deleted:{false}").then((data) => {
			this.totalTestEvents = data;
		});
		this.loading = false
	},
};
</script>

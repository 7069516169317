module.exports = [
	{
		labOrgId: "01GY5KEV9MZ20VHG4K1VAKG9JJ",
		labName: "",
		fields: {
			"sample_date": "sampleDate",
			"sample_analysis_date": "processedDate",
			"sample_id": "tubeId",
			"sample_type": "sampleType",
			"analysis_type": "diseaseType",
			"test_type": "testType",
			"pooled_sample": "pooled",
			"animal_identification_number": "animalId",
			"animal_identification_numbers": "animalId",
			"test_holding_number": "cphNumber",
			"result": "resultText",
			"test_reference": "ignore",
			"herd_mark_number": "ignore",
			"keeper_postcode": "ignore"
		}
	}
]
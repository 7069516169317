import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    showDrawer: false,
    alertBox: { color: "black", timeout: 2000, message: "", show: false },
    showTermsConditions: false,
    currentUserAuth: null,
    currentUserProfile: null,
    userAuthenticated: false,
    noCloud: false,
  },
  getters: {
    GET_showDrawer: state => state.showDrawer,
    GET_alertBox: state => state.alertBox,
    GET_showTermsConditions: state => state.showTermsConditions,
    GET_currentUserAuth: state => state.currentUserAuth,
    GET_currentUserProfile: state => state.currentUserProfile,
    GET_userAuthenticated: state => state.userAuthenticated,
    GET_noCloud: state => state.noCloud,
  },
  mutations: {
    MUT_showDrawer: (state, payload) => state.showDrawer = payload,
    MUT_alertBox: (state, payload) => state.alertBox = payload,
    MUT_showTermsConditions: (state, payload) => state.showTermsConditions = payload,
    MUT_currentUserAuth: (state, payload) => state.currentUserAuth = payload,
    MUT_currentUserProfile: (state, payload) => state.currentUserProfile = payload,
    MUT_userAuthenticated: (state, payload) => state.userAuthenticated = payload,
    MUT_noCloud: (state, payload) => state.noCloud = payload,
  },
  actions: {
    ACT_showDrawer: ({commit}, payload) => commit('MUT_showDrawer', payload),
    ACT_alertBox: ({commit}, payload) => commit('MUT_alertBox', payload),
    ACT_showTermsConditions: ({commit}, payload) => commit('MUT_showTermsConditions', payload),
    ACT_currentUserAuth: ({commit}, payload) => commit('MUT_currentUserAuth', payload),
    ACT_currentUserProfile: ({commit}, payload) => commit('MUT_currentUserProfile', payload),
    ACT_userAuthenticated: ({commit}, payload) => commit('MUT_userAuthenticated', payload),
    ACT_noCloud: ({commit}, payload) => commit('MUT_noCloud', payload),
  },
  modules: {
  }
})
